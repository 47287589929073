import { Label } from '@/components'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { Control, Controller } from 'react-hook-form'
import { FieldComponent, FieldComponentProps, FieldKind } from './types'
import { PropsFrom } from '@/types/common'

const useStyles = makeStyles((theme) => ({
	formFieldContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '100%',
		padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
	},
	errorMessageContainer: {
		textAlign: 'right',
		width: 'inherit',
		height: '1.5em',
	},
	noErrorMessage: {
		visibility: 'hidden',
	},
	noMargin: {
		marginBlockStart: '0em',
		marginBlockEnd: '0em',
		marginInlineStart: '0px',
		marginInlineEnd: '0px',
	},
	errorMessage: {
		color: theme.palette.error.main,
		fontSize: theme.typography.subtitle2.fontSize,
		marginRight: '8px', // matches the padding + border of the Outlined button
		display: 'block',
	},
}))

type FormFieldContainerProps<K extends FieldKind> = {
	isRequired: boolean
	renderComponent: FieldComponent<K>
	renderProps: FieldComponentProps<K>
	label: string
	name: string
	control: Control
}

const FormFieldContainer = ({
	control,
	label,
	name,
	isRequired,
	renderComponent: RenderComponent,
	renderProps,
}: FormFieldContainerProps<FieldKind>) => {
	const classes = useStyles()

	return (
		<li className={classes.formFieldContainer}>
			<Controller
				control={control}
				name={name}
				render={({ field, formState, fieldState }) => {
					return (
						<Label semiBold title={label} required={isRequired}>
							{/* @TODO: Ensure that renderProps are tightly coupled with RenderComponent  */}
							<RenderComponent {...field} {...(renderProps as any)} />
							<div className={classes.errorMessageContainer}>
								<p
									className={classNames([
										classes.noMargin,
										formState.isDirty && !fieldState?.error ? classes.noErrorMessage : classes.errorMessage,
									])}
								>
									{fieldState?.error ? fieldState.error.message : undefined}
								</p>
							</div>
						</Label>
					)
				}}
			></Controller>
		</li>
	)
}

export default FormFieldContainer
