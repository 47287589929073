import { IconWrapper } from '@/components'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { UserPrimaryTitle } from '@/components/UserProfile/UserPrimaryTitle'
import DtoOnboardingEntity from '@/features/onboarding/types/dto/DtoOnboardingEntity'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { CSSObject, styled, Tooltip, Typography } from '@mui/material'

const ProfileImage = styled(LazyLoadProfileImage)(({ theme }) => ({
	width: 40,
	height: 40,
	minWidth: 40,
	minHeight: 40,
	marginRight: 20,
}))

const InfoContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	justifyContent: 'flex-start',
	width: 'auto',
	flexGrow: 1,
})

const textStyles: CSSObject = {
	textTransform: 'capitalize',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	overflowY: 'hidden',
	'-webkit-line-clamp': 1,
	'-webkit-box-orient': 'vertical',
	wordBreak: 'break-word',
}

const StyledEntityTitle = styled(UserPrimaryTitle)(textStyles)

const StyledText = styled(Typography)(({ theme }) => ({
	...textStyles,
	color: theme.palette.text.primary,
}))

const Row = styled('div')<{ existsInSignupEntity: boolean; isSelected: boolean }>(({ theme, existsInSignupEntity, isSelected }) => ({
	padding: 10,
	borderRadius: 5,
	position: 'relative',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'flex-start',
	backgroundColor: existsInSignupEntity || isSelected ? theme.palette.grey[100] : 'transparent',
	color: existsInSignupEntity ? theme.palette.common.black : theme.palette.text.primary,
	cursor: existsInSignupEntity ? 'default' : 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.grey[100],
	},
}))

const WithTooltip = ({ showTooltip, children }) => {
	// @TODO: Resolve visual bug -- still visible when row is scrolled out of view
	return showTooltip ? (
		<Tooltip title="Already included" placement="bottom-end">
			{children}
		</Tooltip>
	) : (
		children
	)
}

const IncludedCheckMark = styled(IconWrapper)({
	cursor: 'pointer',
	flex: '0 0 10%',
	display: 'flex',
	alignContent: 'center',
	justifyContent: 'center',
	minWidth: 24,
})

interface RowProps {
	entity: DtoOnboardingEntity & {
		externalId?: string
		name: string
		subTitle: string
	}
	showImage?: boolean
	entityType: EntityTypes
	children?: React.ReactNode
	profileImageRadius?: number
	showPrimaryTitle?: boolean
	handleClick: (entity: string) => void
	endAdornment?: React.ReactNode
	showAdditionalInfo?: boolean
	isSelected: boolean
	existsInSignupEntity: boolean
}

const SearchEntitiesListRow = ({
	entity,
	profileImageRadius = 50,
	showPrimaryTitle = true,
	showImage = true,
	isSelected,
	existsInSignupEntity,
	handleClick,
}: RowProps) => {
	return (
		<WithTooltip showTooltip={existsInSignupEntity}>
			<Row
				key={`search-result-${entity.id}`}
				onClick={() => {
					if (!existsInSignupEntity) handleClick(entity.id)
				}}
				existsInSignupEntity={existsInSignupEntity}
				isSelected={isSelected}
			>
				{showImage && <ProfileImage borderRadius={profileImageRadius} externalId={entity.externalId} photoUrl={entity.photo} />}
				<InfoContainer>
					<StyledText>{entity.name}</StyledText>
					{showPrimaryTitle && <StyledEntityTitle variant={'subtitle1'} />}
				</InfoContainer>
				{existsInSignupEntity && <IncludedCheckMark iconKey="check" color="green" />}
			</Row>
		</WithTooltip>
	)
}

export default SearchEntitiesListRow
