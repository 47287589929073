import { GridConfigDto, QueryGridFiltersDto, QueryGridSortByDto } from '../types/dto/Grid'
import { generatedBaseApi as api } from './baseGeneratedApi'
export const addTagTypes = ['Analytics Post'] as const
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			postsControllerCreate: build.mutation<PostsControllerCreateApiResponse, PostsControllerCreateApiArg>({
				query: () => ({ url: `/analytics/posts`, method: 'POST' }),
				invalidatesTags: ['Analytics Post'],
			}),
			postsControllerFindOne: build.query<PostsControllerFindOneApiResponse, PostsControllerFindOneApiArg>({
				query: (queryArg) => ({ url: `/analytics/posts/${queryArg.id}` }),
				providesTags: ['Analytics Post'],
			}),
			postsControllerUpdate: build.mutation<PostsControllerUpdateApiResponse, PostsControllerUpdateApiArg>({
				query: (queryArg) => ({ url: `/analytics/posts/${queryArg.id}`, method: 'PATCH' }),
				invalidatesTags: ['Analytics Post'],
			}),
			postsControllerRemove: build.mutation<PostsControllerRemoveApiResponse, PostsControllerRemoveApiArg>({
				query: (queryArg) => ({ url: `/analytics/posts/${queryArg.id}`, method: 'DELETE' }),
				invalidatesTags: ['Analytics Post'],
			}),
			postsControllerFindMany: build.query<PostsControllerFindManyApiResponse, PostsControllerFindManyApiArg>({
				query: (queryArg) => ({
					url: `/analytics/posts/grid/data`,
					params: { take: queryArg.take, skip: queryArg.skip, filters: queryArg.filters, sortBy: queryArg.sortBy },
				}),
				providesTags: ['Analytics Post'],
			}),
			postsControllerGetGridMeta: build.query<PostsControllerGetGridMetaApiResponse, PostsControllerGetGridMetaApiArg>({
				query: () => ({ url: `/analytics/posts/grid/meta` }),
				providesTags: ['Analytics Post'],
			}),
		}),
		overrideExisting: false,
	})
export { injectedRtkApi as analyticsPostsApi }
export type PostsControllerCreateApiResponse = unknown
export type PostsControllerCreateApiArg = void
export type PostsControllerFindOneApiResponse = unknown
export type PostsControllerFindOneApiArg = {
	id: string
}
export type PostsControllerUpdateApiResponse = unknown
export type PostsControllerUpdateApiArg = {
	id: string
}
export type PostsControllerRemoveApiResponse = unknown
export type PostsControllerRemoveApiArg = {
	id: string
}
export type PostsControllerFindManyApiResponse = /** status 200 Successfully received items result */ {
	totalItems?: number
	items?: Post[]
}
export type PostsControllerFindManyApiArg = {
	take?: number
	skip?: number
	filters?: QueryGridFiltersDto
	sortBy?: QueryGridSortByDto
}
export type PostsControllerGetGridMetaApiResponse = /** status 200  */ GridConfigDto
export type PostsControllerGetGridMetaApiArg = void
export type Post = {
	id: string
	numberOfComments: number
	message: string
	deleted: boolean
	createdAt: string
	numberOfLikes: number
}

export const {
	usePostsControllerCreateMutation,
	usePostsControllerFindOneQuery,
	usePostsControllerUpdateMutation,
	usePostsControllerRemoveMutation,
	usePostsControllerFindManyQuery,
	usePostsControllerGetGridMetaQuery,
} = injectedRtkApi
