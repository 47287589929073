import Editor from '@/components/Editor'
import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { TFnOnChangeParams } from '../Editor/plugins/OnChangePlugin'
import { StyleProps } from './Form'

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	textInput: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		fontSize: theme.typography.body1.fontSize,
		fontFamily: theme.typography.fontFamily,
		border: 'none',
		'&:focus': {
			outline: 'none',
		},
	},
	textEditorWrapper: {
		padding: `${theme.spacing(2)}px ${theme.spacing(1.8)}px`,
		borderRadius: theme.spacing(1),
		justifyContent: 'center',
		alignItems: 'center',
		border: `1px solid ${theme.palette.grey[400]}`,
		pointerEvents: 'auto',
		cursor: 'text',
		'&:hover': {
			border: `1px solid ${theme.palette.grey[600]}`,
		},
		'&:focus-within': {
			border: `2px solid #0288d1`, // @TODO: Use theme color
		},
	},
}))

export type TextAreaProps = {
	placeholder?: string
	value?: string
	onChange?: (event: TFnOnChangeParams) => void
}

export const TextArea = React.forwardRef(({ placeholder, value, onChange }: TextAreaProps) => {
	const classes = useStyles({ variant: 'primary', isOpen: false, hasValue: false })

	return (
		<div className={classes.textEditorWrapper}>
			<Editor
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				styles={{
					editorContainer: { width: '100%' },
					editorInput: { borderRadius: '50%', borderColor: 'pink', borderWidth: '5px' },
				}}
			/>
		</div>
	)
})

export type FormInputProps = {
	placeholder?: string
	value?: string
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FormInput = React.forwardRef(({ placeholder, value = '', onChange }: FormInputProps) => {
	const classes = useStyles({ variant: 'primary', isOpen: false, hasValue: false })
	return (
		<div className={classes.textEditorWrapper}>
			<input placeholder={placeholder} value={value} onChange={onChange} className={classes.textInput} />
		</div>
	)
})
