import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { PATHS, STYLES } from '../../constants'
import { UserData } from '../../interfaces/common'
import { getFromNow } from '../../utils/dateTime2'
import { getOrganizationRole, orgTypes } from '../../utils/organizationRoles'
import MemberIcon from '../icons/MemberIcon'
import LazyLoadProfileImage from '../LazyLoadProfileImage'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '15px 0',
	},
	descriptionText: {
		color: theme.palette.text.secondary,
	},
	infoContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 15,
	},
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
	userInfoRowContainer: ({ showRows }: any) => ({
		width: STYLES.FILL_AVAILABLE_WIDTH,
		borderBottom: showRows ? `solid 1px ${theme.palette.divider}` : 'none',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	}),
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	createdAt: {
		color: theme.palette.text.secondary,
		fontSize: 15,
	},
	titleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	clickable: {
		cursor: 'pointer',
	},
	organizationMembershipType: {
		width: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}))

interface UserWithMembershipInfoRowProps {
	classnames?: {
		root?: string
		userRow?: string
		profileImage?: string
		buttonsContainer?: string
		infoContainer?: string
		userName?: string
		positionText?: string
		titleContainer?: string
		additionalInfo?: string
	}
	user?: UserData
	organizationMembershipTypeId?: orgTypes
	showRows?: boolean
	buttons?: React.ReactNode
	createdAt?: string
	onClick?: (e: any) => void
	openProfileOnClick?: boolean
	userEmail?: string
	additionalInfo?: string
}

const UserWithMembershipInfoRow = ({
	classnames = {
		root: '',
		userRow: '',
		profileImage: '',
		buttonsContainer: '',
		infoContainer: '',
		userName: '',
		positionText: '',
		titleContainer: '',
		additionalInfo: '',
	},
	user,
	organizationMembershipTypeId,
	showRows = false,
	buttons = null,
	createdAt,
	onClick,
	openProfileOnClick = false,
	userEmail,
	additionalInfo,
}: UserWithMembershipInfoRowProps) => {
	const classes = useStyles({ showRows })
	const { push } = useHistory()

	const { externalId, fullName, type, campus, position, id, photoUrl } = user!

	const subTitle = position ? position : type && campus ? `${type} | ${campus}` : ''

	const handleClickViewProfile = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		push(PATHS.APP.NETWORK_USER(`${id}`))
	}

	return (
		<div className={classNames(classes.userInfoRowContainer, classnames.root)} onClick={onClick}>
			<div
				className={classNames(classes.root, openProfileOnClick ? classes.clickable : '', classnames.userRow)}
				onClick={openProfileOnClick ? handleClickViewProfile : undefined}
			>
				<LazyLoadProfileImage
					className={classNames(classes.profileImage, classnames.profileImage)}
					borderRadius={10}
					externalId={externalId}
					photoUrl={photoUrl}
				/>
				<div className={classNames(classes.infoContainer, classnames.infoContainer)}>
					<div className={classNames(classes.titleContainer, classnames.titleContainer)}>
						<Typography className={classnames.userName}>{fullName}</Typography>
						{createdAt && <Typography className={classes.createdAt}>{getFromNow(createdAt)}</Typography>}
					</div>
					{organizationMembershipTypeId ? (
						<div className={classes.organizationMembershipType}>
							<Typography className={classNames(classes.descriptionText, classnames.positionText)}>
								{getOrganizationRole(organizationMembershipTypeId)}
							</Typography>
							<MemberIcon />
						</div>
					) : (
						<Typography className={classNames(classes.descriptionText, classnames.positionText)}>{subTitle}</Typography>
					)}
					{additionalInfo && (
						<Typography className={classNames(classes.descriptionText, classnames.additionalInfo)}>{additionalInfo}</Typography>
					)}
				</div>
			</div>
			{/* Prevent event propagation when click to the button inside a row */}
			<div className={classNames(classes.buttonsContainer, classnames.buttonsContainer)} onClick={(e: any) => e.stopPropagation()}>
				{buttons}
			</div>
		</div>
	)
}

export default UserWithMembershipInfoRow
