import { Add, Check, ExpandMore } from '@material-ui/icons'
import { Box, ListItemText, Select as MaterialSelect, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { isArray } from 'lodash'
import { forwardRef, useCallback, useMemo } from 'react'
import { BaseSelectProps, LabelProps } from './types'

export const emptyClassNames = {
	input: '',
	select: '',
	selectOpenIcon: '',
	selectLabel: '',
	selectMenuPaper: '',
	selectMenuList: '',
	selectMenuListContainer: '',
	menuItem: '',
	menuItemIcon: '',
	tag: '',
	tagsContainer: '',
}

const parseValue = (v: string) => {
	try {
		return JSON.parse(v)
	} catch (e) {
		return v
	}
}
export const hasValue = (value: BaseSelectProps['value']): boolean => !!value || !!(value as Array<any>)?.length

const Select = forwardRef<HTMLDivElement, BaseSelectProps>(
	(
		{
			classnames = emptyClassNames,
			label,
			value,
			placeholder = '',
			onChange,
			options = [],
			multiple,
			disabled = false,
			onToggleSelect,
			startIcon,
		},
		ref,
	) => {
		const isSelected = useMemo(() => hasValue(value), [multiple, value, options])

		const onOpenSelect = useCallback(() => onToggleSelect?.(true), [onToggleSelect])
		const onCloseSelect = useCallback(() => onToggleSelect?.(false), [onToggleSelect])

		const handleChange = useCallback(
			({ target: { value } }: any) => {
				if (multiple) {
					onChange(value.filter((v) => v !== placeholder).map((v) => parseValue(v)))
				} else {
					onChange(parseValue(value))
				}
			},
			[multiple, onChange, placeholder],
		)

		const stringValue = useMemo(
			() =>
				isArray(value)
					? isSelected
						? value.map((v) => JSON.stringify(v))
						: [placeholder]
					: isSelected
					? JSON.stringify(value)
					: placeholder,
			[isSelected, multiple, placeholder, value],
		)

		return (
			<MaterialSelect
				ref={ref}
				className={classnames.input}
				classes={{
					select: classnames.select,
				}}
				onOpen={onOpenSelect}
				onClose={onCloseSelect}
				disabled={disabled}
				value={stringValue}
				onChange={handleChange}
				placeholder={placeholder}
				IconComponent={(props) => <ExpandMore {...props} className={classNames(classnames.selectOpenIcon, props.className)} />}
				variant="outlined"
				MenuProps={{
					PaperProps: {
						className: classNames(classnames.selectMenuPaper),
						sx: {
							width: 0,
						},
					},
					MenuListProps: {
						className: classNames(classnames.selectMenuList),
					},
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					className: classnames.selectMenuListContainer,
				}}
				renderValue={(v: any) => {
					if (multiple) {
						const values = v.filter((v) => v !== placeholder)

						return (
							<Label
								startIcon={startIcon}
								label={label ? `${label}: ${placeholder} (${values.length})` : `${placeholder} (${values.length})`}
							/>
						)
					}

					const option = isSelected ? options.find(({ value }) => value === parseValue(v)) : null

					if (option) return <Label startIcon={startIcon} label={label ? `${label}: ${option.label}` : option.label} />
					return <Label startIcon={startIcon} label={label ? `${label}: ${placeholder}` : placeholder} />
				}}
				multiple={multiple}
			>
				{options.map(({ label, value: itemValue, disabled }) => {
					const selected = isArray(value) ? value.includes(itemValue) : itemValue === value

					return (
						<MenuItem
							key={`${label}-${itemValue}`}
							value={JSON.stringify(itemValue)}
							className={classNames(classnames.menuItem)}
							disabled={disabled}
							sx={{ whiteSpace: 'pre-wrap' }}
						>
							<ListItemText primary={label} />
							{selected ? <Check className={classnames.menuItemIcon} /> : multiple ? <Add className={classnames.menuItemIcon} /> : null}
						</MenuItem>
					)
				})}
			</MaterialSelect>
		)
	},
)

export default Select

const LabelText = styled('div', {
	shouldForwardProp: (prop) => prop !== 'hasStartIcon',
})<{ hasStartIcon: boolean }>(({ hasStartIcon }) => ({
	marginLeft: hasStartIcon ? 5 : 0,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: 'block',
}))

const Label = ({ label, startIcon }: LabelProps) => (
	<Box display="flex" alignItems="center">
		{startIcon}
		<LabelText hasStartIcon={!!startIcon}>{label}</LabelText>
	</Box>
)
