import useTakeSkip from '@/anthology/hooks/useTakeSkip'
import { GroupMemberType } from '@/api/http/groups/getGroupMembers'
import { UserRow } from '@/components'
import { ContentPreloader } from '@/components/ContentPreloader'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useGetGroupMembersByMemberTypeQuery } from '../../api/groupsApi'

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '500px',
		width: 'max-content',
		minWidth: '100%',
		gridTemplateColumns: 'max-content 4% auto',
		rowGap: theme.spacing(2),
	},
	row: {
		padding: '1vh 1vw',
		margin: '0 12px',
	},
	user: {
		display: 'grid',
		gridTemplateColumns: 'minmax(0, 1fr) auto',
		columnGap: '2%',
		alignItems: 'center',
		minWidth: 'fit-content',
		whiteSpace: 'nowrap',
	},
	info: {
		width: theme.spacing(8),
		textAlign: 'center',
		fontWeight: 'bold',
	},
	childContainer: {
		maxWidth: '80%',
	},
	userName: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		backgroundColor: 'white',
		// whiteSpace: 'no-wrap',
		textAlign: 'left',
		fontWeight: 600,
		width: 'min-content',
		margin: `0 ${theme.spacing(1)}px`,
		'&::after': {
			transition: 'all .1s ease-in-out',
			width: '0%',
			height: '1px',
			content: '""',
			display: 'block',
			position: 'relative',
			left: '50%',
			transform: 'translateX(-50%)',
			backgroundColor: theme.palette.primary.dark,
		},
		'&:hover': {
			textUnderlineOffset: '4px',
			backgroundColor: theme.palette.grey[100],
			'&::after': {
				width: '100%',
			},
		},
	},
	rowCell: {
		padding: `${theme.spacing(0.48)}px ${theme.spacing(1.24)}px`,
		borderRadius: theme.spacing(1),
	},
	memberChip: {
		border: `2px solid ${theme.palette.primary.light} `,
		backgroundColor: 'white',
	},
	leaderChip: {
		backgroundColor: theme.palette.primary.light,
	},
}))

export const GroupChatMemberList = ({ type, groupId }) => {
	const classes = useStyles()
	const [items, setItems] = useState([])
	const { skip, take, loadMore } = useTakeSkip()

	const { data, isLoading } = useGetGroupMembersByMemberTypeQuery({
		skip,
		take,
		groupId,
		type,
	})

	useEffect(() => {
		if (data?.items) {
			setItems((prev) => [...prev, ...data.items])
		}
	}, [data?.items])

	return (
		<div className={classes.root}>
			{isLoading ? (
				<ContentPreloader />
			) : (
				<InfiniteScrollComponent
					resetScroll
					internalScroll
					withDivider
					canLoadMore={items.length < data.totalItems}
					height={500}
					items={items}
					renderItem={(item) => (
						<UserRow
							classnames={{
								root: classes.row,
								informationContainer: classes.user,
								userName: classNames(classes.userName, classes.rowCell),
								info: classNames(
									classes.info,
									classes.rowCell,
									item.type === GroupMemberType.MEMBER ? classes.memberChip : classes.leaderChip,
								),
							}}
							user={{
								externalId: item.externalUserId,
								fullName: item.fullName,
								id: item.userId,
								type: item.type,
							}}
						/>
					)}
					getKey={(user) => user.userId}
					placeholder={!items?.length ? 'No results found' : null}
					onLoadMore={loadMore}
				/>
			)}
		</div>
	)
}
