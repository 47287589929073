import { TextButton } from '@/components/Buttons'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { ChangeEvent, useRef, useState } from 'react'

import { ImageCropperModal, LazyLoadProfileImage } from '@/components'
import { FILE_FORMATS } from '@/constants'
import { useGetSecurePhotoBySlugQuery } from '@/features/photo/api'
import useImageUploadError from '@/hooks/useImageUploadError'
import { handleDrop, preventDefaults } from '@/utils/dragAndDrop'
import { getLogger } from '../../init/DIContainer'

const useStyles = makeStyles((theme) => ({
	dropAreaContainer: {
		width: 'auto',
		height: 'auto',
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '20px 0',
	},
	dropArea: {
		position: 'relative',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		[theme.breakpoints.down('xs')]: {
			width: '4.5vw',
			height: '4.5vw',
			minWidth: 55,
			minHeight: 55,
		},
	},
	fileInput: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: '-1px',
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		width: 1,
	},
	userImage: {
		width: 152,
		height: 152,
		[theme.breakpoints.up('xl')]: {
			minWidth: 152,
			minHeight: 152,
		},
	},
	changeProfilePictureText: {
		margin: '20px 0 0 0',
	},
	previewImage: ({ url, size = 'contain' }: any) => ({
		background: `url(${url}) center center / ${size} no-repeat`,
		borderRadius: '50%',
	}),
	loading: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		backgroundColor: theme.palette.specialColors.whitetransparent10,
	},
	cropArea: {
		borderRadius: '50%',
	},
}))

interface UserPhotoDropAreaProps {
	fileFormats?: string
	onChange?: (url: string) => void
	errorText?: string
	url?: string
	externalUserId: string
	userPhotoUrl?: string
	loadFromServer?: boolean
	enableCropping?: boolean
}

const sentry = getLogger()

const UserPhotoDropArea = ({
	fileFormats = FILE_FORMATS.commonImages,
	onChange = () => {},
	url = '',
	externalUserId,
	userPhotoUrl,
	loadFromServer,
	enableCropping = true,
}: UserPhotoDropAreaProps) => {
	const [fileUrl, setFileUrl] = useState(url)
	const [imageToCrop, setImageToCrop] = useState('')
	const [openCropModal, setOpenCropModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { isLoading: loadingFromServer, data: imageData } = useGetSecurePhotoBySlugQuery({ slug: url }, { skip: !loadFromServer })
	const inputFileRef = useRef<any>(null)

	const validateImageSize = useImageUploadError()

	const classes = useStyles({ url: fileUrl, size: enableCropping ? 'contain' : 'cover' })

	const uploadHandler = async (files: FileList) => {
		if (!(files && files.length)) return

		const url = URL.createObjectURL(files[0])

		const isValid = await validateImageSize(url)

		if (!isValid) return
		if (enableCropping) {
			setImageToCrop(url)
			setOpenCropModal(true)
		} else {
			setFileUrl(url)
			onChange(url)
		}
	}

	const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => uploadHandler(e.target.files as FileList)

	const initialUrls = { userPhotoUrl, url } // Temporary for logging
	const handleCloseModal = () => {
		setOpenCropModal(false)
		try {
			sentry.captureEvent({
				message: 'UserPhotoDropArea: Crop modal closed',
				platform: 'web',
				level: 'info',
				contexts: { context: { finalUrl: fileUrl, initialUrls } },
			})
		} catch (error) {
			sentry.captureException(error)
		}
	}

	const showCroppedImage = (imageSrc: string) => {
		setFileUrl(imageSrc)
		onChange(imageSrc)
	}

	const handleOpenUploadFileModal = () => inputFileRef.current?.click()
	const helperText = url || fileUrl ? 'Change profile picture' : 'Add profile picture'

	return (
		<div className={classes.root}>
			<ImageCropperModal
				isOpen={openCropModal}
				classnames={{
					cropArea: classes.cropArea,
				}}
				onClose={handleCloseModal}
				onChange={showCroppedImage}
				imageUrl={imageToCrop}
				aspect={3 / 3}
				onLoading={setIsLoading}
			/>
			<label className={classes.root}>
				<div className={classes.dropAreaContainer}>
					<div
						className={classNames(classes.userImage, classes.dropArea)}
						onDragEnter={preventDefaults}
						onDragOver={preventDefaults}
						onDragLeave={preventDefaults}
						onDrop={(e) => uploadHandler(handleDrop(e))}
					>
						{isLoading || loadingFromServer ? (
							<div className={classNames(classes.userImage, classes.loading)}>
								<CircularProgress />
							</div>
						) : (
							<>
								{!fileUrl || imageData ? (
									<LazyLoadProfileImage
										className={classes.userImage}
										photoUrl={userPhotoUrl}
										externalId={externalUserId}
										imageData={imageData ? `data:image/jpeg;base64,${imageData}` : null}
									/>
								) : (
									<div className={classNames(classes.userImage, classes.previewImage)} />
								)}
							</>
						)}
						<input
							ref={inputFileRef}
							className={classes.fileInput}
							type="file"
							accept={fileFormats}
							onChange={onChangeHandler}
							multiple={false}
						/>
					</div>
				</div>
				<TextButton className={classes.changeProfilePictureText} onClick={handleOpenUploadFileModal}>
					{helperText}
				</TextButton>
			</label>
		</div>
	)
}

export default UserPhotoDropArea
