import { makeStyles } from '@material-ui/core'
import { TextField } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { DateTimePicker } from '@mui/x-date-pickers'
import { DateInput, DateTime } from 'luxon'
import React, { useState } from 'react'
import { formatDateNew } from '../../utils/dateTime2'

const useStyles = makeStyles((theme) => ({
	picker: {
		width: '100%',
	},
}))

export const DateTimeCell = (params: GridRenderCellParams) =>
	`${formatDateNew(params.value, 'dateTimeShort')} (${DateTime.fromJSDate(params.value).toLocal().toFormat('ZZZZ')})`

type DateTimePickerProps = {
	defaultValue?: Date
	onChange?: (date: DateInput) => void
}

export const EditDateTime: React.FC<DateTimePickerProps> = ({ defaultValue = new Date(), onChange = () => {} }) => {
	const classes = useStyles()
	const [dateTime, setDateTime] = useState<DateInput>(DateTime.fromJSDate(defaultValue))

	const handleChange = (date: DateInput) => {
		setDateTime(date)
		onChange(date)
	}

	return (
		<DateTimePicker
			className={classes.picker}
			value={dateTime}
			onChange={handleChange}
			disablePast
			renderInput={(props) => <TextField {...props} />}
		/>
	)
}
