import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { PATHS, STYLES } from '@/constants'
import { GroupChatRoles } from '@navengage/sen-types'
import { getFromNow } from '@/utils/dateTime2'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { GroupMember } from '@/store/groups/types'
import { getFullName } from '@/utils/common'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	userInfoRowContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	descriptionText: {
		color: theme.palette.text.secondary,
	},
	infoContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 15,
	},
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	createdAt: {
		color: theme.palette.text.secondary,
		fontSize: 15,
	},
	titleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	clickable: {
		cursor: 'pointer',
	},
	userName: {
		fontWeight: 600,
	},
}))

interface GroupMemberRowProps {
	classnames?: {
		root?: string
		userRow?: string
		profileImage?: string
		buttonsContainer?: string
		infoContainer?: string
		userName?: string
		positionText?: string
		titleContainer?: string
	}
	member: GroupMember
	showRole?: boolean
	buttons?: React.ReactNode
	createdAt?: string
	onClick?: (e: any) => void
	openProfileOnClick?: boolean
}

const GroupMemberRow = ({
	classnames = {
		root: '',
		userRow: '',
		profileImage: '',
		buttonsContainer: '',
		infoContainer: '',
		userName: '',
		positionText: '',
		titleContainer: '',
	},
	member,
	showRole = true,
	buttons = null,
	createdAt,
	onClick,
	openProfileOnClick = false,
}: GroupMemberRowProps) => {
	const classes = useStyles()
	const { push } = useHistory()

	const { externalId, type, photoUrl, userId, firstName, lastName } = member

	const handleClickViewProfile = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		push(PATHS.APP.NETWORK_USER(`${userId}`))
	}

	return (
		<div className={classNames(classes.root, classnames.root)} onClick={onClick}>
			<div
				className={classNames(classes.userInfoRowContainer, openProfileOnClick ? classes.clickable : '', classnames.userRow)}
				onClick={openProfileOnClick ? handleClickViewProfile : undefined}
			>
				<LazyLoadProfileImage
					className={classNames(classes.profileImage, classnames.profileImage)}
					borderRadius={10}
					externalId={externalId}
					photoUrl={photoUrl}
				/>
				<div className={classNames(classes.infoContainer, classnames.infoContainer)}>
					<div className={classNames(classes.titleContainer, classnames.titleContainer)}>
						<Typography className={classNames(classes.userName, classnames.userName)}>{getFullName(firstName, lastName)}</Typography>
						{createdAt && <Typography className={classes.createdAt}>{getFromNow(createdAt)}</Typography>}
					</div>
					{showRole && (
						<Typography className={classNames(classes.descriptionText, classnames.positionText)}>
							{type === GroupChatRoles.Owner ? `${GroupChatRoles.Owner} • ${GroupChatRoles.Admin}` : type}
						</Typography>
					)}
				</div>
			</div>
			{/* Prevent event propagation when click to the button inside a row */}
			<div className={classNames(classes.buttonsContainer, classnames.buttonsContainer)} onClick={(e: any) => e.stopPropagation()}>
				{buttons}
			</div>
		</div>
	)
}

export default GroupMemberRow
