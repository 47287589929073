import IconButton from '@/components/Buttons/IconButton'
import { makeStyles } from '@material-ui/core'
import { CaretLeft } from '@phosphor-icons/react'
import React from 'react'
import { useHistory } from 'react-router-dom'

export type IBackButtonProps = {
	onClick?: () => void
	size?: number
}

const useStyles = makeStyles((theme) => ({
	backButton: {
		height: 'min-content',
	},
}))

const BackButton: React.FC<IBackButtonProps> = ({ onClick, children = 'Back', size = 24 }) => {
	const history = useHistory()
	const classes = useStyles()
	const defaultClickHandler = () => {
		history.goBack()
	}
	const onButtonClick = onClick || defaultClickHandler
	return (
		<IconButton className={classes.backButton} onClick={onButtonClick} startIcon={<CaretLeft size={size} weight="bold" />}>
			{children}
		</IconButton>
	)
}

export default BackButton
