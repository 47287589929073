import { ButtonColors } from '@/components/Buttons/types'
import { TCampus } from '@/features/campus/types/TCampus'
import WithSharedEntity from '@/features/shareEntity/types/WithSharedEntity'
import { orgTypes } from '@/utils/organizationRoles'
import React from 'react'

export interface ButtonProps {
	variant?: 'primary' | 'secondary'
	color?: ButtonColors
	children?: React.ReactNode
	onClick?: (e?: any) => void
	disabled?: boolean
	className?: string
	type?: 'button' | 'reset' | 'submit' | undefined
	onMouseEnter?: (e?: any) => void
	onMouseLeave?: (e?: any) => void
	component?: any
}

export enum ReportActions {
	inaccurate = 'inaccurate',
	inappropriate = 'inappropriate',
	blockUser = 'blocked',
	reportUser = 'report',
}

export enum UserResponseStatuses {
	cleared = 'cleared',
	followed = 'followed',
	going = 'going',
	interested = 'interested',
	notInterested = 'not_interested',
}

// @TODO: #types incorrect types app doesn't support  following post or going to post etc...
export type PostResponseStatuses = ReportActions | UserResponseStatuses

export enum Filters {
	campuses = 'campuses',
	categories = 'categories',
	branches = 'branches',
	date = 'date',
	employers = 'employers',
	locations = 'locations',
	positionAreas = 'positionAreas',
	disciplines = 'disciplines',
	positionTypes = 'positionTypes',
	majors = 'majors',
	workSettings = 'workSettings',
	terms = 'terms',
	housingTypes = 'housingTypes',
	scholarshipsOffered = 'scholarshipsOffered',
	classLevels = 'classLevels',
	minimumGPA = 'minimumGPA',
	paid = 'paid',
	country = 'country',
	connectivityStatus = 'connectivityStatus',
	userTypeId = 'userTypeId',
}

export type FilterOptionsKeys = keyof typeof Filters

export type SearchParams = { query?: string } & SearchFilter

export type SearchFilter = {
	[key in FilterOptionsKeys]?: any
}

export interface CommonSearchConfig {
	fetchMore?: boolean
	endHasBeenReached?: boolean
	page?: number
	filterIsReady?: boolean
}

export interface SearchConfig extends CommonSearchConfig {
	params?: SearchParams
}

export enum ExperienceTypes {
	Event = 'Event',
	Organization = 'Organization',
	EducationAbroad = 'Education Abroad',
	Research = 'Research',
	Internship = 'Internship',
}
export type TUserPrimaryTitle = {
	primaryTitle?: string | null
	primaryTitleDepartment?: string | null
}

export type WithPrimaryTitle<T> = T & TUserPrimaryTitle

export enum AppUserTypes {
	STUDENT = 1,
	FACULTY = 2,
	STAFF = 3,
	OTHER = 4,
	SUPPORT = 5,
	INVALID = 99,
}

// @TODO: #dataShape after consolidating responses in the backend create BaseUser and extend interfaces from #dataShape
export interface BaseUser {
	id: number
	appUserTypeId?: AppUserTypes
	externalId?: string
	email?: string
	lastName?: string
	firstName?: string
	fullName?: string
	position?: string
	campus?: string
	campusId?: TCampus['id']
	preferredPronouns?: string
	photoUrl?: string
	isArchived?: boolean
}

// @TODO: work out whether this could be renamed. I believe this represents users *other* than the current user.
export interface UserData
	extends BaseUser,
		WithPrimaryTitle<{
			type?: string
			numberOfConnections?: number
			connected?: boolean
			blocked?: boolean
			organizationMembershipTypeId?: orgTypes
			organizationMembershipId?: number
		}> {}

export enum SharedDataTypes {
	sharedEvent = 'Event',
	sharedOrganization = 'Organization',
	sharedResearch = 'Research',
	sharedStudyAbroad = 'Study Abroad',
	sharedPost = 'Post',
	sharedJob = 'Internship',
}

export interface SharedData {
	sharedEvent?: any
	sharedOrganization?: any
	sharedResearch?: any
	sharedStudyAbroad?: any
	sharedPost?: any
	sharedJob?: any
	sharedEntity?: WithSharedEntity
	blocked?: boolean
}

export interface ErrorData {
	status: number
	message: string
	statusText: string
}

export enum ConnectivityStatus {
	ACCEPTED = 'accepted',
	PENDING = 'pending',
	CANCELED = 'canceled',
	REJECTED = 'rejected',
}

export const ConnectivityStatusIndex: { [k: string]: ConnectivityStatus } = Object.values(ConnectivityStatus).reduce(
	(acc, key, index) => ({ ...acc, [index]: key }),
	{},
)

export enum CardTypes {
	EVENTS = 'events',
	ORGANIZATIONS = 'organizations',
	VOLUNTEER = 'volunteer',
	RESEARCH = 'research',
}

export interface OrganizationData {
	id: string
	name: string
	photoUrl: string
	campus: string | null
}

export type TFilterItem<T> = T & {
	id: number
	externalUpdatedAt: string | null
	createdAt: string
	updatedAt: string
}
