import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@material-ui/core'
import { Divider, Theme, Typography } from '@mui/material'
import { PaperPlaneTilt } from '@phosphor-icons/react'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { OutlinedButton } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'
import { toText } from '@/utils/toHTML'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/query'
import { ModalKind, ModalState } from '../Modal/types'
import { EditDateTime } from '../Selects/DateTimeSelect'
import FormFieldContainer from './FormFieldContainer'
import FormSelect from './FormSelect'
import { FormInput, TextArea } from './FormText'
import { FieldComponentProps, FieldInitProps, FieldKind, FieldList } from './types'
import { useFormValidation } from './useFormValidation'

export interface StyleProps {
	variant: 'primary' | 'secondary'
	isOpen: boolean
	hasValue: boolean
	error?: boolean
	disabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	postButton: {
		border: 'none',
		height: 68,
		width: 200,
		alignSelf: 'flex-end',
		margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
		'&:disabled': {
			border: 'none',
			opacity: 0.4,
		},
		'&>span': {
			color: theme.palette.primary.main,
			fontSize: '1.4rem',
		},
	},
	form: {
		display: 'flex',
		padding: theme.spacing(4),
		boxSizing: 'border-box',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		justifySelf: 'flex-end',
		width: '100%',
		flexGrow: 1,
	},
	formFields: {
		boxSizing: 'border-box',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		padding: `${theme.spacing(2)}px 0`,
	},
}))

export const FIELD_COMPONENT_MAP = {
	[FieldKind.INPUT]: FormInput,
	[FieldKind.TEXT_AREA]: TextArea,
	[FieldKind.DATE_TIME]: EditDateTime,
	[FieldKind.SELECT]: FormSelect,
} as const

export type FormProps = {
	createMutation: MutationTrigger<MutationDefinition<unknown, BaseQueryFn<any, unknown, unknown, {}, {}>, string, unknown>>
	setModalState: React.Dispatch<React.SetStateAction<ModalState>>
	fields: FieldList
	additionalSubmissionContext?: Record<string, unknown>
	title?: string
}

const Form = (props: FormProps) => {
	const { createMutation, setModalState, fields, additionalSubmissionContext } = props
	const classes = useStyles({ variant: 'primary', isOpen: false, hasValue: false })

	const { getValidationSchema } = useFormValidation({ fields })
	const {
		watch,
		control,
		handleSubmit: resolveForm,
		formState: { isValid },
	} = useForm<unknown>({
		resolver: yupResolver(getValidationSchema()),
		mode: 'all',
		criteriaMode: 'firstError',
	})
	const formValues = watch()

	const onSubmit = resolveForm(async () => {
		const sanitizedFieldValues = Object.values(fields).reduce(
			(acc, { kind, name }, i) => ({ ...acc, [name]: kind === FieldKind.TEXT_AREA ? toText(formValues[name].data) : formValues[name] }),
			additionalSubmissionContext,
		)
		createMutation(sanitizedFieldValues)
		setModalState({ isOpen: false, modalKind: ModalKind.FORM })
	})

	const renderFormField = useCallback(
		(renderField: FieldInitProps<FieldKind>, index: number): JSX.Element => {
			const { kind, isRequired, label, name } = renderField

			let renderComponent = FIELD_COMPONENT_MAP[kind]
			let renderProps: FieldComponentProps<typeof kind>
			switch (kind) {
				case FieldKind.INPUT:
				case FieldKind.TEXT_AREA:
					renderProps = {
						placeholder: renderField.placeholder,
					} satisfies FieldComponentProps<FieldKind.TEXT_AREA | FieldKind.INPUT>
					break
				case FieldKind.DATE_TIME:
					const currentTime = DateTime.now()
					const nextFiveMinuteInterval = Math.ceil(currentTime.minute / 5) * 5
					const nextFiveMinuteIntervalDateTime = currentTime.set({ minute: nextFiveMinuteInterval })

					renderProps = {
						defaultValue: nextFiveMinuteIntervalDateTime.toJSDate(),
					} satisfies FieldComponentProps<FieldKind.DATE_TIME>

					break
				case FieldKind.SELECT:
					renderProps = {
						options: renderField.options,
						multiple: renderField.multiple,
						placeholder: renderField.placeholder,
					} satisfies FieldComponentProps<FieldKind.SELECT>
					break
				default:
					throw new Error(`Unsupported Form Field: ${kind}`)
			}

			return (
				<FormFieldContainer
					key={index}
					label={label}
					name={name}
					control={control}
					isRequired={isRequired}
					renderComponent={renderComponent}
					renderProps={renderProps}
				/>
			)
		},
		[control],
	)

	const renderFields = useMemo(() => fields.map((field, i) => renderFormField(field, i)), [fields, renderFormField])

	return (
		<form className={classes.form}>
			{props.title && <Typography children={props.title} variant="h4" sx={{ paddingBottom: 2, alignSelf: 'flex-start' }} />}
			<Divider orientation="horizontal" component={'div'} sx={{ height: '1px', width: '30%', display: 'block', alignSelf: 'flex-start' }} />
			<ul className={classes.formFields}>{renderFields}</ul>
			<div className={classes.buttonContainer}>
				<OutlinedButton className={classes.postButton} onClick={onSubmit} disabled={!isValid}>
					<PaperPlaneTilt size={ICON_SIZES.md} fontWeight="bold" style={{ marginRight: 10 }} /> Schedule
				</OutlinedButton>
			</div>
		</form>
	)
}

export default Form
