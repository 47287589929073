import { ICON_SIZES } from '@/constants/iconSizes'
import { useGetGroupsGridDataQuery, useGetGroupsGridMetaQuery, useToggleRemoveGroupsMutation } from '@/features/adminConsole/api/groupsApi'
import DataTable from '@/features/adminConsole/components/dataTable/DataTable'
import { XCircle } from '@phosphor-icons/react'
import { useCallback, useMemo, useState } from 'react'

const GroupChatList = () => {
	const [toggleRemoveGroups] = useToggleRemoveGroupsMutation()
	const [selectedEntities, setSelectedEntities] = useState<string[]>([])

	const handleDeleteEntities = useCallback(() => {
		toggleRemoveGroups({
			groupIds: selectedEntities,
		})
		setSelectedEntities([])
	}, [toggleRemoveGroups, selectedEntities])

	const tableActions = useMemo(
		() => [
			{
				label: 'Remove',
				icon: <XCircle size={ICON_SIZES.sm} weight="bold" />,
				onClick: handleDeleteEntities,
				isVisible: selectedEntities.length > 0,
			},
		],
		[handleDeleteEntities, selectedEntities],
	)
	return (
		<DataTable
			metaQuery={useGetGroupsGridMetaQuery}
			listQuery={useGetGroupsGridDataQuery}
			setSelectedEntities={setSelectedEntities}
			tableActions={tableActions}
			selectedEntities={selectedEntities}
		/>
	)
}

export default GroupChatList
