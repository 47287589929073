import { makeStyles } from '@material-ui/core'
import Select, { hasValue } from '../Selects/Select'
import { BaseSelectProps, SelectOption } from '../Selects/types'

const useStyles = makeStyles((theme) => ({
	selectContainer: ({}) => ({
		display: 'flex',
		flexDirection: 'row',
	}),
	input: ({}) => ({
		justifySelf: 'start',
		borderRadius: theme.spacing(1),
		minWidth: '200px',
		width: 'clamp(200px, max-content, 100%)',
		'&::placeholder': {
			color: theme.palette.grey[200],
		},
	}),
}))

type FormSelectProps = BaseSelectProps & {
	options: SelectOption[]
}

const FormSelect: React.FC<FormSelectProps> = ({ options, placeholder, onChange, ...props }) => {
	const classes = useStyles({ hasValue: hasValue(props.value) })

	return (
		<div className={classes.selectContainer}>
			<Select
				value={props.value}
				onChange={onChange}
				placeholder={placeholder}
				multiple={props.multiple}
				options={options}
				classnames={{
					input: classes.input,
				}}
			/>
		</div>
	)
}

export default FormSelect
