import { $generateHtmlFromNodes } from '@lexical/html'
import { AutoLinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNodeByKey } from 'lexical'
import { useEffect } from 'react'

import { MentionNode } from '../../nodes/MentionNode'
import { TMentionData } from '../MentionPlugin/MentionTypeaheadOption'

export type TFnOnChangeParams = { data: string; links?: string[]; mentions?: TMentionData[] }

export interface OnChangePluginProps {
	asHtml?: boolean
	onChange: (props: TFnOnChangeParams) => void
	resetTrigger?: number
}

const convertMapToArray = <T>(map: Map<any, T>): T[] => {
	const values = []
	map.forEach((value) => values.push(value))

	return values
}

const mentionNodeList = new Map<number | string, TMentionData>()
const linkNodeList = new Map<string, string>()

const OnChangePlugin = ({ onChange, asHtml = true, resetTrigger }: OnChangePluginProps) => {
	const [editor] = useLexicalComposerContext()

	useEffect(() => {
		mentionNodeList.clear()
		linkNodeList.clear()
	}, [resetTrigger])

	useEffect(() => {
		return editor.registerUpdateListener(({ editorState }) => {
			editorState.read(() => {
				const htmlString = $generateHtmlFromNodes(editor, null)

				onChange({
					data: htmlString,
					mentions: convertMapToArray<TMentionData>(mentionNodeList),
					links: convertMapToArray<string>(linkNodeList),
				})
			})
		})
	}, [asHtml, editor, onChange])

	useEffect(() => {
		if (!editor.hasNodes([MentionNode])) return

		return editor.registerMutationListener(MentionNode, (nodeMutations) => {
			nodeMutations.forEach((mutation, nodeKey) => {
				if (mutation === 'created' || mutation === 'updated') {
					editor.update(() => {
						const mentionNode = $getNodeByKey<MentionNode>(nodeKey)
						mentionNodeList.set(nodeKey, mentionNode.__mention)
					})
				} else if (mutation === 'destroyed') {
					mentionNodeList.delete(nodeKey)
				}
			})
		})
	}, [editor])

	useEffect(() => {
		if (!editor.hasNodes([AutoLinkNode])) return

		return editor.registerMutationListener(AutoLinkNode, (nodeMutations) => {
			nodeMutations.forEach((mutation, nodeKey) => {
				if (mutation === 'created' || mutation === 'updated') {
					editor.update(() => {
						const linkNode = $getNodeByKey<AutoLinkNode>(nodeKey)
						linkNodeList.set(nodeKey, linkNode.__url)
					})
				} else if (mutation === 'destroyed') {
					const linkNode = linkNodeList.get(nodeKey)
					if (linkNode !== undefined) {
						linkNodeList.delete(nodeKey)
					}
				}
			})
		})
	}, [editor])

	return null
}

export default OnChangePlugin
