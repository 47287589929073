import LinkPreview from '@/components/Feeds/LinkPreview'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import SecureImage from '@/components/Messages/SecureImage'
import { STYLES } from '@/constants'
import SharedEntity from '@/features/shareEntity/components/SharedEntity'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import useSocket from '@/hooks/useSocket'
import { getUserInfo } from '@/store/auth'
import { Message } from '@/store/messages/types'
import { isCurrentUser } from '@/utils/authHandlers'
import { getFromNow } from '@/utils/dateTime2'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useInView } from 'react-intersection-observer'

const useStyles = makeStyles((theme) => ({
	root: ({ myMessage }: any) => ({
		display: 'flex',
		flexDirection: 'row',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		justifyContent: myMessage ? 'end' : 'start',
		padding: '5px 15px',
	}),
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
		marginRight: 10,
	},
	greyText: {
		color: theme.palette.text.secondary,
	},
	content: ({ myMessage }: any) => ({
		...theme.typography.body2,
		'& > p': {
			...theme.typography.body2,
			margin: 0,
		},
		'& a': {
			...theme.typography.body2,
			color: theme.palette.common.black,
			textDecoration: 'underline',
		},
		overflowWrap: 'break-word',
		width: '100%',
	}),
	contentContainer: ({ myMessage }: any) => ({
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		alignItems: myMessage ? 'flex-end' : 'flex-start',
	}),
	bubble: ({ myMessage }: any) => ({
		display: 'flex',
		width: 'fit-content',
		maxWidth: '100%',
		padding: 15,
		backgroundColor: myMessage ? theme.palette.primary.main : theme.palette.grey[200],
		color: myMessage ? theme.palette.common.white : theme.palette.common.black,
		borderRadius: myMessage ? '12px 12px 0 12px' : '12px 12px 12px 0',
	}),
	container: {
		display: 'flex',
		flexDirection: 'row',
		maxWidth: '22vw',
		[theme.breakpoints.down('md')]: {
			maxWidth: '50vw',
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '70vw',
		},
	},
	time: {
		marginTop: 5,
		color: theme.palette.text.secondary,
		fontSize: 15,
	},
	shareItem: {
		width: 344,
		margin: '20px 0',
		boxShadow: '0px 4px 8px -1px rgba(34, 60, 80, 0.2)',
		borderRadius: 7,
	},
	sharePostContainer: {
		maxWidth: 550,
		width: '32vw',
		padding: '20px 0',
		overflowY: 'auto',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '70vw',
		},
	},
	previewLinkContainer: {
		width: 344,
		padding: '20px 0',
		borderRadius: 7,
	},
	image: {
		width: 344,
		margin: '20px 0',
		borderRadius: 7,
	},
	imageMessageContainer: {
		width: 344,
		height: 344,
	},
}))

interface MessageBubbleProps {
	id: string
	message: Message
}

const MessageBubble = ({ id, message }: MessageBubbleProps) => {
	const currentUser = useSelector(getUserInfo)

	const { changeMessageViewStatus } = useSocket()

	const { message: text, photoUrl, linkPreviewUrl, sharedEntityId, sharedEntityType, viewed } = message

	const isCurrentUserMessage = isCurrentUser(currentUser.id, message.sourceUser!.id!)

	const classes = useStyles({ myMessage: isCurrentUserMessage })
	const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 })
	useEffect(() => {
		if (!viewed && !isCurrentUserMessage && inView) {
			changeMessageViewStatus({
				id,
				targetUserId: message.targetUserId,
				sourceUserId: message.sourceUserId,
				userId: currentUser.id,
			})
		}
	}, [changeMessageViewStatus, currentUser.id, id, inView, isCurrentUserMessage, message.sourceUserId, message.targetUserId, viewed])

	return (
		<div ref={ref} id={`message-${id}`} className={classes.root}>
			<div className={classes.container}>
				{!isCurrentUserMessage && (
					<LazyLoadProfileImage
						borderRadius={50}
						className={classes.profileImage}
						externalId={message.sourceUser?.externalId}
						photoUrl={message.sourceUser?.photoUrl}
					/>
				)}
				<div className={classes.contentContainer}>
					{text && (
						<div className={classes.bubble}>
							<DescriptionHtml className={classes.content} description={text || ''} />
						</div>
					)}
					{sharedEntityId && sharedEntityType ? (
						<div className={sharedEntityType === EntityTypes.post ? classes.sharePostContainer : classes.shareItem}>
							<SharedEntity variant="primary" sharedEntityId={sharedEntityId} sharedEntityType={sharedEntityType} />
						</div>
					) : null}

					{linkPreviewUrl && (
						<div className={classes.previewLinkContainer}>
							<LinkPreview userId={currentUser.id} url={linkPreviewUrl} />
						</div>
					)}
					{photoUrl && (
						<SecureImage containerClass={classes.imageMessageContainer} showSkeleton={true} src={photoUrl} className={classes.image} />
					)}
					<Typography className={classes.time}>{getFromNow(message.createdAt)}</Typography>
				</div>
			</div>
		</div>
	)
}

export default MessageBubble
