import { NavTabs } from '../../../../components/NavTabs'
import { ADMIN_ROOT_PATH, Route } from '../../routes/config'
import GroupChatList from '../../components/groups/GroupListComponent'

const TABS = [
	{
		label: 'List',
		route: `${ADMIN_ROOT_PATH}/${Route.Groups}/table`,
		exact: false,
		component: <GroupChatList />,
	},
]

const UsersListPage = () => {
	return (
		<div style={{ flex: 1, flexDirection: 'column' }}>
			<NavTabs tabs={TABS} withHistory />
		</div>
	)
}

export default UsersListPage
