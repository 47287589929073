import { PATHS, ROLE } from '@/constants'
import { PHOTO_BASE_URL } from '@/constants/configuration'
import { ErrorData, SearchConfig, UserData, UserResponseStatuses } from '@/interfaces/common'
import { AppDispatch } from '@/store'
import { StoreSearchParams } from '@/store/types'
import { getUserRole } from '@/utils/authHandlers'
import { URL_PATTERN, getShortCounterLabel } from '@navengage/sen-shared-assets'

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { isFunction, isObject, isString } from 'lodash'

const toCamel = (s: string): string => {
	return s.replace(/([-_][a-z])/gi, ($1) => {
		return $1.toUpperCase().replace('-', '').replace('_', '')
	})
}

export const objectToCamel = (obj: { [k: string]: any }) =>
	Object.keys(obj).reduce(
		(acc: any, key: string) => ({
			...acc,
			[toCamel(key)]: obj[key],
		}),
		{},
	)

export const objectToCamelDeep = (obj: { [k: string]: any }) =>
	Object.keys(obj).reduce((acc: any, key: string) => {
		const isArray = Array.isArray(obj[key])
		const isObjectType = isObject(obj[key])

		const newChild: any = isArray
			? obj[key].map((arrObj: any) => objectToCamelDeep(arrObj))
			: isObjectType
			? objectToCamelDeep(obj[key])
			: obj[key]

		return {
			...acc,
			[toCamel(key)]: newChild,
		}
	}, {})

export const createOptionArray = (arr: any[], field: string) =>
	arr.map((item) => ({
		label: item[field],
		value: item.id,
	}))

export const getRoleBadgeLabel = (typeId: number) => {
	const labelByRole = {
		[ROLE.STUDENT]: 'Student',
		[ROLE.FACULTY]: 'Faculty/Staff',
		[ROLE.STAFF]: 'Faculty/Staff',
		[ROLE.UNKNOWN]: 'Not found',
		[ROLE.GUEST]: 'Not found',
	}
	const role = getUserRole(typeId)

	return labelByRole[role]
}

export const getFullName = (firstName?: string, lastName?: string) => `${firstName} ${lastName}`
export const formPrimaryTitle = (parts: Array<string | null> = [], separator = ', ') => {
	return parts.filter((titlePart) => !!titlePart).join(separator)
}

export const getNormalizedUserData = (users: { appUser: Array<string | null> }[]): UserData[] =>
	users.reduce((acc: UserData[], { appUser }) => {
		const [id, firstName, lastName, email, externalId, photoUrl] = appUser
		const userData: UserData = {
			id: +id,
			firstName,
			lastName,
			fullName: `${firstName} ${lastName}`,
			email,
			externalId,
			photoUrl,
		}

		return [...acc, userData]
	}, [])

const eventResponseStatus = {
	[UserResponseStatuses.going]: 'Going',
	[UserResponseStatuses.interested]: 'Interested',
	[UserResponseStatuses.notInterested]: 'Not Interested',
}

export const getEventResponseLabel = (response: string) => (eventResponseStatus as any)[response]

export const getCurrentFilterValues = (storedSearch: StoreSearchParams, config: SearchConfig) => {
	const fetchMore = config.fetchMore
	const currentConfig = fetchMore ? storedSearch : config
	const currentPage = currentConfig.page

	const params = currentConfig.params
	const page = fetchMore && currentPage ? currentPage + 1 : 1
	const filterIsReady = storedSearch.filterIsReady || !!config.filterIsReady

	return {
		params,
		page,
		filterIsReady,
		fetchMore,
		currentConfig,
	}
}

export const prepareError = (err: any): ErrorData => ({
	status: err?.message?.response?.status,
	statusText: err?.message?.response?.statusText,
	message: err?.message?.response?.data?.error
		? err?.message?.response?.data?.error
		: err?.message?.toJSON
		? err?.message?.toJSON().message
		: 'Unknown error',
})

export const storeError = (dispatch: AppDispatch, setError: ActionCreatorWithPayload<ErrorData, string>, err: any) => {
	const errorData = prepareError(err)

	console.error(errorData)
	dispatch(setError(errorData))
}

export const copyToClipboard = (text: string) => {
	//@ts-ignore
	if (window.clipboardData && window.clipboardData.setData) {
		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		//@ts-ignore
		return window.clipboardData.setData('Text', text)
	} else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
		var textarea = document.createElement('textarea')
		textarea.textContent = text
		textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea)
		textarea.select()
		try {
			return document.execCommand('copy') // Security exception may be thrown by some browsers.
		} catch (ex) {
			console.warn('Copy to clipboard failed.', ex)
			return false
		} finally {
			document.body.removeChild(textarea)
		}
	}
}

export const createShareLink = (path: string) => `${window.location.host}${path}`
export const createPhotoUrl = (imageName: string, directory: string) => `${PHOTO_BASE_URL}/${directory}/${imageName}`

export const scrollToElement = (id: string, offset: number, timer = 300) => {
	const timeoutId = setTimeout(() => {
		const element = document.getElementById(id)
		const elementPositionY = element?.getBoundingClientRect()?.top ?? 0
		const y = elementPositionY + window.pageYOffset + offset

		window.scrollTo({ top: y, behavior: 'smooth' })
	}, timer)

	return timeoutId
}

export const getCountLabel = (count: number | string, label: string) => {
	const numberOf = getShortCounterLabel(count)

	return `${numberOf} ${label}${numberOf === '1' ? '' : 's'}`
}

export const profileLink = (userId: number) => PATHS.APP.NETWORK_USER(`${userId}`)

export function createOptionMapper<T>(value: string, label?: string): (item: any) => { value: T; label: string }
export function createOptionMapper<T>(value: string, label?: (item: any) => string): (item: any) => { value: T; label: string }
export function createOptionMapper(value, label) {
	return (item) => {
		return {
			value: item[value],
			label: label ? (isFunction(label) ? label(item) : item[label]) : item[value],
		}
	}
}

export const applyInterestedStatus: <T extends { response: any }>(
	entity: T,
) => T & { responseStatus: null | UserResponseStatuses.interested } = (entity) => {
	return {
		...entity,
		responseStatus:
			entity.response && entity.response.response === UserResponseStatuses.interested ? UserResponseStatuses.interested : null,
	}
}

export const isValidUrl = (s: string) => {
	return isString(s) && URL_PATTERN.test(s)
}
