import { ComponentMapProps, OneOrMore } from '@/types/common'
import { ReactNode } from 'react'
import { AnySchema } from 'yup'
import { FIELD_COMPONENT_MAP } from './Form'

export enum FieldKind {
	INPUT = 'input',
	TEXT_AREA = 'textArea',
	DATE_TIME = 'dateTime',
	SELECT = 'select',
	// DATE = 'date',
	// RADIO = 'radio',
	// CHECKBOX = 'checkbox',
	// MULTI_SELECT = 'multiSelect',
}

export type FieldBase<K extends FieldKind> = {
	kind: K
	label: string & ReactNode
	name: string
	isRequired: boolean
	icon?: ReactNode
	enabled?: boolean
	customValidation?: AnySchema | null
}

export type FieldComponentMap = typeof FIELD_COMPONENT_MAP
export type FieldComponent<K extends FieldKind> = FieldComponentMap[K]

export type FieldComponentPropsMap = ComponentMapProps<FieldComponentMap>
export type FieldComponentProps<K extends FieldKind> = Omit<FieldComponentPropsMap[K], 'onChange' | 'value'>

export type FieldInitPropsMap = {
	[K in FieldKind]: FieldBase<K> & FieldComponentProps<K>
}
export type FieldInitProps<T extends FieldKind> = FieldInitPropsMap[T]

export type FieldList = OneOrMore<FieldInitPropsMap[FieldKind]>
