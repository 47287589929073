import { STYLES } from '@/constants'
import { CircularProgress, Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Divider, styled } from '@mui/material'
import { StyleFunction, unstable_styleFunctionSx } from '@mui/system'
import classNames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { v4 as uuidv4 } from 'uuid'

interface StylesProps {
	height?: number
	internalScroll?: boolean
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: ({ height, internalScroll }) => ({
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: height || '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		overflow: height || internalScroll ? 'auto' : 'hidden',
	}),
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		'&>div.infinite-scroll-component__outerdiv': {
			width: STYLES.FILL_AVAILABLE_WIDTH,
			height: '100%',
		},
	},
	cardContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'flex-start',
		gridGap: 10,
		margin: '1vh 2vw',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-evenly',
		},
	},
	controlPanel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '3vh 0 6vh 0',
	},
	infoText: {
		fontSize: '0.9em',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
	},
}))

type InfiniteScrollComponentProps = {
	items: any[]
	renderItem: (item: any, index: number) => React.ReactNode
	getKey?: (item: any, index: number) => React.Key
	withDivider?: boolean
	placeholder?: React.ReactNode
	onLoadMore: () => void
	canLoadMore: boolean
	classnames?: {
		root?: string
		contentContainer?: string
		content?: string
	}
	scrollToItemId?: string
	resetScroll?: boolean
	scrollThreshold?: number
	sx?: React.CSSProperties
	renderLoader?: () => JSX.Element | string
	height?: number
	internalScroll?: boolean
} & StylesProps

const DEFAULT_SCROLL_THRESHOLD = 0.8

const InfiniteScrollComponent = ({
	placeholder,
	onLoadMore,
	sx,
	canLoadMore = false,
	classnames = {
		root: '',
		contentContainer: '',
		content: '',
	},
	items = [],
	renderItem,
	getKey = (item, index) => item?.id ?? index,
	withDivider,
	height,
	resetScroll,
	internalScroll = false,
	scrollThreshold = DEFAULT_SCROLL_THRESHOLD,
	renderLoader,
}: InfiniteScrollComponentProps) => {
	const classes = useStyles({ height, internalScroll })

	const [scrollContainerId] = useState(() => uuidv4())
	const scrollRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (resetScroll && scrollRef?.current) {
			scrollRef.current.scrollTo(0, 0)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetScroll])

	const renderList = useMemo(() => {
		if (items.length === 0) {
			return placeholder
		}

		return items.map((item, index) => (
			<React.Fragment key={getKey(item, index)}>
				{renderItem(item, index)}
				{withDivider && index != items.length - 1 && <Divider />}
			</React.Fragment>
		))
	}, [items, renderItem, withDivider, placeholder, getKey])

	return (
		<div id={scrollContainerId} ref={scrollRef} className={classNames(classnames.root, classes.root)}>
			<div style={sx} className={classNames(classnames.contentContainer, classes.contentContainer)}>
				<InfiniteScroll
					className={classNames(classnames.content, classes.cardContainer)}
					dataLength={items?.length}
					next={onLoadMore}
					hasMore={canLoadMore}
					loader={
						renderLoader ? (
							renderLoader()
						) : (
							<div className={classes.controlPanel}>
								<CircularProgress />
							</div>
						)
					}
					endMessage={
						placeholder ? <Typography className={classNames(classes.infoText, classes.controlPanel)}>{placeholder}</Typography> : null
					}
					scrollableTarget={height || internalScroll ? scrollContainerId : undefined}
					scrollThreshold={scrollThreshold}
				>
					{renderList}
				</InfiniteScroll>
			</div>
		</div>
	)
}

export default InfiniteScrollComponent
export const StyledScrollComponent = styled(InfiniteScrollComponent)<InfiniteScrollComponentProps>(
	unstable_styleFunctionSx as StyleFunction<InfiniteScrollComponentProps>,
)
