import { GridConfigDto, QueryGridFiltersDto, QueryGridSortByDto } from '../types/dto/Grid'
import { generatedBaseApi as api } from './baseGeneratedApi'
export const addTagTypes = [
	'Analytics User',
	'Analytics User Membership',
	'Analytics Student',
	'Analytic Faulty/Staff',
	'Analytics User App Usage',
	'Analytic User Goals',
] as const
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			usersControllerFindOneById: build.query<UsersControllerFindOneByIdApiResponse, UsersControllerFindOneByIdApiArg>({
				query: (queryArg) => ({ url: `/analytics/users/user/${queryArg.id}` }),
				providesTags: ['Analytics User'],
			}),
			usersControllerFindOrganizationMembershipsById: build.query<
				UsersControllerFindOrganizationMembershipsByIdApiResponse,
				UsersControllerFindOrganizationMembershipsByIdApiArg
			>({
				query: (queryArg) => ({
					url: `/analytics/users/user/${queryArg.id}/org-memberships`,
					params: { take: queryArg.take, skip: queryArg.skip },
				}),
				providesTags: ['Analytics User Membership'],
			}),
			usersControllerFindStudentProfile: build.query<UsersControllerFindStudentProfileApiResponse, UsersControllerFindStudentProfileApiArg>(
				{
					query: (queryArg) => ({ url: `/analytics/users/student/${queryArg.externalId}` }),
					providesTags: ['Analytics Student'],
				},
			),
			usersControllerFindFacultyStaffProfile: build.query<
				UsersControllerFindFacultyStaffProfileApiResponse,
				UsersControllerFindFacultyStaffProfileApiArg
			>({
				query: (queryArg) => ({ url: `/analytics/users/faculty-staff/${queryArg.externalId}` }),
				providesTags: ['Analytic Faulty/Staff'],
			}),
			usersControllerGetAppUsageById: build.query<UsersControllerGetAppUsageByIdApiResponse, UsersControllerGetAppUsageByIdApiArg>({
				query: (queryArg) => ({ url: `/analytics/users/user/${queryArg.id}/app-usage` }),
				providesTags: ['Analytics User App Usage'],
			}),
			usersControllerGetGoalsById: build.query<UsersControllerGetGoalsByIdApiResponse, UsersControllerGetGoalsByIdApiArg>({
				query: (queryArg) => ({ url: `/analytics/users/user/${queryArg.id}/goals` }),
				providesTags: ['Analytic User Goals'],
			}),
			usersControllerGetGridMeta: build.query<UsersControllerGetGridMetaApiResponse, UsersControllerGetGridMetaApiArg>({
				query: () => ({ url: `/analytics/users/grid/meta` }),
				providesTags: ['Analytics User'],
			}),
			usersControllerGetGridData: build.query<UsersControllerGetGridDataApiResponse, UsersControllerGetGridDataApiArg>({
				query: (queryArg) => ({
					url: `/analytics/users/grid/data`,
					params: { take: queryArg.take, skip: queryArg.skip, filters: queryArg.filters, sortBy: queryArg.sortBy },
				}),
				providesTags: ['Analytics User'],
			}),
		}),
		overrideExisting: false,
	})
export { injectedRtkApi as analyticsUsersApi }
export type UsersControllerFindOneByIdApiResponse = /** status 200  */ BaseUser
export type UsersControllerFindOneByIdApiArg = {
	id: number
}
export type UsersControllerFindOrganizationMembershipsByIdApiResponse = /** status 200 Successfully received items result */ {
	totalItems?: number
	items?: OrganizationMembership[]
}
export type UsersControllerFindOrganizationMembershipsByIdApiArg = {
	id: number
	take?: number
	skip?: number
}
export type UsersControllerFindStudentProfileApiResponse = /** status 200  */ Student
export type UsersControllerFindStudentProfileApiArg = {
	externalId: string
}
export type UsersControllerFindFacultyStaffProfileApiResponse = /** status 200  */ FacultyStaff
export type UsersControllerFindFacultyStaffProfileApiArg = {
	externalId: string
}
export type UsersControllerGetAppUsageByIdApiResponse = /** status 200  */ UserAppUsageDto
export type UsersControllerGetAppUsageByIdApiArg = {
	id: number
}
export type UsersControllerGetGoalsByIdApiResponse = /** status 200  */ UserGoalsDto
export type UsersControllerGetGoalsByIdApiArg = {
	id: number
}
export type UsersControllerGetGridMetaApiResponse = /** status 200  */ GridConfigDto
export type UsersControllerGetGridMetaApiArg = void
export type UsersControllerGetGridDataApiResponse = /** status 200 Successfully received items result */ {
	totalItems?: number
	items?: BaseUser[]
}
export type UsersControllerGetGridDataApiArg = {
	take?: number
	skip?: number
	filters?: QueryGridFiltersDto
	sortBy?: QueryGridSortByDto
}
export type BaseUser = {
	id: number
	bio: string | null
	appUserTypeId: number
	analyticsRole: string | null
	campusId: number | null
	createdAt: string
	lastLogin: string
	displayName: string | null
	email: string
	externalId: string
	firstName: string
	instagramUrl: string | null
	isArchived: boolean
	lastName: string
	photoUrl: string | null
	preferredPronouns: string
	primaryTitle: string
	primaryTitleDepartment: string | null
	schoolId: number
	updatedAt: string | null
	linkedinUrl: string | null
	twitterUrl: string | null
	facebookUrl: string | null
	websiteUrl: string | null
	youtubeUrl: string | null
}
export type OrganizationMembership = {
	id: number
	membershipTitle: string
	userId: number
	userEmail: string
	startDate: string
	endDate: string | null
	hidden: boolean
	exclude: boolean
	isRolePrimaryContactAffairs: boolean
	isRolePrimaryContactFinance: boolean
	isRolePrimaryContactPublic: boolean
	isRolePrimaryLeader: boolean
	organizationId: string
	organizationMembershipRequestId: number
	organizationMembershipTypeId: number
	pointOfContact: boolean
	privacyLevel: string
	reasonForExclusion: number
	referenceToReplacedId: number
	createdAt: string
	updatedAt: string
	organizationName: string | null
}
export type IntendedGraduationDateDto = {
	term: 'Summer' | 'Winter' | 'Fall' | 'Spring'
	year: number
}
export type Student = {
	id: string
	firstName: string
	lastName: string
	academicCollegeId: number
	major: string
	intendedGraduationDate: IntendedGraduationDateDto | null
	semesterStanding: string
	schoolId: number
	email: string
	campusId: number
	academicCollegeName: string
	externalId: string
	progEffdt: string
	createdAt: string
	updatedAt: string
}
export type FacultyStaff = {
	id: string
	academicCollegeName: string
	academicCollegeId: number
	campusId: number
	createdAt: string
	department: string
	positionTitle: string
	schoolId: number
	updatedAt: string
}
export type UserAppUsageDto = {
	postCount: number
	groupsCount: number
	connectionsCount: number
}
export type UserGoals = {
	id: number
	userId: number
	typesOfEvents: string
	wouldLikeToJoinANewStudentOrganization: boolean
	numberOfOrganizationsToJoin: string
	sizeOfOrganizationWouldLikeToJoin: string
	descriptionOfOrganizationWouldLikeToJoin: string
	organizationsWouldLikeToJoin: string
	wouldLikeToVolunteer: boolean
	causesWouldLikeToVolunteerFor: string
	numberOfHoursWouldLikeToVolunteer: string
	whenWouldLikeToVolunteer: string
	interestedInJoiningAStudentServiceOrganization: boolean
	wouldLikeToStudyAbroad: boolean
	wouldLikeToParticipateIn: string
	countriesWhereWouldLikeToStudyAbroad: string
	whileAbroadWouldLikeTo: string
	whenWouldLikeToStudyAbroad: string
	wouldLikeToCompleteUndergraduateResearch: boolean
	areasOfResearchOrCreativeInquiryInterestedIn: string
	whenWouldLikeToCompleteResearch: string
	currentlyHaveAJob: boolean
	whenLookingForNextJobOrInternship: string
	typeOfJobOrInternshipWouldLikeToComplete: string
	professionalGoalsForAfterGraduation: string
	interestedInTheseServices: string
	currentlyLookingForAJob: boolean
	typeOfJobCurrentlyLookingFor: string
	experiencesWouldLikeToHaveOutsideOfClassroom: string
	createdAt: string
	updatedAt: string
	emailsAboutGrantProgramOffers: boolean
}
export type UserGoalsDto = {
	history: UserGoals[]
}

export const {
	useUsersControllerFindOneByIdQuery,
	useUsersControllerFindOrganizationMembershipsByIdQuery,
	useUsersControllerFindStudentProfileQuery,
	useUsersControllerFindFacultyStaffProfileQuery,
	useUsersControllerGetAppUsageByIdQuery,
	useUsersControllerGetGoalsByIdQuery,
	useUsersControllerGetGridMetaQuery,
	useUsersControllerGetGridDataQuery,
} = injectedRtkApi
