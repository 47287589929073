import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { PATHS } from '@/constants'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { UserData } from '@/interfaces/common'
import classNames from 'classnames'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { UserPrimaryTitle } from '../UserProfile/UserPrimaryTitle'

const useStyles = makeStyles((theme) => ({
	connectionRequestRoot: {
		display: 'flex',
		flexDirection: 'row',
		width: 'auto',
		margin: 15,
		border: `solid 1px ${theme.palette.divider}`,
		padding: 15,
		alignItems: 'center',
		cursor: 'pointer',
		backgroundColor: theme.palette.background.paper,
	},
	connectionRequestContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
	},
	profileImage: {
		width: 60,
		height: 60,
		minWidth: 60,
		minHeight: 60,
		marginRight: 20,
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		marginTop: 10,
	},
	userName: {
		color: theme.palette.text.primary,
	},
	text: {
		fontSize: 15,
		color: theme.palette.text.secondary,
		textTransform: 'capitalize',
	},
}))

interface NetworkUserRowProps {
	user: UserData
	profileImageRadius?: number
	className?: string
}

const NetworkUserRow = ({ user, profileImageRadius = 5, className = '' }: NetworkUserRowProps) => {
	const classes = useStyles()
	const { push } = useHistory()

	const { externalId, fullName, type, campus, primaryTitle, primaryTitleDepartment, id, photoUrl } = user

	const handleOpenUserProfile = useCallback(() => {
		push(PATHS.APP.NETWORK_USER(`${id}`))
	}, [id, push])

	return (
		<div className={classNames(classes.connectionRequestRoot, className)} onClick={handleOpenUserProfile}>
			<LazyLoadProfileImage
				borderRadius={profileImageRadius}
				className={classes.profileImage}
				externalId={externalId}
				photoUrl={photoUrl}
			/>
			<div className={classes.connectionRequestContent}>
				<Typography className={classes.userName}>{fullName}</Typography>
				<UserPrimaryTitle className={classes.text} primaryTitle={primaryTitle} primaryTitleDepartment={primaryTitleDepartment} />
				<Typography className={classes.text}>{`${type} ${campus ? ` | ${campus}` : ''}`}</Typography>
			</div>
		</div>
	)
}

export default NetworkUserRow
