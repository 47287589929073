import { OneOrMore } from '@/types/common'
import { MODAL_COMPONENT_MAP } from './AdminModalContent'

export enum ModalKind {
	ENTITY_SEARCH = 'entitySearch',
	LEARN_MORE = 'learnMore',
	FORM = 'form',
}

type ComponentMap = typeof MODAL_COMPONENT_MAP

export type ModalComponentContent<T extends keyof ComponentMap> = (props: React.ComponentProps<ComponentMap[T]>) => JSX.Element

export type ModalComponentContentProps<T extends keyof ComponentMap> = Parameters<ModalComponentContent<T>>['0']

export type ModalContentPropMap = {
	[K in ModalKind]: React.ComponentProps<ComponentMap[K]>
}

export type ModalState = {
	modalKind: ModalKind
	isOpen: boolean
}
export type ContentPropsMapKey<T extends keyof ModalContentPropMap> = `${T}Props`

export type ModalContentProps<T extends ModalKind[]> = {
	[K in T[number] as ContentPropsMapKey<K>]: Omit<ModalComponentContentProps<K>, 'setModalState'>
}

export type TModalConfig<T extends OneOrMore<ModalKind>> = T extends null
	? never
	: ModalContentProps<T> & {
			modalKinds: T
	  }
