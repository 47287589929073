import { Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { UserOrganizationTypes } from '@/anthology/organizations/types/UserOrganizationTypes'
import {
	CameraIcon,
	CampusIcon,
	ColorButton,
	CommonModalCard,
	IconButtonWithLabel,
	ImageUploadField,
	Modal,
	UserGroupIcon,
} from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import CardSelectButton from '@/components/CardSelect/CardSelectButton'
import CardSelectOptionsContainer from '@/components/CardSelect/CardSelectOptionsContainer'
import LinkPreview from '@/components/Feeds/LinkPreview'
import SelectPostingAs from '@/components/Feeds/SelectPostingAs'
import { FILE_FORMATS, STYLES } from '@/constants'
import { getActiveCampuses, selectShowCampuses } from '@/features/campus/slice'
import useImageUploadError from '@/hooks/useImageUploadError'
import { getFeedsFilterOption } from '@/store/feeds'
import useFeedPostById from '@/features/posts/hooks/useFeedPostById'
import SharedEntity from '@/features/shareEntity/components/SharedEntity'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useCurrentUser } from '@/hooks/userHooks'
import { globalStates, useGlobalState, useGlobalStateSetter } from '@/lib/globalState'
import { FeedPost, UserAudience, UserAudienceScopes } from '@/store/feeds/types'
import Editor from '../Editor'
import { OnChangePluginProps } from '../Editor/plugins/OnChangePlugin'
import { Iff } from '../Iff'
import SchedulePostCardSubTitle from './SchedulePost/CardSubtitle'
import SchedulePostFormCard from './SchedulePost/FormCard'
import { ScheduleIcon } from './SchedulePost/Icons'
import useCurrentUserOrgs from './hooks/useCurrentUserOrgs'
import { useFeedPostCreator } from './hooks/useFeedPostCreator'

const useStyles = makeStyles((theme) => ({
	rootCard: {
		minWidth: 530,
		maxWidth: 660,
		width: '70vw',
		[theme.breakpoints.down('sm')]: {
			width: '95vw',
			maxWidth: 'none',
			minWidth: 'auto',
		},
	},
	tagsRootCard: {
		minWidth: 500,
		[theme.breakpoints.down('sm')]: {
			width: '95vw',
			maxWidth: 'none',
			minWidth: 'auto',
		},
	},
	campusRootCard: {
		minWidth: 550,
		[theme.breakpoints.down('sm')]: {
			width: '95vw',
			maxWidth: 'none',
			minWidth: 'auto',
		},
	},
	usersAudienceRootCard: {
		minWidth: 600,
		[theme.breakpoints.down('sm')]: {
			width: '95vw',
			maxWidth: 'none',
			minWidth: 'auto',
		},
	},
	textEditor: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		minHeight: 'auto',
		maxWidth: 'none',
		minWidth: 530,
		marginBottom: 10,
		[theme.breakpoints.down('sm')]: {
			width: STYLES.FILL_AVAILABLE_WIDTH,
			maxWidth: 'none',
			minWidth: 'auto',
		},
	},
	cardHeader: {
		borderBottom: 'none',
	},
	profileImage: {
		width: 50,
		height: 50,
		minWidth: 50,
		minHeight: 50,
		marginRight: 10,
	},
	userInfoRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	rootContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	addPhotoButton: {
		color: theme.palette.primary.main,
	},
	icon: {
		width: 25,
		height: 25,
	},
	footer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	displayColumn: {
		flexDirection: 'column',
	},
	blockContainer: {
		padding: 15,
		borderTop: `solid 1px ${theme.palette.divider}`,
	},
	textArea: {
		overflow: 'hidden',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-root': {
			height: '100%',
			alignItems: 'baseline',
		},
	},
	select: {
		width: '48%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',

		[theme.breakpoints.down('sm')]: {
			width: STYLES.FILL_AVAILABLE_WIDTH,
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	outlinedSelect: {
		color: theme.palette.text.primary,
	},
	selectContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	requiredMark: {
		color: theme.colors.red[500],
	},
	tagsContainer: {
		borderTop: `solid 1px ${theme.palette.divider}`,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 48%)`,
		justifyContent: 'space-evenly',
		gridGap: 10,
		padding: '4vh 4vw 8vh 4vw',
	},
	optionsContainer: {
		borderTop: `solid 1px ${theme.palette.divider}`,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '4vh 4vw 8vh 4vw',
		maxHeight: '30vh',
		overflow: 'auto',
	},
	usersAudienceOption: {
		marginBottom: '4vh',
	},
	contentContainer: {
		maxHeight: '50vh',
		overflow: 'auto',
		paddingBottom: 10,
	},
	scheduleButton: {
		width: 150,
		color: theme.palette.common.black,
		marginRight: theme.spacing(2),
		paddingTop: 7,
		paddingBottom: 7,
		'&:hover': {
			color: theme.palette.common.black,
			backgroundColor: theme.palette.grey[200],
		},
	},
	postButton: {
		width: 150,
		padding: 7,
	},
	uploadInput: {
		display: 'none',
	},
	tooltip: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.specialColors.blacktransparent80,
		padding: '5px 20px',
	},
	popover: {
		pointerEvents: 'none',
	},
	tagSelect: {
		margin: '10px 0',
		width: '35%',
		color: 'black',
		[theme.breakpoints.down('sm')]: {
			width: STYLES.FILL_AVAILABLE_WIDTH,
		},
	},
	footerSelects: {
		width: 'calc(100% - 165px)',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	content: {
		margin: '0 20px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	uploadField: {
		marginBottom: 30,
	},
	modalActions: {
		marginTop: theme.spacing(2),
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
}))

const toText = (value: string) => {
	return value.replace(/<\/?[^>]+(>|$)/g, '')
}

const determineAudience = ({
	defaultUserAudience,
	defaultAudienceScope,
	sharedPost,
}: {
	defaultUserAudience: UserAudience
	defaultAudienceScope: UserAudienceScopes
	sharedPost?: FeedPost
}) => ({
	usersAudience: Number(sharedPost?.usersAudience ?? defaultUserAudience),
	audienceScope: Number(sharedPost?.usersAudienceScope ?? defaultAudienceScope),
})

export const CreateFeedsModal = () => {
	const [createFeedsModal] = useGlobalState(globalStates.createFeedsModal)
	if (!createFeedsModal.isOpen) {
		return null
	}
	return <CreateFeedsModalContent />
}

const CreateFeedsModalContent = () => {
	const [createFeedsModal] = useGlobalState(globalStates.createFeedsModal)

	const { isOpen, isEdit, isSchedule } = createFeedsModal
	const currentUser = useCurrentUser()
	const { createPost, updatePost } = useFeedPostCreator()

	const {
		tag,
		usersAudience: defaultUserAudience,
		audienceScope: defaultAudienceScope,
		campus,
		message,
		photoUrl,
		activeSince,
		sharedEntity,
		linkPreviewUrl,
		ownerOrganizationId,
	} = createFeedsModal.content

	const showCampuses = useSelector(selectShowCampuses)
	const campuses = useSelector(getActiveCampuses)

	const { data: sharedPost } = useFeedPostById(
		sharedEntity?.sharedEntityId,
		!sharedEntity?.sharedEntityId || sharedEntity.sharedEntityType !== EntityTypes.post,
	)
	const { usersAudience, audienceScope } = determineAudience({
		defaultUserAudience: defaultUserAudience ?? UserAudience.COMMUNITY,
		defaultAudienceScope: defaultAudienceScope ?? UserAudienceScopes.ALL_USERS,
		sharedPost,
	})

	const isRepost = !!sharedPost?.id

	// No need to check for userAudience as for network reposting is disabled
	const disableAudienceChange = isRepost && audienceScope !== UserAudienceScopes.ALL_USERS

	const {
		usersAudience: usersAudienceOptions,
		organizationAudience: organizationAudienceOptions,
		tags: tagsOptions,
	} = useSelector(getFeedsFilterOption)
	const {
		organizations,
		isFetching: orgsIsFetching,
		isLoading: orgsIsLoading,
		canLoadMore: orgsCanLoadMore,
		loadMore: orgsLoadMore,
	} = useCurrentUserOrgs({ roleType: UserOrganizationTypes.admin })

	const setCreateFeedsModal = useGlobalStateSetter(globalStates.createFeedsModal)

	const validateImageSize = useImageUploadError()

	const campusesOptions = useMemo(
		() => [
			{
				label: 'All campuses',
				value: undefined,
			},
			...campuses,
		],
		[campuses],
	)

	const audienceOptions = useMemo(
		() => (ownerOrganizationId ? organizationAudienceOptions : usersAudienceOptions),
		[ownerOrganizationId, usersAudienceOptions, organizationAudienceOptions],
	)

	const anchorRef = useRef<HTMLButtonElement | null>(null)

	const [isOpenTagSelectCard, setOpenTagSelectCard] = useState(false)
	const [isOpenCampusSelectCard, setOpenCampusSelectCard] = useState(false)
	const [isOpenUsersSelectCard, setOpenUsersSelectCard] = useState(false)
	const [isOpenScheduleCard, setOpenScheduleCard] = useState(!!isSchedule)
	const [openTooltip, setOpenTooltip] = useState(false)

	const [previewLink, setPreviewLink] = useState<any>(null)
	const [showLinkPreview, setShowLinkPreview] = useState(true)
	const [showAddPhotoInput, setShowAddPhotoInput] = useState(!!photoUrl)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [resetTextEditorValues, setResetTextEditorValues] = useState(0)

	const classes = useStyles({ url: photoUrl })

	const isOpenOtherCard = isOpenTagSelectCard || isOpenCampusSelectCard || isOpenUsersSelectCard || isOpenScheduleCard
	const canAddLinkPreview = !showAddPhotoInput && !photoUrl && !sharedEntity

	const shouldShowTooltip = !tag && openTooltip
	const disableSubmitButton = !tag || (!toText(message || '') && !photoUrl && !sharedEntity)

	const resetMessage = useCallback(() => {
		setResetTextEditorValues((prev) => prev + 1)
	}, [])

	const handleOpenTooltip = useCallback(() => {
		setOpenTooltip(true)
	}, [])
	const handleCloseTooltip = useCallback(() => {
		setOpenTooltip(false)
	}, [])

	const handleShowAddPhotoInput = useCallback(() => {
		setShowAddPhotoInput(true)
	}, [])
	const handleHideAddPhotoInput = useCallback(() => {
		setShowAddPhotoInput(false)
	}, [])

	const handleToggleTagSelect = useCallback(() => setOpenTagSelectCard((prev) => !prev), [])
	const handleToggleCampusSelect = useCallback(() => setOpenCampusSelectCard((prev) => !prev), [])
	const handleToggleUsersAudienceSelect = useCallback(() => setOpenUsersSelectCard((prev) => !prev), [])
	const handleToggleSchedule = useCallback(() => setOpenScheduleCard((prev) => !prev), [])

	const handleChangePostCreator = useCallback(
		({ organization }: any) => {
			setCreateFeedsModal((data) => {
				data.content.ownerOrganizationId = organization?.id
			})
		},
		[setCreateFeedsModal],
	)

	const handleChangeTag = useCallback(
		(value?: number | null) => {
			setCreateFeedsModal((data) => {
				data.content.tag = value
			})
		},
		[setCreateFeedsModal],
	)

	const handleChangeUsersAudience = useCallback(
		(value?: number | null, subValue?: number) => {
			setCreateFeedsModal((data) => {
				data.content.usersAudience = value
				data.content.audienceScope = subValue
			})
		},
		[setCreateFeedsModal],
	)

	const handleChangeCampus = useCallback(
		(value?: number | null) => {
			setCreateFeedsModal((data) => {
				data.content.campus = value
			})
		},
		[setCreateFeedsModal],
	)

	const handleChangeMessage: OnChangePluginProps['onChange'] = useCallback(
		({ data: htmlText, links, mentions }) => {
			setCreateFeedsModal((data) => {
				data.content.message = htmlText
				data.content.mentions = mentions
			})

			if (!canAddLinkPreview) return

			const linkToPreview = links?.[0] || ''
			const resetPreviewLink = linkToPreview !== previewLink
			if (resetPreviewLink) {
				setShowLinkPreview(true)
				setPreviewLink(linkToPreview)
			}

			if (showLinkPreview || resetPreviewLink) {
				setCreateFeedsModal((data) => {
					data.content.linkPreviewUrl = linkToPreview
				})
			}
		},
		[canAddLinkPreview, previewLink, setCreateFeedsModal, showLinkPreview],
	)

	const handleChangeActiveSince = useCallback(
		(value?: Date) => {
			setCreateFeedsModal((data) => {
				data.content.activeSince = value.toISOString()
			})
		},
		[setCreateFeedsModal],
	)

	const handleCancelPreviewLink = useCallback(() => {
		setShowLinkPreview(false)
		setCreateFeedsModal((data) => {
			data.content.linkPreviewUrl = ''
		})
	}, [setCreateFeedsModal])

	const handleChangePhotoHandler = useCallback(
		(photoUrl: string) => {
			setCreateFeedsModal((data) => {
				data.content.photoUrl = photoUrl
			})
		},
		[setCreateFeedsModal],
	)

	const handleClearImage = useCallback(() => {
		setCreateFeedsModal((data) => {
			data.content.photoUrl = ''
		})
	}, [setCreateFeedsModal])

	const onClose = useCallback(() => {
		setOpenTooltip(false)
		setShowLinkPreview(true)
		setPreviewLink('')
		setShowAddPhotoInput(false)
		setCreateFeedsModal((data) => {
			data.isOpen = false
		})
	}, [setCreateFeedsModal])

	const handleSubmit = useCallback(() => {
		if (isEdit) {
			updatePost()
		} else {
			createPost()
		}

		onClose()
	}, [isEdit, onClose, updatePost, createPost])

	useEffect(() => {
		if (isEdit && photoUrl) {
			handleShowAddPhotoInput()
		}
	}, [handleShowAddPhotoInput, isEdit, photoUrl])

	const tagLabel = tag ? tagsOptions.find((option) => option.value === tag)?.label : undefined
	const userAudienceLabel = usersAudience ? audienceOptions.find((option) => option.value === usersAudience)?.label : undefined
	const campusLabel = campus || campus === undefined ? campusesOptions.find((option) => option.value === campus)?.label : undefined

	const hideAddPhotoButton = !!sharedEntity

	const footerSelects = (
		<>
			{showCampuses && (
				<CardSelectButton
					defaultLabel="Choose campus"
					label={campusLabel}
					startIcon={<CampusIcon className={classes.icon} />}
					onClick={handleToggleCampusSelect}
				/>
			)}
			<CardSelectButton
				defaultLabel="All Users"
				label={userAudienceLabel}
				startIcon={<UserGroupIcon className={classes.icon} />}
				onClick={handleToggleUsersAudienceSelect}
			/>
		</>
	)

	useEffect(() => {
		if (!isOpen) return

		if (canAddLinkPreview) {
			setShowLinkPreview(true)
			setCreateFeedsModal((data) => {
				data.content.linkPreviewUrl = previewLink
			})
		} else {
			setShowLinkPreview(false)
			setCreateFeedsModal((data) => {
				data.content.linkPreviewUrl = ''
			})
		}
	}, [canAddLinkPreview, isOpen, previewLink, setCreateFeedsModal])

	useEffect(() => {
		if (isOpen) {
			resetMessage()
		}
	}, [isOpen, resetMessage])

	const topLevelTitle = isEdit ? 'Update Post' : activeSince ? 'Schedule Post' : 'Create Post'
	const postTitle = isEdit ? 'Update' : activeSince ? 'Schedule' : 'Post'

	const activeSinceDate = useMemo(() => {
		return activeSince ? new Date(activeSince) : null
	}, [activeSince])

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			{!isOpenOtherCard && (
				<CommonModalCard
					title={topLevelTitle}
					titleContent={<SchedulePostCardSubTitle labelPrefix="Will post on" date={activeSinceDate} onEditClick={handleToggleSchedule} />}
					onClose={onClose}
					classnames={{
						header: classes.cardHeader,
						card: classes.rootCard,
					}}
				>
					<div className={classes.rootContainer}>
						<div className={classes.blockContainer}>
							{currentUser && (
								<div className={classes.userInfoRow}>
									<SelectPostingAs
										loadMore={orgsLoadMore}
										canLoadMore={orgsCanLoadMore}
										isFetching={orgsIsFetching}
										isLoading={orgsIsLoading}
										user={currentUser}
										organizations={organizations}
										onChange={handleChangePostCreator}
										selectedOrgId={ownerOrganizationId}
									/>
								</div>
							)}
							<div>
								<CardSelectButton
									className={classes.tagSelect}
									variant="filled"
									defaultLabel="Select Tag"
									label={tagLabel}
									onClick={handleToggleTagSelect}
									required
								/>
							</div>
						</div>
						<div className={classes.contentContainer}>
							<Editor value={message} onChange={handleChangeMessage} placeholder="What do you want to share?" />
							<div className={classes.content}>
								<Iff
									if={showLinkPreview && linkPreviewUrl}
									render={() => {
										return <LinkPreview userId={currentUser.id} url={linkPreviewUrl} onClose={handleCancelPreviewLink} />
									}}
								/>

								<Iff
									if={showAddPhotoInput}
									render={() => {
										return (
											<ImageUploadField
												maxWidth={400}
												helperText={'Preferred pixels: 1080:1080'}
												aspect={{ w: 1 }}
												className={classes.uploadField}
												value={photoUrl}
												imageFormats={FILE_FORMATS.commonImages}
												onClose={handleHideAddPhotoInput}
												onClear={handleClearImage}
												onChange={handleChangePhotoHandler}
												validateImageSize={validateImageSize}
											/>
										)
									}}
								/>
								{sharedEntity && sharedEntity.sharedEntityId && sharedEntity.sharedEntityType ? <SharedEntity {...sharedEntity} /> : null}
							</div>
						</div>
						{!hideAddPhotoButton && <div className={classNames(classes.footer, classes.blockContainer)}>{footerSelects}</div>}
						<div
							className={
								hideAddPhotoButton
									? classNames(classes.footer, classes.blockContainer, classes.displayColumn)
									: classNames(classes.footer, classes.blockContainer)
							}
						>
							{hideAddPhotoButton ? (
								<div className={classes.footerSelects}>{footerSelects}</div>
							) : (
								<>
									<IconButtonWithLabel label="Add Photo" onClick={handleShowAddPhotoInput} disabled={showAddPhotoInput}>
										<CameraIcon className={classes.icon} />
									</IconButtonWithLabel>
								</>
							)}
							{anchorRef?.current && (
								<Popover
									className={classes.popover}
									open={shouldShowTooltip}
									anchorEl={anchorRef?.current}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									disableRestoreFocus
								>
									<Typography className={classes.tooltip}>Add a tag</Typography>
								</Popover>
							)}
							<div className={hideAddPhotoButton ? classes.modalActions : undefined}>
								{!activeSince && (
									<ColorButton
										className={classes.scheduleButton}
										onClick={handleToggleSchedule}
										color={ButtonColors.GREY}
										startIcon={<ScheduleIcon />}
									>
										Schedule
									</ColorButton>
								)}
								<ColorButton
									className={classes.postButton}
									onClick={tag ? handleSubmit : undefined}
									ref={anchorRef}
									onMouseEnter={handleOpenTooltip}
									onMouseLeave={handleCloseTooltip}
									disabled={disableSubmitButton}
									component={!tag ? 'div' : undefined}
								>
									{postTitle}
								</ColorButton>
							</div>
						</div>
					</div>
				</CommonModalCard>
			)}
			{isOpenTagSelectCard && (
				<CardSelectOptionsContainer
					classnames={{
						header: classes.cardHeader,
						card: classes.tagsRootCard,
					}}
					title="Select a Tag"
					subTitle="What kind of post is this?"
					options={tagsOptions}
					value={tag}
					onChange={handleChangeTag}
					handleBack={handleToggleTagSelect}
					variant="secondary"
				/>
			)}
			{isOpenCampusSelectCard && showCampuses && (
				<CardSelectOptionsContainer
					classnames={{
						header: classes.cardHeader,
						card: classes.campusRootCard,
					}}
					title="Choose campus"
					subTitle="Your post will be shared to people at this campus"
					options={campusesOptions}
					value={campus}
					onChange={handleChangeCampus}
					shouldAcceptChanges
					handleBack={handleToggleCampusSelect}
				/>
			)}
			{isOpenUsersSelectCard && (
				<CardSelectOptionsContainer
					classnames={{
						header: classes.cardHeader,
						card: classes.usersAudienceRootCard,
						option: classes.usersAudienceOption,
					}}
					disabled={disableAudienceChange}
					title="User Audience"
					subTitle="Who can see this post?"
					options={audienceOptions}
					value={usersAudience}
					subOptionValue={audienceScope}
					onChange={handleChangeUsersAudience}
					shouldAcceptChanges
					handleBack={handleToggleUsersAudienceSelect}
				/>
			)}
			{isOpenScheduleCard && <SchedulePostFormCard onClose={handleToggleSchedule} onChange={handleChangeActiveSince} value={activeSince} />}
		</Modal>
	)
}
