import { SCHOOL_ID } from '@/constants/configuration'
import { useCreateAcademicCollegesMutation } from '@/features/academicColleges/academicCollegesApi'
import SearchEntities from '@/features/adminConsole/components/SearchEntities'
import { useCreateMajorsMutation } from '@/features/majors/majorsApi'
import { useCreateOnboardingEntitiesMutation, useCreateSignupExtraEntitiesMutation } from '@/features/onboarding/api'
import DataSource from '@/features/onboarding/types/DataSource'
import EntityScope from '@/features/onboarding/types/EntityScope'
import EntityTypes, { externalEntities } from '@/features/shareEntity/types/EntityTypes'
import { UserData } from '@/interfaces/common'
import { makeStyles } from '@material-ui/core'
import { Divider, Theme, Typography } from '@mui/material'
import { useCallback } from 'react'
import { ContentPropsMapKey, ModalKind, ModalState } from './types'
import Form from '../Form/Form'

const useStyles = makeStyles<Theme, undefined>((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		boxSizing: 'border-box',
		height: '100%',
		flexShrink: 1,
		padding: `${theme.spacing(8)}px 0`,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	leftContent: {
		flex: '1 1 50%',
		marginRight: theme.spacing(2),
		paddingRight: theme.spacing(2),
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	rightContent: {
		flex: '1 1 40%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		paddingX: theme.spacing(2),
		[theme.breakpoints.down('lg')]: {
			flex: '0 1 20%',
		},
	},
	image: {
		height: '80%',
		width: '100',
		display: 'block',
		objectFit: 'contain',
		flexShrink: 1,
		[theme.breakpoints.down('lg')]: {
			height: '80%',
		},
	},
}))
// @TODO: Break file into smaller components
// @Todo: Move to a common location
export type Image = {
	src: string
	alt?: string
}

export interface LearnMoreProps {
	title: string
	description: string
	image?: Image
}

const TitleAndDescription = ({ title, description }: { title: string; description: string }) => {
	const classes = useStyles()
	return (
		<div className={classes.leftContent}>
			<Typography variant="h1" style={{ marginBottom: 25, fontSize: 18 }}>
				{title}
			</Typography>
			<Typography variant="body2">{description}</Typography>
		</div>
	)
}

const TwoPanelHero = (props: LearnMoreProps) => {
	const { title, description, image } = props
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<TitleAndDescription title={title} description={description} />
			{!!image?.src && (
				<>
					{/* @TODO: create a scheme for orienting this on lower breakpoints */}
					<Divider orientation="vertical" flexItem />
					<div className={classes.rightContent}>
						<img src={image.src} alt={image?.alt ?? 'Phone Demoing Application'} className={classes.image} />
					</div>
				</>
			)}
		</div>
	)
}

export type SearchEntitiesModalProps = {
	setModalState: React.Dispatch<React.SetStateAction<ModalState>>
	entityName: string
	entityType: EntityTypes
	entityScope?: EntityScope
	placeholder?: string
	users?: UserData[]
}

const SearchEntityModalContent = (props: SearchEntitiesModalProps) => {
	const { entityType, entityScope, entityName, setModalState } = props

	// @TODO: Let's hoist these mutations up to the hook level(?)
	const [createEntities] = useCreateOnboardingEntitiesMutation()
	const [createSignupExtras] = useCreateSignupExtraEntitiesMutation()
	const [createMajor] = useCreateMajorsMutation()
	const [createAcademicCollege] = useCreateAcademicCollegesMutation()
	const handleSubmit = useCallback(
		(args) => {
			const handleClose = () => setModalState({ isOpen: false, modalKind: ModalKind.ENTITY_SEARCH })
			const baseArgs = {
				schoolId: SCHOOL_ID,
				entities: args,
			}
			switch (entityType) {
				case EntityTypes.major:
					createMajor(baseArgs)
					break
				case EntityTypes.academicCollege:
					createAcademicCollege(baseArgs)
					break
				case EntityTypes.interest:
				case EntityTypes.opportunities:
				case EntityTypes.experience:
					createSignupExtras({
						...baseArgs,
						entityScope,
						entityType,
					})
					break
				default:
					createEntities({
						...baseArgs,
						entityType,
						entityScope,
						dataSource: externalEntities.includes(entityType) ? DataSource.ENGAGE : DataSource.INTERNAL, // FIX me for PSU
					})
			}
			handleClose()
		},
		[entityType, entityScope, createEntities, createSignupExtras, createMajor, createAcademicCollege, setModalState],
	)

	return (
		<>
			<h2 id="modal-title">Add to Signup Flow</h2>
			<SearchEntities
				entityType={entityType}
				entityScope={entityScope}
				entityName={entityName}
				handleClose={() => setModalState({ isOpen: false, modalKind: ModalKind.ENTITY_SEARCH })}
				onSubmit={handleSubmit}
			/>
		</>
	)
}

export const MODAL_COMPONENT_MAP = {
	[ModalKind.LEARN_MORE]: TwoPanelHero,
	[ModalKind.ENTITY_SEARCH]: SearchEntityModalContent,
	[ModalKind.FORM]: Form,
	// [ModalKind.SELECT_DATE]: SelectDate,
} as const

export const genModalContentPropMapKey = (modalKind: ModalKind): ContentPropsMapKey<typeof modalKind> => `${modalKind}Props`
