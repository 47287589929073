import { PATHS } from '@/constants'
import AppRoute from '@/interfaces/route'

import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import GroupsListPage from '../pages/Groups/GroupsListPage'
import GroupIndividualPage from '../pages/Groups/GroupIndividualPage'
import { Route } from './config'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()
// Bellow approach is incorrect way to handle routes (Davit)
const routes: AppRoute[] = [
	{
		component: GroupsListPage,
		name: 'analytics-groups',
		path: `${PATHS.ADMIN}/${Route.Groups}`,
		exact: true,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: GroupIndividualPage,
		name: 'analytics-groups-individual',
		path: `${PATHS.ADMIN}/${Route.Groups}/:id`,
		exact: true,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes
