import { GridConfigDto, QueryGridFiltersDto, QueryGridSortByDto } from '../types/dto/Grid'
import { generatedBaseApi as api } from './baseGeneratedApi'
export const addTagTypes = ['Analytics Organization'] as const
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			organizationsControllerFindMany: build.query<OrganizationsControllerFindManyApiResponse, OrganizationsControllerFindManyApiArg>({
				query: (queryArg) => ({
					url: `/analytics/organizations/grid/data`,
					params: { take: queryArg.take, skip: queryArg.skip, filters: queryArg.filters, sortBy: queryArg.sortBy },
				}),
				providesTags: ['Analytics Organization'],
			}),
			organizationsControllerGetGridMeta: build.query<
				OrganizationsControllerGetGridMetaApiResponse,
				OrganizationsControllerGetGridMetaApiArg
			>({
				query: () => ({ url: `/analytics/organizations/grid/meta` }),
				providesTags: ['Analytics Organization'],
			}),
		}),
		overrideExisting: false,
	})
export { injectedRtkApi as analyticsOrganizationsApi }
export type OrganizationsControllerFindManyApiResponse = /** status 200 Successfully received items result */ {
	totalItems?: number
	items?: Organization[]
}
export type OrganizationsControllerFindManyApiArg = {
	take?: number
	skip?: number
	filters?: QueryGridFiltersDto
	sortBy?: QueryGridSortByDto
}
export type OrganizationsControllerGetGridMetaApiResponse = /** status 200  */ GridConfigDto
export type OrganizationsControllerGetGridMetaApiArg = void
export type Organization = {
	id: string
	name: string
	campusId?: number | null
	contactEmail?: string | null
	contactName?: string | null
	createdAt: string
	updatedAt: string
	description: string
	displayName?: string | null
	externalId?: string | null
	linkedinUrl?: string | null
	organizationTypeId: number
	photoUrl?: string | null
	rolePrimaryContactAffairsUserId?: number | null
	rolePrimaryContactFinanceUserId?: number | null
	rolePrimaryContactPublicUserId?: number | null
	rolePrimaryLeaderUserId?: number | null
	sourceSystem?: string | null
	status?: string
	tag?: string
	twitterUrl?: string
	websiteUrl?: string
	youtubeUrl?: string
	facebookUrl?: string
	instagramUrl?: string
}

export const { useOrganizationsControllerFindManyQuery, useOrganizationsControllerGetGridMetaQuery } = injectedRtkApi
