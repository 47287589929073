import { getUserInfo } from '@/store/auth'
import { getFullName } from '@/utils/common'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import LazyLoadProfileImage from '../../LazyLoadProfileImage'

const useStyles = makeStyles<Theme, { usersToShow: number }>((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '1.5vh 0',
	},
	images: ({ usersToShow }) => ({
		display: 'flex',
		minWidth: 33 * usersToShow,
		marginRight: 10,
	}),
	profileImage: {
		width: 44,
		height: 44,
		border: `solid 1px ${theme.palette.common.white}`,
	},

	profileImageMask: ({ usersToShow }) => ({
		position: 'absolute',
		marginLeft: (usersToShow - 1) * 25,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
		backgroundColor: theme.palette.specialColors.blacktransparent60,
		borderRadius: '50%',
	}),
	smallText: {
		fontSize: 15,
	},
}))

export type IShortResponsesListProps = {
	currentUserHasResponse?: boolean
	maxUsersToShow?: number
	users: Array<any>
	response: string
}

const ShortResponsesList: React.FC<IShortResponsesListProps> = ({
	maxUsersToShow = 5,
	users,
	response,
	currentUserHasResponse = false,
}) => {
	const currentUser = useSelector(getUserInfo)

	const listToDisplay = []
	const filteredResult = useMemo(
		() => (currentUserHasResponse && users ? users.filter((u) => u.id !== currentUser.id) : users),
		[currentUserHasResponse, users, currentUser.id],
	)
	if (currentUserHasResponse) {
		listToDisplay.push({
			externalId: currentUser.externalId,
			displayName: 'You',
			photoUrl: currentUser.photoUrl,
		})
	}
	const maxCountFromTheList = currentUserHasResponse ? maxUsersToShow - 1 : maxUsersToShow
	let index = 0

	while (index < filteredResult.length && index < maxCountFromTheList && filteredResult.length > 0) {
		listToDisplay.push({
			externalId: users[index].externalId,
			displayName: getFullName(filteredResult[index].firstName, filteredResult[index].lastName),
			photoUrl: users[index].photoUrl,
		})
		index++
	}

	const classes = useStyles({ usersToShow: listToDisplay.length })

	const others = users.length - listToDisplay.length

	const showOthers = users.length - listToDisplay.length > 0 && listToDisplay.length === maxUsersToShow

	if (showOthers) {
		listToDisplay.push({
			displayName: others > 1 ? `${others} others` : `${others} other`,
		})
	}
	const verb = currentUserHasResponse || listToDisplay.length > 1 ? 'are' : 'is'
	const profilePics = []
	const displayParts = []
	if (listToDisplay.length > 1) {
		const { [listToDisplay.length - 1]: lastItem } = listToDisplay
		displayParts.push(
			listToDisplay
				.filter((__, index) => index < listToDisplay.length - 1)
				.map((u) => u.displayName)
				.join(', '),
			' and ',
			lastItem.displayName,
			` ${verb} ${response}`,
		)
	} else {
		displayParts.push(listToDisplay.map((u) => u.displayName).join(', '), ` ${verb} ${response}`)
	}
	profilePics.push(...listToDisplay)

	return (
		<div className={classes.root}>
			<div className={classes.images}>
				{profilePics
					.filter((user) => user.externalId)
					.map((user, index) => (
						<LazyLoadProfileImage
							key={user.externalId}
							inlineStyles={index > 0 ? { position: 'absolute', marginLeft: 25 * index } : undefined}
							className={classes.profileImage}
							externalId={user.externalId}
							photoUrl={user.photoUrl}
						/>
					))}
				{showOthers && <div className={classNames(classes.profileImageMask, classes.profileImage)}>+{others}</div>}
			</div>
			<Typography className={classes.smallText}>
				{displayParts.map((text, index) => {
					if (index % 2 === 0) {
						return <b>{text}</b>
					}
					return text
				})}
			</Typography>
		</div>
	)
}

export { ShortResponsesList }
