import { ContentPreloader } from '@/components/ContentPreloader'
import NetworkUserRow from '@/components/IndividualPage/NetworkUserRow'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import settings from '@/constants/http'
import { UserData } from '@/interfaces/common'
import { getFullName } from '@/utils/common'
import { makeStyles } from '@material-ui/core'
import { createSelector } from '@reduxjs/toolkit'
import React, { useCallback, useMemo, useState } from 'react'
import { useGetEventResponsesQuery } from '../api'
import DtoEventUsers from '../types/dto/DtoEventUsers'
import { EventResponseType } from '../types/EventResponseType'
import EmptyResponseListPlaceholder from './EmptyResponseListPlaceholder'

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',

		overflowY: 'auto',
		margin: '0 0 40px 0',
	},
}))

export type IEventResponseListProps = {
	eventId: string
	responseType: EventResponseType
	title?: string
}

const EventResponseList: React.FC<IEventResponseListProps> = ({ eventId, responseType, title }) => {
	const classes = useStyles()
	const [offset, setOffset] = useState(0)

	const selectUserData = useMemo(() => {
		const emptyArray = []
		return createSelector(
			(data: DtoEventUsers) => data,
			(data: DtoEventUsers): UserData[] => {
				return data && data.result
					? data.result?.map(({ userId, ...rest }) => ({
							...rest,
							fullName: getFullName(rest.firstName, rest.lastName),
							id: userId,
					  }))
					: emptyArray
			},
		)
	}, [])

	const { isLoading, users, data } = useGetEventResponsesQuery(
		{ id: eventId, response: responseType, offset },
		{
			refetchOnMountOrArgChange: true,
			selectFromResult: (result) => ({
				...result,
				users: selectUserData(result.data),
			}),
		},
	)
	const total = data ? data.total : 0
	const loadMore = useCallback(() => {
		setOffset((prev) => prev + settings.USERS_PER_PAGE)
	}, [])
	if (isLoading) {
		return <ContentPreloader />
	}
	return !isLoading && users.length > 0 ? (
		<InfiniteScrollComponent
			internalScroll
			classnames={{ content: classes.root }}
			height={400}
			canLoadMore={users.length < total}
			onLoadMore={loadMore}
			items={users}
			renderItem={(user) => <NetworkUserRow user={user} />}
			getKey={(user, i) => user.externalId}
		/>
	) : (
		<EmptyResponseListPlaceholder type={responseType} title={title} />
	)
}

export { EventResponseList }
