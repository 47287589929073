import { STYLES } from '@/constants'
import { getFromNow } from '@/utils/dateTime2'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { forwardRef } from 'react'

const useStyles = makeStyles((theme) => ({
	skeletonContentContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginBottom: 10,
	},
	rootContainer: ({ compactView }: any) => ({
		width: '100%',
		margin: '10px 0',
		display: 'flex',
		flexDirection: compactView ? 'column' : 'row',
		alignItems: 'center',
		justifyContent: 'center',
	}),
	contentText: ({ compactView }: any) => ({
		width: compactView ? 300 : STYLES.FILL_AVAILABLE_WIDTH,
	}),
	timeText: {
		color: theme.palette.text.secondary,
		display: 'contents',
		whiteSpace: 'nowrap',
	},
	buttonsContainer: ({ compactView }: any) => ({
		marginLeft: 47,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: compactView ? 'space-between' : 'flex-end',
	}),
	headingText: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
}))

interface NotificationSkeletonProps {
	content: string
	heading?: string
	createdAt: string
	compactView?: boolean
	iconComponent: React.ReactNode
	actionButtons: React.ReactNode
}

const NotificationSkeleton = forwardRef<HTMLDivElement, NotificationSkeletonProps>(
	({ content, createdAt, compactView = false, iconComponent, actionButtons, heading }, ref) => {
		const classes = useStyles({ compactView })

		return (
			<div ref={ref} className={classes.rootContainer}>
				<div className={classes.skeletonContentContainer}>
					{iconComponent}
					<div>
						{heading && <Typography className={classes.headingText}>{heading}</Typography>}
						<Typography className={classes.contentText}>
							{content}
							<Typography component="span" className={classes.timeText}>
								&nbsp;{getFromNow(createdAt)}
							</Typography>
						</Typography>
					</div>
				</div>
				<div className={classes.buttonsContainer}>{actionButtons}</div>
			</div>
		)
	},
)

export default NotificationSkeleton
