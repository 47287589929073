import { OneOrMore } from '@/types/common'
import { useMemo, useState } from 'react'
import { TAdminModalProps } from './AdminModal'
import { MODAL_COMPONENT_MAP, genModalContentPropMapKey } from './AdminModalContent'
import { ModalComponentContent, ModalKind, ModalState, TModalConfig } from './types'

/**
 * This hook is used to manage the state of the AdminModal component.
 * @param modalKinds – ModalKind[]: The kinds of modals that can be displayed.
 * @param  ModalKindProps - The props for each kind of modal.
 * @returns `{ isOpen: boolean, modalKind: ModalKind, setModalState: React.Dispatch, modalContent: JSX.Element }`
 *
 * If you want to create new modal content, you'll need to:
 * 1. Add a new ModalKind to the ModalKind enum in `DashboardTypes.ts`.
 * 2. Define your component and add it to the MODAL_COMPONENT_MAP in `AdminModalContent.tsx`.
 *
 */
function useAdminModal<T extends OneOrMore<ModalKind>>(config: TModalConfig<T>): TAdminModalProps<T[number]> {
	const [{ isOpen, modalKind }, setModalState] = useState<ModalState>({
		isOpen: false,
		modalKind: config.modalKinds[0],
	})

	const modalContent = useMemo(() => {
		const ModalContent = MODAL_COMPONENT_MAP[modalKind] as ModalComponentContent<ModalKind>
		const props = config[genModalContentPropMapKey(modalKind)]

		return <ModalContent {...props} setModalState={setModalState} />
	}, [config, modalKind])

	return { isOpen, modalKind, setModalState, modalContent }
}

export default useAdminModal
