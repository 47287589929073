import DataTable from '@/features/adminConsole/components/dataTable/DataTable'
import { useMemo } from 'react'
import { NavTabs } from '../../../components/NavTabs'
import { useUsersControllerGetGridDataQuery, useUsersControllerGetGridMetaQuery } from '../api/usersGeneratedApi'
import {
	ProfileItemDto,
	useProfileItemsControllerGetGridDataQuery,
	useProfileItemsControllerGetGridMetaQuery,
} from '../api/usersProfileItemsApi'
import { AnalyticsFrameWithRoleCheck } from '../components/AnalyticsFrame'
import { ADMIN_ROOT_PATH } from '../routes/config'
import { DashboardType } from '../types/dashboardTypes'
import { usePatchUserTypeMutation } from '../api/usersApi'

const profileItemsTabs: Array<{ label: string; type: ProfileItemDto['type'] }> = [
	{
		label: 'Reported  Research',
		type: 'research',
	},
	{
		label: 'Reported Internships',
		type: 'internship',
	},
	{
		label: 'Reported Involvements',
		type: 'involvement',
	},
	{
		label: 'Reported Study Abroad',
		type: 'studyAbroad',
	},
	// Removed for now
	// {
	// 	label: 'Reported Interests',
	// 	type: 'interest',
	// },
]

const UsersListPage = () => {
	const TABS = useMemo(
		() => [
			{
				label: 'Analytics',
				route: `${ADMIN_ROOT_PATH}/users`,
				exact: true,
				component: <AnalyticsFrameWithRoleCheck dashboardType={DashboardType.USERS} />,
			},
			{
				label: 'Details',
				route: `${ADMIN_ROOT_PATH}/users/table`,
				exact: false,
				component: (
					<DataTable
						metaQuery={useUsersControllerGetGridMetaQuery}
						mutationDefinition={usePatchUserTypeMutation}
						listQuery={useUsersControllerGetGridDataQuery}
					/>
				),
			},
			...profileItemsTabs.map((item) => ({
				label: item.label,
				route: `${ADMIN_ROOT_PATH}/users/${item.type}`,
				exact: true,
				component: (
					<DataTable
						metaQueryParams={{ type: item.type }}
						queryParams={{ type: item.type }}
						metaQuery={useProfileItemsControllerGetGridMetaQuery}
						listQuery={useProfileItemsControllerGetGridDataQuery}
					/>
				),
			})),
		],
		[],
	)

	return (
		<div style={{ flex: 1, flexDirection: 'column' }}>
			<NavTabs tabs={TABS} withHistory />
		</div>
	)
}

export default UsersListPage
