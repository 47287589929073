import { EnumType, OneOrMore, RequiresNonEmptyObject } from '@/types/common'
import _ from 'lodash'

/**
 * Required due to curious behavior of TS Enums;
 * Numeric enums get a "Reverse Mapping", whereby the values are compiled to keys.
 * When they have a string, string KV Pair, they don't exhibit this behavior.
 * @param enum: EnumType with at least one key
 * @returns [(str | number)]
 */
// * For more info: https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
//  * For an example: https://www.typescriptlang.org/play/?ssl=8&ssc=1&pln=9&pc=1#code/PTAEBUE8AcEsDsDmoDOBTALqArtAUGgB7QD2ATlhjGqAKLzYC2U0aAPLaERmvACYpUGMgmQAfUA0YAjNGQB8oALx4AkBIAUnbrwFCRSUAH5QAJTQBjcnzYphogDT7RigFyS0ANzkBKNZu1CHn5BKVkyYzNLa1t7JCcwuVAJOwNENw9vMj88PBBQADFseAsMWBJ4UD40ADM8K3g7UERMeiYANQBDABtsNEElUDZwLiDdQVTRZMkmcPkNXmx3NuZqYfkfZUUAbzUGps8evoHQAHlpACtLDAA6Q97+hYY-VVga0A0AQnvjm5QSRhoDQaTybJSKKisEjvTzKJSDADkiTICJ8mzImGwZEqP36agxGCxOKO-RuNVg3R4ZGBuLBEOo0NAuLhiORqLwAF9cvlwP0sBZOugUAQpPoVqBtqBQHgpVKCWRobAsIjzMJFVgAEIkEgAawRDhlsrgApQ-2UoARAAVYCazVrdfrDVLEN1YNVzQiAOKu932vUG2XNf6VRGe4OgP2OwMIxDBgC0AHdyDrpNq9R6wxVQAB1ZOph0Grn1CpNVJdbonc5XUp3EkoDSpFY5fYkbpoG7dEiIDQIgA68AAspBQABlOLIcXl46ufVlnooPwttsdrsaAAG-ctCos-XQfFA0mHNXgrjXoAcLQwKynj0bUjRuUWjBmjEYkAAckxBJLpYGbwHZRvAAmACpRvABmUDQBvAAWKCbwAVk5XJ9iwKQb0rS5rlrB56ykN9P0YBdckva86x7AALWB2WLRpW3bTtuz7Qdh2RaC6xnBx0LrRcS3olduw3eAtxIHdTTQfdD1AY9T3PUjuNwjR8I-L8HyAA

export function getEnumValues<T extends EnumType<string | number>>(_enum: RequiresNonEmptyObject<T>): OneOrMore<string | number> {
	const values = Object.values(_enum)
	return (values.every((v) => typeof v === 'string') ? values : values.filter((v) => typeof v === 'number')) as unknown as OneOrMore<
		string | number
	>
}

export function getEnumKeys<T extends EnumType<string | number>>(_enum: RequiresNonEmptyObject<T>): OneOrMore<string | number> {
	const keys = Object.keys(_enum)
	return keys.filter((v) => _.isNaN(Number(v))) as unknown as OneOrMore<string | number>
}

export const num = 1
