import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { ButtonColors } from '@/components/Buttons/types'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { PATHS } from '@/constants'
import { UserData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { createConnectionRequest } from '@/store/network'
import { formPrimaryTitle } from '@/utils/common'
import { Card as MaterialCard, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
	card: {
		borderRadius: 10,
		margin: 5,
		cursor: 'pointer',
		height: 320,
		width: 221,
	},
	cardHeader: {
		height: 60,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		padding: 0,
		background: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	cardContent: {
		height: 260,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '0.9375em',
	},
	cardContentTitle: {
		margin: '10px 0',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
	connectionNumber: {
		padding: '10px 0px',
		fontSize: '0.9375em',
	},
	cardContentSubTitle: {
		height: '2.8125rem',
		fontSize: '0.9375em',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
	cardContentTitleContainer: {
		marginTop: 7,
		fontSize: 16,
	},
	cardContentInfo: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
		fontSize: '0.7375em',
		color: theme.palette.text.secondary,
		textTransform: 'capitalize',
	},
	cardContentFooter: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '15px 0',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	imageButtonsContainer: {
		width: 125,
		height: 125,
		padding: 15,
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			width: '84vw',
		},
	},
	feedbackButton: {
		minWidth: 150,
	},
	profileImage: {
		width: 76,
		height: 76,
		minWidth: 76,
		minHeight: 76,
		marginTop: -30,
	},
	requestedLabel: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.light,
		borderRadius: 5,
		minWidth: 150,
		height: 38,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	blockedStatus: {
		color: theme.colors.red[500],
		backgroundColor: theme.colors.red[200],
	},
}))

export enum CardTypes {
	EVENTS = 'events',
	ORGANIZATIONS = 'organizations',
	VOLUNTEER = 'volunteer',
	RESEARCH = 'research',
}

interface NetworkUserCardProps {
	user: UserData
	status: string
}

const NetworkUserCard = ({ user, status }: NetworkUserCardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const { id, externalId, fullName, type, campus, primaryTitle, primaryTitleDepartment, numberOfConnections, photoUrl } = user

	const connectionsNumber = `Connections: ${numberOfConnections}`

	const handleCreateRequest = useCallback(
		(e: any) => {
			e.stopPropagation()
			dispatch(createConnectionRequest(id!))
		},
		[dispatch, id],
	)

	const handleClick = useCallback(
		(e: any) => {
			push(PATHS.APP.NETWORK_USER(`${id}`))
		},
		[id, push],
	)

	return (
		<MaterialCard className={classNames(classes.card)} onClick={handleClick}>
			<div className={classes.cardHeader}></div>
			<div className={classes.cardContent}>
				<LazyLoadProfileImage borderRadius={5} className={classes.profileImage} externalId={externalId} photoUrl={photoUrl} />

				<div className={classNames(classes.cardContentTitleContainer)}>
					<Typography className={classes.cardContentTitle}>{fullName}</Typography>

					<Typography className={classNames(classes.cardContentSubTitle)}>
						{formPrimaryTitle([primaryTitle, primaryTitleDepartment])}
					</Typography>
					<Typography className={classes.connectionNumber}>{connectionsNumber}</Typography>
				</div>
				<div className={classNames(classes.cardContentInfo)}>{`${type} ${campus ? ` | ${campus}` : ''}`}</div>
				<div className={classNames(classes.cardContentFooter)}>
					{['requested', 'connected', 'blocked'].includes(status) ? (
						<div className={classNames(classes.requestedLabel, status === 'blocked' ? classes.blockedStatus : '')}>
							{status === 'blocked' ? 'Blocked' : status === 'requested' ? 'Requested' : 'Connected'}
						</div>
					) : (
						<OutlinedButton className={classes.feedbackButton} color={ButtonColors.PRIMARY} onClick={handleCreateRequest}>
							Connect
						</OutlinedButton>
					)}
				</div>
			</div>
		</MaterialCard>
	)
}

export default NetworkUserCard
