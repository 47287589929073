import { InputAdornment, IconButton as MaterialIconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { CommonModalCard, FilledTextField, IconButton, Modal, OutlinedSelect } from '@/components'
import UserWithMembershipInfoRow from '@/components/IndividualPage/UserWithMembershipInfoRow'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { STYLES } from '@/constants'
import { getActiveCampuses } from '@/features/campus/slice'
import useOrganizationMembershipsList from '@/features/organizations/hooks/useOrganizationMembershipsList'
import { useAppDispatch } from '@/store'
import {
	clearSearchUsers,
	createMembershipsRequest,
	getCanLoadMoreSearchUserResults,
	getIsLoadingSearchUserResults,
	getSearchUserResults,
	getSelectedOrganization,
	getUserTypeOptions,
	searchUserRequest,
} from '@/store/organizations'
import { getOrganizationRole, orgTypes } from '@/utils/organizationRoles'
import { submitFeedback } from '@/utils/services'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	searchResultContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 500,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 15px 15px 15px',
	},
	saveButton: {
		margin: '4.5vh 0',
		padding: '1vh',
		fontWeight: 600,
		minWidth: 200,
	},
	label: {
		fontSize: 13,
		textAlign: 'left',
		margin: '5px 0',
	},
	positionInput: {
		margin: 0,
		width: '100%',
	},
	roleSelect: {
		height: 56,
	},
	modalCard: {
		width: '90vw',
		minWidth: 300,
		maxWidth: 940,
	},
	formItem: {
		width: '100%',
		margin: '1vh 0',
	},
	userRowContainer: {
		margin: '15px 0',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: 5,
	},
	userRow: {
		margin: 15,
	},
	userRowImage: {
		width: 40,
		height: 40,
	},
	inputIcon: ({ focused }: any) => ({
		color: focused ? theme.palette.text.primary : theme.palette.text.secondary,
	}),
	clearInputButton: {
		cursor: 'pointer',
	},
	header: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
		padding: 15,
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 0,
		margin: 0,
	},
	select: {
		minWidth: 243,
		width: 243,
		marginLeft: 15,
		height: 50,
	},
	input: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	icon: {
		color: theme.palette.primary.main,
	},
	addButton: {
		marginRight: 20,
	},
	acceptedButton: {
		width: 160,
		height: 40,
		marginRight: 25,
		backgroundColor: theme.colors.green[100],
		'&>span': {
			color: theme.colors.green[500],
			textTransform: 'capitalize',
			'&>span': {
				position: 'absolute',
				right: 25,
			},
		},
	},
	infiniteScroll: {
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
}))

interface FindNewOrganizationMembersModalProps {
	membershipTypeId: orgTypes | null
	handleCloseModal: (e: any) => void
}

const FindNewOrganizationMembersModal = ({ membershipTypeId, handleCloseModal = () => {} }: FindNewOrganizationMembersModalProps) => {
	const dispatch = useAppDispatch()

	const campuses = useSelector(getActiveCampuses)

	const canLoadMore = useSelector(getCanLoadMoreSearchUserResults)
	const isLoading = useSelector(getIsLoadingSearchUserResults)
	const usersWithMembershipType = useSelector(getSearchUserResults)
	const userTypeOptions = useSelector(getUserTypeOptions)

	const [focused, setFocused] = useState(false)
	const [resetScrollPosition, setResetScrollPosition] = useState(false)
	const organization = useSelector(getSelectedOrganization)

	const { refetch } = useOrganizationMembershipsList({ id: organization.id, membershipTypeIds: [membershipTypeId] })

	const [searchValue, setSearchValue] = useState('')
	const [campus, setCampus] = useState<any>(null)
	const [orgUserType, setOrgUserType] = useState<any>(null)

	const classes = useStyles({ focused })

	const campusesOptions = useMemo(
		() => [
			{
				label: 'All campuses',
				value: null,
			},
			...campuses,
		],
		[campuses],
	)

	const userTypeOptionsMap = useMemo(
		() => userTypeOptions.filter(({ value }) => Number(value) > Number(membershipTypeId) || value === null),
		[membershipTypeId, userTypeOptions],
	)

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[],
	)

	const handleSetFocus = useCallback(
		(val: boolean) => () => {
			setFocused(val)
		},
		[],
	)

	const handleClearInput = useCallback(() => {
		setSearchValue('')
		dispatch(clearSearchUsers())
	}, [dispatch])

	const handleChangeInput = useCallback(
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			if (value && !value.trim()) return handleClearInput()

			setSearchValue(value)
		},
		[handleClearInput],
	)

	const handleLoadMore = useCallback(() => {
		dispatch(
			searchUserRequest({
				fetchMore: true,
				query: searchValue,
				campusId: campus,
				organizationMembershipTypeId: orgUserType,
				maxMembershipTypeId: membershipTypeId!,
			}),
		)
	}, [campus, dispatch, membershipTypeId, orgUserType, searchValue])

	const handleSearch = useCallback(() => {
		setResetScrollPosition(true)
		dispatch(
			searchUserRequest({
				query: searchValue,
				campusId: campus,
				organizationMembershipTypeId: orgUserType,
				maxMembershipTypeId: membershipTypeId!,
			}),
		)
	}, [campus, dispatch, membershipTypeId, orgUserType, searchValue])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(
		debounce(() => handleSearch(), 300),
		[handleSearch],
	)

	const onClose = (e: any) => {
		handleCloseModal(e)
		refetch()
	}

	const handleCreateMembership = (id: number) => (e: any) => {
		dispatch(
			createMembershipsRequest({
				targetUserId: id,
				membershipTypeId: membershipTypeId!,
			}),
		)
	}

	useEffect(() => {
		delayedSearch()

		return delayedSearch.cancel
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campus, orgUserType, searchValue])

	useEffect(() => {
		if (!isLoading) setResetScrollPosition(false)
	}, [isLoading])

	useEffect(() => {
		handleClearInput()

		if (membershipTypeId) {
			setCampus(null)
			setOrgUserType(null)
			delayedSearch()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [membershipTypeId])

	const renderNewMembers = useCallback(
		(user) => (
			<UserWithMembershipInfoRow
				classnames={{
					userRow: classes.userRow,
				}}
				user={user}
				organizationMembershipTypeId={user.organizationMembershipTypeId}
				openProfileOnClick
				buttons={
					user.added ? (
						<IconButton className={classes.acceptedButton} endIcon={<Check />} disabled>
							Added
						</IconButton>
					) : (
						<MaterialIconButton className={classes.addButton} onClick={handleCreateMembership(user.id!)}>
							<AddIcon className={classes.icon} />
						</MaterialIconButton>
					)
				}
			/>
		),
		[classes, handleCreateMembership],
	)

	const showNoResultFoundPlaceholder = !isLoading && !usersWithMembershipType.length && !canLoadMore

	return (
		<Modal isOpen={!!membershipTypeId} onClose={onClose}>
			<CommonModalCard
				title={`Add ${getOrganizationRole(membershipTypeId!)}`}
				onClose={onClose}
				classnames={{
					card: classes.modalCard,
				}}
			>
				<div className={classes.header}>
					<FilledTextField
						className={classes.input}
						onChange={handleChangeInput}
						value={searchValue}
						placeholder={'Search users'}
						inputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon className={classes.inputIcon} fontSize="medium" />
								</InputAdornment>
							),
							endAdornment: searchValue ? (
								<InputAdornment className={classes.clearInputButton} position="end" onClick={handleClearInput}>
									<Close className={classes.inputIcon} fontSize="medium" />
								</InputAdornment>
							) : undefined,
						}}
						onFocus={handleSetFocus(true)}
						onBlur={handleSetFocus(false)}
					/>
					{userTypeOptionsMap.length > 1 && (
						<OutlinedSelect
							classnames={{
								input: classes.select,
							}}
							value={orgUserType}
							onChange={setOrgUserType}
							options={userTypeOptionsMap}
							placeholder="Select user type"
						/>
					)}
					<OutlinedSelect
						classnames={{
							input: classes.select,
						}}
						value={campus}
						onChange={setCampus}
						options={campusesOptions}
						placeholder="Select campus"
					/>
				</div>
				<div className={classes.searchResultContainer}>
					{showNoResultFoundPlaceholder ? (
						<NotFoundPlaceholder buttons={placeholderButtons} showNothingSeemsToMatchMessage={false} />
					) : (
						<InfiniteScrollComponent
							classnames={{
								root: classes.infiniteScroll,
								content: classes.cardContainer,
							}}
							onLoadMore={handleLoadMore}
							items={usersWithMembershipType}
							renderItem={renderNewMembers}
							getKey={(user) => `search-user-${user.id}`}
							canLoadMore={canLoadMore}
							height={500}
							resetScroll={resetScrollPosition}
						/>
					)}
				</div>
			</CommonModalCard>
		</Modal>
	)
}

export default FindNewOrganizationMembersModal
