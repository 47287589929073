import EntityResults from '@/anthology/types/EntityResults'
import { SetFieldType } from 'type-fest'

export type ScheduledNotificationDTO = {
	id: string
	heading: string
	content: string
	scheduledAt: string
	status: NotificationStatus
	audienceId: NotificationAudience
	createdAt: string
	updatedAt: string
}

export type GetScheduledNotificationsDTO = EntityResults<ScheduledNotificationDTO>

export type TransformedGetScheduledNotifications = SetFieldType<
	GetScheduledNotificationsDTO,
	'items',
	Array<ScheduledNotificationDTO & { isEditable: boolean }>
>

export type PostNotificationDTO = {
	heading: string
	content: string
	scheduledAt: string
	audienceId: NotificationAudience
}

export type PatchNotificationDTO = Pick<ScheduledNotificationDTO, 'id'> & SetFieldType<Partial<PostNotificationDTO>, 'audienceId', 'string'>

export enum NotificationStatus {
	PENDING = 'pending',
	SENT = 'sent',
	CANCELLED = 'cancelled',
}

export enum NotificationAudience {
	ALL = 1,
	STUDENT = 2,
	'FACULTY/STAFF' = 3,
}
