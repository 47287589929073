import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useState } from 'react'

import Select, { emptyClassNames } from './Select'
import { BaseSelectProps, SelectClasses, SelectStyleProps } from './types'

const useStyles = makeStyles<Theme, Omit<SelectStyleProps, 'hasValue'>>((theme) => ({
	selectOpenIcon: {
		color: theme.palette.text.primary,
		width: 18,
	},
	selectMenuListContainer: {
		maxHeight: 350,
	},
	inputRoot: ({ isOpen, error }) => {
		const borderColor = error ? theme.palette.error.main : theme.palette.divider
		const border = isOpen || error ? `solid 1px ${borderColor}` : 'none'

		return {
			'&.MuiOutlinedInput-root': {
				color: theme.palette.text.primary,
				backgroundColor: isOpen ? theme.palette.background.paper : theme.palette.background.default,
				boxShadow: 'none',

				'& fieldset': {
					border,
					borderRadius: 5,
					background: 'none',
				},
				'&:hover fieldset': {
					border,
					borderRadius: 5,
					background: 'none',
				},
				'&.Mui-focused fieldset': {
					border,
					borderRadius: 5,
					background: 'none',
				},
			},
		}
	},
	selectRoot: ({ isOpen, error }) => ({
		color: error ? theme.palette.error.main : theme.palette.text.primary,
		backgroundColor: isOpen ? theme.palette.background.paper : theme.palette.background.default,
		padding: '10px 20px 10px 5px',
		boxShadow: 'none',
		'&.MuiSelect-select:focus': {
			background: 'none',
		},
		...theme.typography.subtitle2,
	}),
	selectMenuPaper: {
		borderRadius: 0,
		boxShadow: '0px 0px 8px 4px rgba(0,0,0,0.13)',
		backgroundColor: theme.palette.background.paper,
	},
	selectMenuList: {
		width: 'calc(100% - 2px) !important',
		boxShadow: 'none',
		backgroundColor: theme.palette.background.paper,
		padding: '10px 0',
		borderRadius: 0,
	},
	menuItemIcon: {
		color: theme.palette.primary.main,
		paddingLeft: 5,
	},
	menuItem: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'&.Mui-selected': {
			background: 'none',
		},
		...theme.typography.subtitle2,
	},
	selectLabel: {
		width: 'auto',
	},
	tagIcon: {
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
}))

type SelectWithIconProps = BaseSelectProps<SelectClasses & { tag?: string; tagsContainer?: string }> & {
	startIcon: React.ReactNode
}

const SelectWithIcon = ({
	classnames = emptyClassNames,
	label,
	value,
	placeholder = '',
	onChange,
	options = [],
	multiple = false,
	disabled = false,
	error = false,
	startIcon,
}: SelectWithIconProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const classes = useStyles({ isOpen, error, disabled })

	const onToggleSelect = useCallback((open: boolean) => setIsOpen(open), [])

	return (
		<Select
			classnames={{
				input: classNames(classnames.input, classes.inputRoot),
				select: classNames(classnames.select, classes.selectRoot),
				selectMenuPaper: classNames(classnames.selectMenuPaper, classes.selectMenuPaper),
				selectMenuList: classNames(classnames.selectMenuList, classes.selectMenuList),
				selectMenuListContainer: classNames(classnames.selectMenuListContainer, classes.selectMenuListContainer),
				selectLabel: classNames(classnames.selectLabel, classes.selectLabel),
				menuItem: classNames(classnames.menuItem, classes.menuItem),
				menuItemIcon: classNames(classnames.menuItemIcon, classes.menuItemIcon),
				selectOpenIcon: classNames(classnames.selectOpenIcon, classes.selectOpenIcon),
			}}
			label={label}
			placeholder={placeholder}
			options={options}
			onToggleSelect={onToggleSelect}
			disabled={disabled}
			value={value}
			onChange={onChange}
			multiple={multiple}
			startIcon={startIcon}
		/>
	)
}

export default SelectWithIcon
