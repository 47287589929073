import ColorButton from '@/components/Buttons/ColorButton'
import MenuIconButton from '@/components/Buttons/IconButton'
import { ButtonColors } from '@/components/Buttons/types'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { isEngage } from '@/constants/configuration'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { UserData } from '@/interfaces/common'
import { getFullName } from '@/utils/common'
import { CircularProgress, Popper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const useStyles = makeStyles((theme) => ({
	paper: ({ anchorOffsetWidth }: any) => ({
		zIndex: 1300,
		minWidth: anchorOffsetWidth || 0,
		backgroundColor: theme.palette.background.paper,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	}),
	popoverButton: {
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	optionsContainer: {
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		maxHeight: 300,
		borderRadius: '7px 0 7px 7px',
	},
	optionImage: {
		width: 28,
		height: 28,
		minWidth: 28,
		minHeight: 28,
		marginRight: 10,
		borderRadius: '50%',
	},
	selectedOptionImage: {
		width: 42,
		height: 42,
		minWidth: 42,
		minHeight: 42,
		borderRadius: '50%',
	},
	optionButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		color: theme.palette.text.primary,
		fontSize: 15,
		borderRadius: 0,
	},
	menuHeader: {
		padding: 18,
		borderBottom: `solid 1px ${theme.palette.border.bold}`,
	},
	headerText: {
		fontSize: 15,
		color: theme.palette.text.primary,
	},
	rootContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	selectedOption: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.light,
	},
	orgPreloader: {
		height: '100px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	select: ({ open }: any) => ({
		marginLeft: 4,
		borderRadius: 7,
		color: open ? theme.palette.text.secondary : theme.palette.text.primary,
		textTransform: 'none',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
			color: open ? theme.palette.text.secondary : theme.palette.specialColors.blacktransparent80,
			boxShadow: 'none',
			'&>span': {
				opacity: 1,
			},
		},
		// @TODO: style bellow doesn't make sense need to be checked
		'&.Mui-disabled': {
			backgroundColor: theme.palette.specialColors.transparent,
			color: theme.palette.text.primary,
			'&>span': {
				opacity: 1,
			},
			'&:hover': {
				backgroundColor: theme.palette.specialColors.transparent,
				color: theme.palette.text.primary,
				'&>span': {
					opacity: 1,
				},
			},
		},
	}),
}))

interface SelectPostingAsProps {
	user: UserData
	canLoadMore?: boolean
	isLoading?: boolean
	isFetching?: boolean
	loadMore?: () => void
	organizations: any[]
	selectedOrgId?: string
	onChange: ({ user, organization }: { user?: UserData; organization?: any }) => void
}

const SelectPostingAs = ({
	user,
	organizations,
	onChange,
	selectedOrgId,
	canLoadMore,
	isFetching,
	isLoading,
	loadMore,
}: SelectPostingAsProps) => {
	const [open, setOpen] = useState(false)
	const [selectedOrg, setSelectedOrg] = useState<any | null>(organizations.find((org) => org.id === selectedOrgId))

	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const [ref, inView] = useInView({
		threshold: 0,
		delay: 1000,
	})

	const classes = useStyles({
		open,
		anchorOffsetWidth: anchorRef.current?.offsetWidth,
	})

	const handleToggle = (e: any) => {
		e.stopPropagation()
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	const handleSelectCurrentUser = (e: any) => {
		handleToggle(e)
		setSelectedOrg(null)
		onChange({ user })
	}

	const handleSelectOrganization = (id: string) => (e: any) => {
		const organization = organizations.find((org) => org.id === id)

		handleToggle(e)
		setSelectedOrg(organization)
		onChange({ organization })
	}

	useOutsideClickCallback(popperRef, handleClose)

	const fullName = getFullName(user.firstName, user.lastName)

	const disableSelect = !organizations.length && !isEngage()

	useEffect(() => {
		if (inView && !isLoading && !isFetching && canLoadMore && loadMore) {
			loadMore()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching, inView, isLoading, canLoadMore])

	return (
		<>
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end">
				<div className={classes.menuHeader}>
					<Typography className={classes.headerText}>Post as</Typography>
				</div>
				<div className={classes.optionsContainer}>
					<MenuIconButton
						className={classNames(classes.optionButton, !selectedOrg ? classes.selectedOption : '')}
						onClick={handleSelectCurrentUser}
					>
						<LazyLoadProfileImage className={classes.optionImage} externalId={user.externalId} photoUrl={user.photoUrl} />
						{fullName}
					</MenuIconButton>
					{isLoading && (
						<div className={classes.orgPreloader}>
							<CircularProgress size={32} />
						</div>
					)}
					{organizations.map((org) => (
						<MenuIconButton
							key={org.id}
							className={classNames(classes.optionButton, selectedOrg?.id === org.id ? classes.selectedOption : '')}
							onClick={handleSelectOrganization(org.id)}
						>
							<img className={classes.optionImage} src={org.photoUrl} alt={org.name} />
							{org.name}
						</MenuIconButton>
					))}
					{canLoadMore ? (
						<div ref={ref} className={classes.orgPreloader}>
							<CircularProgress size={32} />
						</div>
					) : null}
				</div>
			</Popper>
			<div className={classes.rootContainer}>
				{selectedOrg ? (
					<img className={classes.selectedOptionImage} src={selectedOrg.photoUrl} alt={selectedOrg.name} />
				) : (
					<LazyLoadProfileImage className={classes.selectedOptionImage} externalId={user.externalId} photoUrl={user.photoUrl} />
				)}
				<ColorButton ref={anchorRef} onClick={handleToggle} className={classes.select} color={ButtonColors.GREY} disabled={disableSelect}>
					Posting as {selectedOrg ? selectedOrg.name : fullName}
					{disableSelect && !!isEngage() ? null : open ? <ExpandLess /> : <ExpandMore />}
				</ColorButton>
			</div>
		</>
	)
}

export default SelectPostingAs
