import { GroupMemberType } from '@/api/http/groups/getGroupMembers'
import { generateDynamicLabel } from '@/features/adminConsole/utils/getTypeLabel'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { Divider } from '@mui/material'
import { DateTime } from 'luxon'
import { useState } from 'react'
import BackButton from '../BackButton'
import { GroupChatMemberList } from './GroupChatMemberList'

const useStyles = makeStyles<Theme, Partial<GroupProps>>((theme) => ({
	root: {
		margin: '0 2vw',
	},
	pageTitleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}`,
		textWrap: 'nowrap',
	},
	pageInfo: {
		flexDirection: 'column',
		justifyContent: 'center',
		width: 'min-content',
	},
	title: {
		textAlign: 'left',
		fontSize: 25,
		fontWeight: 700,
	},
	details: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(0.5),
		'& > span.detail': {
			fontWeight: '800',
			color: theme.palette.grey[800],
		},
		'& > span.detail + span': {
			margin: '0 0 0 8px',
			'&::before': {
				fontWeight: '300',
				content: '"|"',
				position: 'relative',
				display: 'inline-block',
				left: '-4px',
				height: '100%',
			},
		},
	},
	listContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
	},
	sectionHeader: {
		marginBottom: theme.spacing(2),
		marginLeft: '2vw',
	},
}))

export interface GroupProps {
	id: string
	name: string
	memberCount: number
	type: GroupMemberType
	createdAt: string
}

const GroupChatIndividualInfo: React.FC<GroupProps> = ({ name, memberCount, type, createdAt, id }) => {
	const classes = useStyles({})

	const parsedDate = DateTime.fromISO(createdAt, { zone: 'UTC' })
	const formattedDate = parsedDate.toFormat('MMMM dd, yyyy')

	const [selectedMemberType, setSelectedMemberType] = useState(null)

	return (
		<div className={classes.root}>
			<div className={classes.pageTitleContainer}>
				<div className={classes.pageInfo}>
					<Typography className={classes.title} variant="h1">
						{name}
					</Typography>
					<Typography variant="subtitle1" className={classes.details}>
						<span className="detail">{type}</span>
						<span className="detail">{generateDynamicLabel({ count: memberCount, subj: 'Member' })}</span>
						<span className="detail">Created {formattedDate}</span>
					</Typography>
				</div>
				<BackButton size={18} />
			</div>
			<Divider sx={{ margin: `16px 0` }} />
			<div className={classes.listContainer}>
				<Typography variant="h2" className={classes.sectionHeader}>
					Members
				</Typography>
				<GroupChatMemberList type={selectedMemberType} groupId={id} />
			</div>
		</div>
	)
}

export { GroupChatIndividualInfo }
