import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { PATHS, STYLES } from '@/constants'
import { campusSelectors } from '@/features/campus/slice'
import { UserData } from '@/interfaces/common'
import { useAppSelector } from '@/store'
import { getUserRole } from '@/utils/authHandlers'
import { getFullName } from '@/utils/common'
import { getFromNow } from '@/utils/dateTime2'
import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import startCase from 'lodash/startCase'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '15px 0',
	},
	descriptionText: {
		color: theme.palette.text.secondary,
	},
	infoContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 15,
	},
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
	userInfoRowContainer: ({ showRows }: any) => ({
		width: STYLES.FILL_AVAILABLE_WIDTH,
		borderBottom: showRows ? `solid 1px ${theme.palette.divider}` : 'none',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	}),
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	createdAt: {
		color: theme.palette.text.secondary,
		fontSize: 15,
	},
	titleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	clickable: {
		cursor: 'pointer',
	},
}))

interface MembershipInfoRowProps {
	classnames?: {
		root?: string
		userRow?: string
		profileImage?: string
		buttonsContainer?: string
		infoContainer?: string
		userName?: string
		positionText?: string
		titleContainer?: string
		additionalInfo?: string
	}
	user?: UserData
	membershipTitle?: string | null
	showRows?: boolean
	buttons?: React.ReactNode
	createdAt?: string
	onClick?: (e: any) => void
	openProfileOnClick?: boolean
	userEmail?: string
	additionalInfo?: string
}

const createSubTitle = ({
	appUserTypeId,
	campusName,
	position,
	type,
}: {
	position?: string
	campusName?: string
	appUserTypeId?: number
	type?: string
}) => {
	const userType = type || getUserRole(appUserTypeId)

	return position ? position : [startCase(userType), campusName].filter((part) => part).join(' | ')
}

const MembershipInfoRow = ({
	classnames = {
		root: '',
		userRow: '',
		profileImage: '',
		buttonsContainer: '',
		infoContainer: '',
		userName: '',
		positionText: '',
		titleContainer: '',
		additionalInfo: '',
	},
	user,
	membershipTitle,
	showRows = false,
	buttons = null,
	createdAt,
	onClick,
	openProfileOnClick = false,
	userEmail,
	additionalInfo,
}: MembershipInfoRowProps) => {
	const classes = useStyles({ showRows })
	const { push } = useHistory()
	const localUserCampus = useAppSelector((state) => campusSelectors.selectById(state, user?.campusId))
	const clickable = user && openProfileOnClick

	const campusName = localUserCampus?.name ?? user?.campus
	const fullName = getFullName(user?.firstName, user?.lastName)

	const subTitle = createSubTitle({ type: user?.type, campusName, appUserTypeId: user?.appUserTypeId, position: user?.position })

	const handleClickViewProfile = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		push(PATHS.APP.NETWORK_USER(`${user?.id}`))
	}

	return (
		<div className={classNames(classes.userInfoRowContainer, classnames.root)} onClick={onClick}>
			<div
				className={classNames(classes.root, clickable ? classes.clickable : '', classnames.userRow)}
				onClick={clickable ? handleClickViewProfile : undefined}
			>
				<LazyLoadProfileImage
					className={classNames(classes.profileImage, classnames.profileImage)}
					borderRadius={10}
					externalId={user?.externalId}
					photoUrl={user?.photoUrl}
				/>
				<div className={classNames(classes.infoContainer, classnames.infoContainer)}>
					<div className={classNames(classes.titleContainer, classnames.titleContainer)}>
						<Typography className={classnames.userName}>
							{user && fullName ? fullName : <Link href={`mailto:${userEmail}`}>{userEmail}</Link>}
						</Typography>
						{createdAt && <Typography className={classes.createdAt}>{getFromNow(createdAt)}</Typography>}
					</div>
					<Typography className={classNames(classes.descriptionText, classnames.positionText)}>{membershipTitle || subTitle}</Typography>
					{additionalInfo && (
						<Typography className={classNames(classes.descriptionText, classnames.additionalInfo)}>{additionalInfo}</Typography>
					)}
				</div>
			</div>
			{/* Prevent event propagation when click to the button inside a row */}
			<div className={classNames(classes.buttonsContainer, classnames.buttonsContainer)} onClick={(e: any) => e.stopPropagation()}>
				{buttons}
			</div>
		</div>
	)
}

export default MembershipInfoRow
