import ColorButton from '@/components/Buttons/ColorButton'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import { CampusPicker } from '@/components/filters/CampusPicker'
import { SearchBar } from '@/components/filters/SearchBar'
import { STYLES } from '@/constants'
import { selectShowCampuses } from '@/features/campus/slice'
import { useAppDispatch } from '@/store'
import {
	getSearchGroupsData,
	searchGroupsRequest,
	setSearchGroupsCampus,
	setSearchGroupsQuery,
	setShowCreateGroupModal,
} from '@/store/groups'
import { clearAddNewUserToMessagesListInput, setShowSelectUserInput } from '@/store/messages'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import GroupCard from './GroupCard'
import JoinGroupPopup from './JoinGroupPopup'

const createGridCss = (cols: number, gap: number) => {
	const percentage = 100 / cols
	const whiteSpaces = gap * (cols - 1)
	const colWidth = `calc(${percentage}% - ${whiteSpaces / cols}px)`

	return {
		gridTemplateColumns: `repeat(auto-fill, ${colWidth})`,
		gridGap: gap,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},

	listContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	searchPlaceholder: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: `calc(100vh - 6vh - 200px - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
	},
	cardContainer: {
		display: 'grid',
		margin: 0,
		...createGridCss(5, 10),
		[theme.breakpoints.down('lg')]: {
			...createGridCss(4, 10),
		},
		[theme.breakpoints.down('md')]: {
			...createGridCss(2, 10),
		},
		[theme.breakpoints.down('xs')]: {
			...createGridCss(1, 10),
		},
	},
	contentContainer: {
		padding: '2vh 2vw',
	},
	placeholderTitle: {
		fontWeight: 600,
	},
	placeholderText: {
		margin: '10px 0',
	},
	placeholderButton: {
		width: 200,
	},

	header: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
		margin: '1.5vh 7%',
	},
	rootCard: {
		minWidth: 530,
	},
	listHeader: {
		alignItems: 'center',
		background: theme.palette.background.paper,
		borderBottom: `1px solid ${theme.palette.border.bold}`,
		justifyContent: 'space-between',
		display: 'flex',
		padding: '2vh 2vw',
		top: `${STYLES.MAIN_HEADER_HEIGHT}vh`,
		position: 'sticky',
		zIndex: 3,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	headerLeft: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: `${theme.spacing(2)}px 0px`,
		},
	},
	headerRight: {
		display: 'flex',
		alignItems: 'center',
		width: '60%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			flexDirection: 'column',
		},
	},
	pickerContainer: {
		marginLeft: theme.spacing(4),
		width: STYLES.FILL_AVAILABLE_WIDTH,
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
			margin: `${theme.spacing(2)}px 0px`,
			width: '100%',
			flexDirection: 'column',
		},
	},
	pageTitle: {
		fontWeight: 700,
	},
}))

interface SearchGroupsListProps {
	listClassName?: string
	rootClassName?: string
}

const SearchGroupsList = ({ listClassName = '', rootClassName = '' }: SearchGroupsListProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { pathname } = useLocation()
	const showCampuses = useSelector(selectShowCampuses)
	const { q: searchValue, campus, canLoadMore, groups } = useSelector(getSearchGroupsData)

	const [isTouched, setIsTouched] = useState(false)

	const [groupIdToShowInfoPopup, setGroupIdToShowInfoPopup] = useState<string>('')

	const handleChangeInput = useCallback(
		(value: string) => {
			dispatch(setSearchGroupsQuery(value))
			setIsTouched(true)
		},
		[dispatch],
	)

	const handleLoadMore = useCallback(() => {
		dispatch(searchGroupsRequest({ fetchMore: true }))
	}, [dispatch])

	const handleSearch = useCallback(() => {
		dispatch(searchGroupsRequest({}))
	}, [dispatch])

	const handleChangeCampus = useCallback(
		(id?: number | null) => {
			setIsTouched(true)
			dispatch(setSearchGroupsCampus(id ?? null))
		},
		[dispatch],
	)

	const handleShowCreateGroupModal = useCallback(() => {
		dispatch(clearAddNewUserToMessagesListInput())
		dispatch(setShowCreateGroupModal(true))
		dispatch(setShowSelectUserInput(false))
	}, [dispatch])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(
		debounce((search: string) => handleSearch(), 300),
		[handleSearch],
	)

	const handleCloseGroupInfoPopup = () => setGroupIdToShowInfoPopup('')

	useEffect(() => {
		if (isTouched) delayedSearch(searchValue)

		return delayedSearch.cancel
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, campus, isTouched])

	useEffect(() => {
		dispatch(searchGroupsRequest({ fetchMore: false }))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		handleCloseGroupInfoPopup()
	}, [pathname])

	return (
		<>
			<JoinGroupPopup groupId={groupIdToShowInfoPopup} onClose={handleCloseGroupInfoPopup} />

			<div className={classNames(classes.root, rootClassName)}>
				<div className={classes.listHeader}>
					<div className={classes.headerLeft}>
						<Typography className={classes.pageTitle} variant="h2">
							Discover new group to join
						</Typography>
					</div>
					<div className={classes.headerRight}>
						<SearchBar onChange={handleChangeInput} placeholder={'Search Groups'} />
						{showCampuses && (
							<div className={classes.pickerContainer}>
								<CampusPicker selected={campus} onChange={handleChangeCampus} />
							</div>
						)}
					</div>
				</div>
				<div className={classNames(classes.listContainer, listClassName)}>
					{!!groups?.length ? (
						<InfiniteScrollComponent
							classnames={{
								content: classes.cardContainer,
								contentContainer: classes.contentContainer,
							}}
							onLoadMore={handleLoadMore}
							canLoadMore={canLoadMore}
							items={groups}
							renderItem={(group) => <GroupCard data={group} onClick={setGroupIdToShowInfoPopup} />}
						/>
					) : (
						<div className={classes.searchPlaceholder}>
							<Typography className={classes.placeholderTitle}>No results found.</Typography>
							<Typography className={classes.placeholderText}>Nothing seems to match your search.</Typography>
							<ColorButton className={classes.placeholderButton} onClick={handleShowCreateGroupModal}>
								Create Group
							</ColorButton>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default SearchGroupsList
