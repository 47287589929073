import Router from '@/components/Router'
import { CommonCard } from '../../components/CommonCard'
import { Layout } from '../../components/Layout'
import routes from '../../routes/groupRoutes'

const GroupChatsMainPage = () => {
	return (
		<Layout>
			<CommonCard
				title="Group Chats"
				subHeader="Welcome to the group chats dashboard! Find information about public and restricted chats (private chats not listed).
				Click on a group chat ID for more information about that group."
			>
				<Router routes={routes} />
			</CommonCard>
		</Layout>
	)
}

export default GroupChatsMainPage
