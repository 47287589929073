import { useProfilePhoto } from '@/hooks/useProfilePhoto'
import { isValidUrl } from '@/utils/common'
import { makeStyles } from '@material-ui/core'
import Person from '@material-ui/icons/Person'
import classNames from 'classnames'
import { CSSProperties } from 'react'
import { useInView } from 'react-intersection-observer'

const useStyles = makeStyles((theme) => ({
	root: ({ borderRadius }: any) => ({
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: `${borderRadius}%`,
		backgroundColor: theme.palette.type === 'light' ? theme.palette.neutral.light : theme.palette.background.default,
		[theme.breakpoints.down('xs')]: {
			width: '4.5vw',
			height: '4.5vw',
			minWidth: 55,
			minHeight: 55,
		},
	}),
	image: ({ borderRadius }: any) => ({
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: `${borderRadius}%`,
	}),
	personIcon: {
		width: '80%',
		height: '80%',
		color: theme.palette.text.secondary,
	},
	notificationDot: ({ notificationDot }: any) => ({
		width: notificationDot.width,
		height: notificationDot.height,
		backgroundColor: theme.palette.icons.notificationDot,
		border: `solid 1px ${theme.palette.common.white}`,
		borderRadius: '50%',
		position: 'absolute',
		top: notificationDot.top,
		right: notificationDot.right,
	}),
}))

// @TODO: #refactor
// - correct component logic
// - remove SWR,
// - create dumb component for profile image and reuse it
// - remove notificationDot and inlineStyles
// - pass as children whatever needs to be displayed on profile image

interface LazyLoadProfileImageProps {
	className?: string
	photoUrl?: string
	externalId?: string
	imageData?: string
	borderRadius?: number
	/**
	 * @TODO: notificationDot has nothing to tod with LazyLoadProfileImageProps, bad abstraction has been done
	 */
	notificationDot?: {
		width: number
		height: number
		top: number
		right: number
	}
	inlineStyles?: CSSProperties
}

export const LazyLoadProfileImage = ({
	className = '',
	externalId,
	borderRadius = 50,
	imageData, // Just for editing profile photos
	photoUrl,
	notificationDot = {
		width: 0,
		height: 0,
		top: 0,
		right: 0,
	},
	inlineStyles,
}: LazyLoadProfileImageProps) => {
	const classes = useStyles({ borderRadius, notificationDot })

	const [ref, inView] = useInView({
		threshold: 0,
		delay: 100,
		triggerOnce: true,
	})

	const { image } = useProfilePhoto({
		externalId,
		photoUrl,
		inView: inView && !imageData && !isValidUrl(photoUrl),
	})

	const showDot = !!notificationDot.width && !!notificationDot.height
	const hasImage = image || imageData || isValidUrl(photoUrl)
	const imageSrc = imageData || image || photoUrl

	return (
		<div style={inlineStyles} ref={ref} className={classNames(classes.root, className)}>
			{showDot && <div className={classes.notificationDot} />}
			{/* Can make this into ImageWithTextFallback (from mobile) in the future*/}
			{/* Can use loading from useProfilePhoto for skeleton in the future */}
			{hasImage ? <img className={classes.image} src={imageSrc} alt="profile" /> : <Person className={classes.personIcon} />}
		</div>
	)
}

export default LazyLoadProfileImage
