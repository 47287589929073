import { isEqual } from 'lodash'

import { Dictionary, createSelector } from '@reduxjs/toolkit'

import settings from '@/constants/http'

import { PostActivity, UserInfo } from '@/interfaces/user'

import util from '@/utils'

import MentionTypeaheadOption from '@/components/Editor/plugins/MentionPlugin/MentionTypeaheadOption'
import { UserResponseStatuses } from '@/interfaces/common'
import { OrgMembership, Organization } from '@/store/organizations/types'
import { TCampus } from '../campus/types/TCampus'
import { baseApi } from '../general/baseApi'
import DtoOrganization from './types/dto/DtoOrganization'
import DtoOrganizationEvents from './types/dto/DtoOrganizationEvents'
import DtoOrganizationMemberShips from './types/dto/DtoOrganizationMemberShips'
import DtoSearchOrganizations from './types/dto/DtoSearchOrganizations'
import MembershipQueryParams from './types/queryParams/MembershipQueryParams'
import SearchOrganizationsParams from './types/queryParams/SearchOrganizationsParams'
import EntityTypes from '../shareEntity/types/EntityTypes'

const API_BASE_URL = `organizations`
const ACTIVITY_PATH = `/activity`

const TAGS = {
	organization: 'Organization',
	membership: 'Membership',
	events: 'OrganizationEvent',
}

export const organizationApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [TAGS.organization, TAGS.membership],
	})
	.injectEndpoints({
		overrideExisting: true,
		endpoints: (builder) => ({
			getOrganizationInfo: builder.query<DtoOrganization, { id: Organization['id']; userId: UserInfo['id'] }>({
				query: ({ id, userId }) => ({ url: `${API_BASE_URL}/${userId}/org/${id}/info` }),
				providesTags: (result, error, { id, userId }) => [{ type: TAGS.organization, id: id }],
				keepUnusedDataFor: 600,
			}),

			// Event list
			getOrganizationEvents: builder.query<DtoOrganizationEvents, { offset; limit; orgId: string; filter?: 'past' | 'future' }>({
				query: ({ offset = 0, limit = settings.SEARCH_LIST_PER_PAGE, orgId, filter = 'future' }) => ({
					url: `/v2/${API_BASE_URL}/${orgId}/events`,
					params: {
						filter,
						offset,
						limit,
					},
				}),
				transformResponse: (response: DtoOrganizationEvents) => {
					return {
						total: response.total,
						results: response.results.map((orgEvent) => {
							return {
								...orgEvent,
								// @TODO: correct type
								// @ts-ignore
								responseStatus: orgEvent.response ? orgEvent.response.response : null,
							}
						}),
					}
				},
				serializeQueryArgs: ({ endpointName, queryArgs: { orgId } }) => {
					const serializedKey = `${endpointName}-${orgId}`
					return serializedKey
				},
				merge: (currentCache, { results, total }, { arg: { offset } }) => {
					console.log(offset, currentCache)
					if (offset > 0) {
						currentCache.results.push(...results)
						currentCache.total = total
					} else {
						currentCache.results = results
						currentCache.total = total
					}
				},
				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
				//providesTags: (result, error, { filter, orgId }) => [{ type: TAGS.events, id: orgId }],
			}),

			// Memberships
			getMemberships: builder.query<DtoOrganizationMemberShips, MembershipQueryParams>({
				query: ({ id, userId, membershipTypeIds, limit = settings.ORG_MEMBERS_PER_PAGE, offset }) => {
					// this is needed because of array params rtk converts with commas, but backend accepts membershipTypeId[]=1&membershipTypeId[]=2
					const queryArgs = util.rtkQueryUtils.generateQueryParams({
						limit,
						offset,
						membershipTypeIds,
					})

					return {
						url: `${API_BASE_URL}/memberships/${userId}/org/${id}?${queryArgs}`,
					}
				},
				serializeQueryArgs: ({ endpointName, queryArgs }) => {
					const serializedKey = `${endpointName}-${queryArgs.id}-${queryArgs.membershipTypeIds.join('-')}`
					return serializedKey
				},

				merge: (currentCache, { memberships, total }, { arg: { offset } }) => {
					if (offset > 0) {
						currentCache.memberships.push(...memberships)
						currentCache.total = total
					} else {
						currentCache.memberships = memberships
						currentCache.total = total
					}
				},

				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
				transformResponse: (result: DtoOrganizationMemberShips, meta, { id, membershipTypeIds }) => ({
					...result,
				}),
			}),

			addOrganizationMember: builder.mutation<
				{ membership: OrgMembership },
				{ userId: number; orgId: number; body: { membershipTypeId: number; targetUserId: number } }
			>({
				query: ({ orgId, userId, body }) => ({
					url: `${API_BASE_URL}/${userId}/org/${orgId}/memberships/new`,
					method: 'POST',
					body,
				}),
				invalidatesTags: (result, error, { body }) => [{ type: TAGS.membership, id: `LIST` }],
			}),

			// Activity list
			getOrganizationActivity: builder.query<
				PostActivity[] | { count: number },
				{ userId: number; orgId: string; page?: number; perPage?: number; light?: boolean; count?: boolean }
			>({
				query: ({ orgId, page = 1, perPage = settings.SEARCH_ACTIVITY_LIST_PER_PAGE, userId, count = false }) => ({
					url: `${ACTIVITY_PATH}/${userId}/organization`,
					params: {
						organizationId: orgId,
						perPage,
						page,
						count,
						light: true,
					},
				}),
			}),
			searchOrganizations: builder.query<DtoSearchOrganizations, SearchOrganizationsParams>({
				query: ({ userId, ...params }) => ({
					url: `${API_BASE_URL}/${userId}/search`,
					params,
				}),
			}),
		}),
	})

export const {
	useGetOrganizationInfoQuery,
	useGetMembershipsQuery,
	useGetOrganizationEventsQuery,
	useGetOrganizationActivityQuery,
	useSearchOrganizationsQuery,
} = organizationApi

// Selectors

export const selectMembershipsNormalizedUserData = () => {
	const emptyArray = []

	return createSelector(
		(data: DtoOrganizationMemberShips) => data,
		(result: DtoOrganizationMemberShips, campuses: Dictionary<TCampus>) => campuses,
		(data: DtoOrganizationMemberShips, campuses: Dictionary<TCampus>): OrgMembership[] => {
			return data && data.memberships
				? data.memberships.map((membership) => ({
						...membership,
						user: membership.user ? util.transformers.getNormalizeUser(membership.user, campuses) : null,
				  }))
				: emptyArray
		},
	)
}

export const selectOrganization = () =>
	createSelector(
		(data?: DtoOrganization) => data,
		(data?: DtoOrganization) => {
			if (data && data.organizations) {
				return {
					...data.organizations,
					followed: data.organizations.response && data.organizations.response.response === UserResponseStatuses.followed ? true : false,
				}
			} else {
				return null
			}
		},
	)

export const selectOrgsAsAutocompleteOption = () => {
	const emptyArray = []
	return createSelector(
		(campusMap: Dictionary<TCampus>) => campusMap,
		(campusMap: Dictionary<TCampus>, results?: DtoSearchOrganizations) => results,
		(campusMap, results): MentionTypeaheadOption[] => {
			return results && results.length > 0
				? results.map((org) => {
						const summary = campusMap?.[org.campusId]?.name || ''
						return new MentionTypeaheadOption({
							name: org.name,
							summary,
							picture: org.photoUrl,
							entityType: EntityTypes.organization,
							id: org.id,
						})
				  })
				: emptyArray
		},
	)
}
