import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { GroupMemberStatus, GroupMemberType } from '@/api/http/groups/getGroupMembers'
import { ChangeGroupMember } from '@/api/http/groups/patchGroupMember'
import { IconWrapper, ModalWithSubmittedPopup, NavigationTab, NavigationTabs, SuccessPopup, TextButton } from '@/components'

import { STYLES } from '@/constants'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { UserData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import {
	changeBlockedMemberRequest,
	getIsOpenAddNewMemberPopup,
	inviteUsersToGroupRequest,
	setAddNewMembersPopup,
	showMemberInfoRequest,
} from '@/store/groups'

import MenuIconButton from '@/components/Buttons/IconButton'

import { GroupMember } from '@/store/groups/types'
import { IconButton, Popper, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore, MoreHoriz, PersonAdd } from '@material-ui/icons'
import { ADMIN_ACTIONS, checkGroupPermission } from '@navengage/sen-shared-assets'
import { DtoGroupMember, GroupChatRoles, IGroupChat } from '@navengage/sen-types'
import EmptyMembersListPlaceholder from '../EmptyMembersListPlaceholder'
import GroupMemberRow from '../GroupMemberRow'
import AddGroupMembers from '../ManageCards/AddGroupMembers'
import GroupMembersList from '../ManageCards/GroupMembersList'
import SideBarTabHeader from './SideBarTabHeader'

const useStyles = makeStyles((theme) => ({
	groupInformation: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		marginBottom: 30,
	},
	tabs: {
		borderBottom: `solid 1px ${theme.palette.divider}`,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		maxHeight: 48,
	},
	tabsContainer: {
		alignItems: 'center',
		maxHeight: 48,
	},
	tab: {
		maxHeight: 48,
		marginTop: 0,
		...theme.typography.body2,
		fontWeight: 600,
		flexDirection: 'row-reverse',
		alignItems: 'center',
	},
	adminIcon: {
		marginBottom: '0px !important',
		marginLeft: 5,
	},
	dropdownButton: {
		padding: 5,
	},
	addMemberButton: {
		color: theme.palette.primary.main,
	},
	optionsContainer: {
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginTop: 5,
		zIndex: 1300,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	activeViewButton: {
		color: theme.palette.primary.main,
		'& > span': {
			justifyContent: 'space-between',
		},
	},
	addMembersHeader: {
		flexDirection: 'column',
	},
	addMembersSelect: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		marginLeft: 0,
		margin: '10px 0',
	},
	addMembersSearchContainer: {
		height: 'calc(100vh - 400px)',
	},
	moreButton: {
		color: theme.palette.text.primary,
		padding: 10,
	},
	unblockButton: {
		...theme.typography.body2,
		fontWeight: 600,
	},
	listTitle: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		fontWeight: 700,
		textAlign: 'left',
		padding: '0 20px',
	},
}))

export enum MembersTabs {
	ADMINS = 'Admins',
	MEMBERS = 'Members',
}

const tabIndex = {
	[MembersTabs.ADMINS]: 0,
	[MembersTabs.MEMBERS]: 1,
}

const tabs = Object.values(MembersTabs)

export enum TabView {
	ALL_MEMBERS = 'All members',
	BLOCKED_MEMBERS = 'Blocked members',
}

interface GroupMembersProps {
	data?: IGroupChat
	onTouch?: () => void
	onClose?: () => void
	onSubmitUnsavedChanges: () => void
}

const GroupMembers = ({ data, onClose, onTouch, onSubmitUnsavedChanges }: GroupMembersProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const appTheme = useTheme()
	const isOpenAddNewMember = useSelector(getIsOpenAddNewMemberPopup)

	const [activeTab, setActiveTab] = useState(tabIndex[MembersTabs.ADMINS])
	const [open, setOpen] = useState(false)
	const [view, setView] = useState(TabView.ALL_MEMBERS)
	const [isAddingNewMembers, setIsAddingNewMembers] = useState(isOpenAddNewMember)
	const [isOpenUnblockPopup, setIsOpenUnblockPopup] = useState(false)

	const [hasUnsavedMembers, setHasUnsavedMembers] = useState(false)
	const [isOpenUnsavedChangesPopup, setIsOpenUnsavedChangesPopup] = useState(false)

	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const canAddRemoveMembers = checkGroupPermission(ADMIN_ACTIONS.ADD_REMOVE_MEMBERS, data, data?.userMemberType)
	const canAddRemoveAdmins = checkGroupPermission(ADMIN_ACTIONS.ADD_REMOVE_ADMINS, data, data?.userMemberType)

	const handleCheckUnsavedChanges = (close: () => void) => () => {
		if (hasUnsavedMembers) {
			setIsOpenUnsavedChangesPopup(true)
		} else {
			setHasUnsavedMembers(false)
			close()
		}
	}

	const handleToggle = (e: any) => {
		e.stopPropagation()
		setOpen((prevOpen) => !prevOpen)
	}

	const handleToggleIsOpenUnblockPopup = () => setIsOpenUnblockPopup((prevOpen) => !prevOpen)

	const handleAddUnsavedMembers = () => setHasUnsavedMembers(true)
	const handleOpenAddingMembers = () => setIsAddingNewMembers(true)
	const handleCloseAddingMembers = () => {
		dispatch(setAddNewMembersPopup(false))
		setIsAddingNewMembers(false)
	}

	const handleCancelUnsavedChangesAndClose = () => {
		onSubmitUnsavedChanges()
		setHasUnsavedMembers(false)
		setIsOpenUnsavedChangesPopup(false)
		handleCloseAddingMembers()
	}

	const handleCloseUnsavedChangesPopup = () => {
		setIsOpenUnsavedChangesPopup(false)
	}

	const handleTouchAddNewMembers = () => {
		if (onTouch) onTouch()
		handleAddUnsavedMembers()
	}

	const handleAddUsers = (users: UserData[]) => {
		dispatch(inviteUsersToGroupRequest(users))
		setActiveTab(tabIndex[MembersTabs.ADMINS])
		setView(TabView.ALL_MEMBERS)
		handleCancelUnsavedChangesAndClose()
	}

	const handleShowMemberInfo = useCallback((m: GroupMember) => () => dispatch(showMemberInfoRequest(m)), [dispatch])

	const handleUnBlockUser = (memberId: string) => () => {
		handleToggleIsOpenUnblockPopup()
		dispatch(
			changeBlockedMemberRequest({
				memberId,
				action: ChangeGroupMember.UNBLOCK,
			}),
		)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	const handleCloseAfterItemClick = (callback: (e: any) => void) => (e: any) => {
		handleToggle(e)
		callback(e)
	}

	const renderMember = useCallback(
		(member: DtoGroupMember) => {
			const isOwner = member.type === GroupChatRoles.Owner
			const canEditMembers = member.type === GroupChatRoles.Member && canAddRemoveMembers
			const canEditAdmins = member.type === GroupChatRoles.Admin && canAddRemoveAdmins

			return (
				<GroupMemberRow
					member={member}
					buttons={
						!isOwner && (canEditAdmins || canEditMembers) ? (
							<IconButton className={classes.moreButton} onClick={handleShowMemberInfo(member)}>
								<MoreHoriz />
							</IconButton>
						) : null
					}
				/>
			)
		},
		[canAddRemoveAdmins, canAddRemoveMembers, classes.moreButton, handleShowMemberInfo],
	)

	const tabComponentsByIndex = useMemo(
		() => ({
			[tabIndex[MembersTabs.ADMINS]]: (
				<GroupMembersList type={GroupMemberType.ADMIN} status={GroupMemberStatus.ACTIVE} renderItem={renderMember} />
			),
			[tabIndex[MembersTabs.MEMBERS]]: (
				<GroupMembersList
					type={GroupMemberType.MEMBER}
					status={GroupMemberStatus.ACTIVE}
					renderItem={renderMember}
					emptyPlaceholder={
						canAddRemoveMembers && (data?.memberCount || 0) < 2 ? (
							<EmptyMembersListPlaceholder onClickAddMembers={handleOpenAddingMembers} />
						) : undefined
					}
				/>
			),
		}),
		[canAddRemoveMembers, data?.memberCount, renderMember],
	)

	const activeTabComponent = useMemo(() => tabComponentsByIndex[activeTab], [activeTab, tabComponentsByIndex])

	const handleChangeTab = (value: number) => {
		setActiveTab(value)
	}

	const popperButtons = useMemo(
		() =>
			Object.values(TabView).map((v) => ({
				label: v,
				className: view === v ? classes.activeViewButton : '',
				icon: view === v ? <IconWrapper iconKey="checkCircle" weight="fill" /> : null,
				onClick: () => setView(v),
			})),
		[classes.activeViewButton, view],
	)

	useOutsideClickCallback(popperRef, handleClose)

	const icon = open ? <ExpandLess fontSize="medium" /> : <ExpandMore fontSize="medium" />

	const renderUnblockList = useCallback(
		(member) => (
			<GroupMemberRow
				member={member}
				showRole={false}
				buttons={
					canAddRemoveMembers ? (
						<TextButton className={classes.unblockButton} onClick={handleUnBlockUser(member.id)}>
							Unblock
						</TextButton>
					) : null
				}
			/>
		),
		[classes, canAddRemoveMembers, handleUnBlockUser],
	)

	return (
		<div className={classes.groupInformation}>
			<ModalWithSubmittedPopup
				isOpen={isOpenUnsavedChangesPopup}
				onSubmit={handleCancelUnsavedChangesAndClose}
				onClose={handleCloseUnsavedChangesPopup}
				title="Want to cancel?"
				content={['Your work will not be saved.']}
				submitButtonColor={appTheme.colors.red[500]}
				submitButtonLabel="Yes, cancel"
				cancelButtonLabel="No, keep editing"
				showSubmittedModal={false}
			/>
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end">
				<div className={classes.optionsContainer}>
					{popperButtons.map((btn) => (
						<MenuIconButton
							key={btn.label}
							className={classNames(classes.popoverButton, btn.className)}
							onClick={handleCloseAfterItemClick(btn.onClick)}
							endIcon={btn.icon}
						>
							{btn.label}
						</MenuIconButton>
					))}
				</div>
			</Popper>
			{!isAddingNewMembers ? (
				<>
					<SideBarTabHeader
						title={view === TabView.ALL_MEMBERS ? 'Group Members' : 'Blocked Members'}
						onCancel={onClose}
						middleElement={
							canAddRemoveMembers ? (
								<IconButton className={classes.dropdownButton} ref={anchorRef} onClick={handleToggle}>
									{icon}
								</IconButton>
							) : null
						}
						rightElement={
							canAddRemoveMembers ? (
								<IconButton className={classes.addMemberButton} onClick={handleOpenAddingMembers}>
									<PersonAdd />
								</IconButton>
							) : null
						}
					/>
					{view === TabView.ALL_MEMBERS ? (
						<>
							<NavigationTabs
								activeTabIndex={activeTab}
								className={classes.tabs}
								containerClassName={classes.tabsContainer}
								tabsRenderer={(props) =>
									tabs.map((tab, index) => (
										<NavigationTab
											className={classes.tab}
											key={`${tab}-${index}`}
											index={index}
											label={tab}
											icon={tab === MembersTabs.ADMINS ? <IconWrapper className={classes.adminIcon} iconKey="crown" /> : undefined}
											onClick={handleChangeTab}
											{...props}
										/>
									))
								}
							/>
							{activeTabComponent}
						</>
					) : (
						<>
							<SuccessPopup
								isOpen={isOpenUnblockPopup}
								onClose={handleToggleIsOpenUnblockPopup}
								submittedMessage={['This user has been unblocked from ', 'the group. They will need to join ', 'or be added to enter.']}
							/>
							<Typography className={classes.listTitle}>Blocked Members</Typography>
							<GroupMembersList
								status={GroupMemberStatus.BLOCKED}
								emptyPlaceholder={<Typography>There are no blocked users yet</Typography>}
								renderItem={renderUnblockList}
							/>
						</>
					)}
				</>
			) : (
				<>
					<SideBarTabHeader title="Add Members" onCancel={handleCheckUnsavedChanges(handleCloseAddingMembers)} />
					<AddGroupMembers
						groupId={data?.id}
						classnames={{
							header: classes.addMembersHeader,
							select: classes.addMembersSelect,
							searchResultContainer: classes.addMembersSearchContainer,
						}}
						onSubmit={handleAddUsers}
						onTouch={handleTouchAddNewMembers}
						submitLabel="Add"
					/>
				</>
			)}
		</div>
	)
}

export default GroupMembers
