import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { ButtonColors } from '@/components/Buttons/types'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { PATHS, STYLES } from '@/constants'
import { UserData } from '@/interfaces/common'
import { UserPrimaryTitle } from '../UserProfile/UserPrimaryTitle'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 1,
		minWidth: STYLES.MESSAGES_BAR.XL,
		borderLeft: `solid 1.5px ${theme.palette.border.bold}`,
		paddingTop: 10,
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('lg')]: {
			minWidth: STYLES.MESSAGES_BAR.LG,
		},
		[theme.breakpoints.down('md')]: {
			minWidth: STYLES.MESSAGES_BAR.MD,
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: STYLES.MESSAGES_BAR.SM,
		},
	},
	container: {
		position: 'fixed',
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		minWidth: STYLES.MESSAGES_BAR.XL,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'auto',
		[theme.breakpoints.down('lg')]: {
			minWidth: STYLES.MESSAGES_BAR.LG,
		},
		[theme.breakpoints.down('md')]: {
			minWidth: STYLES.MESSAGES_BAR.MD,
		},
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			width: '100vw',
			minWidth: STYLES.MESSAGES_BAR.SM,
		},
	},
	profileImage: {
		width: 96,
		height: 96,
		minWidth: 96,
		minHeight: 96,
		marginBottom: 15,
	},
	userName: {
		color: theme.palette.text.primary,
	},
	greyText: {
		color: theme.palette.text.secondary,
	},
	text: {
		textTransform: 'capitalize',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
		margin: '5px 0',
	},
	infoContainer: {
		margin: 30,
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
	},
	button: {
		color: theme.palette.text.secondary,
		marginTop: 25,
		padding: '7px 30px',
	},
}))

interface UserInfoBarProps {
	user: UserData
}

const UserInfoBar = ({ user }: UserInfoBarProps) => {
	const classes = useStyles()
	const { push } = useHistory()

	const handleClickViewProfile = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		push(PATHS.APP.NETWORK_USER(`${id}`))
	}

	const { id, externalId, fullName, type, campus, primaryTitle, primaryTitleDepartment, preferredPronouns, photoUrl } = user

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<div className={classes.infoContainer}>
					<LazyLoadProfileImage className={classes.profileImage} externalId={externalId} photoUrl={photoUrl} />
					<Typography variant="subtitle1" className={classNames(classes.userName, classes.text)}>
						{fullName}
					</Typography>
					<Typography variant="subtitle1" className={classNames(classes.text, classes.greyText)}>
						{`${type} ${campus ? ` | ${campus}` : ''}`}
					</Typography>
					{preferredPronouns && (
						<Typography variant="subtitle1" className={classNames(classes.text, classes.greyText)}>
							{preferredPronouns}
						</Typography>
					)}

					<UserPrimaryTitle
						variant="subtitle1"
						className={classes.text}
						primaryTitle={primaryTitle}
						primaryTitleDepartment={primaryTitleDepartment}
					/>
					<OutlinedButton className={classes.button} color={ButtonColors.GREY} onClick={handleClickViewProfile}>
						View Profile
					</OutlinedButton>
				</div>
			</div>
		</div>
	)
}

export default UserInfoBar
