import { NavigationButton } from '@/components/Buttons'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { PATHS, STYLES } from '@/constants'
import useUrlIncludesPaths from '@/hooks/useIncludesUrl'
import { useMainNavigation } from '@/hooks/useMainNavigation'
import { IFeature } from '@/lib/splitio/'
import { useAppDispatch } from '@/store'
import { getSideBarOpened, setSideBar } from '@/store/app'
import { getUserInfo } from '@/store/auth'
import { getFullName } from '@/utils/common'
import { Paper } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ArrowLeft, ArrowRight } from '@material-ui/icons'
import { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const hideSidebar = [
	PATHS.APP.EVENTS_CREATE,
	PATHS.APP.EVENTS_EDIT(),
	PATHS.APP.HOME,
	PATHS.APP.POST_INFO(),
	PATHS.APP.NOTIFICATIONS('all'),
	PATHS.APP.NOTIFICATIONS('new'),
	PATHS.APP.NOTIFICATIONS('past'),
	PATHS.APP.PATH_BUILDER,
	PATHS.APP.PATH_BUILDER_GRANT,
	PATHS.APP.PATH_BUILDER_LAST_STEP,
	PATHS.APP.PATH_BUILDER_QUESTIONS,
	PATHS.APP.PATH_BUILDER_SUMMARY,
]

const getWidth = (open: boolean) => {
	const width = open ? STYLES.SIDEBAR.OPENED : STYLES.SIDEBAR.COLLAPSED

	return width
}

const useStyles = makeStyles((theme) => ({
	root: ({ open }: any) => ({
		borderRadius: 0,
		minWidth: getWidth(open),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.palette.type === 'light' ? '0px 7px 15px -1px rgba(181,181,181,0.2)' : theme.shadows[8],
		transition: '0.5s',
		zIndex: 1,
	}),
	container: ({ open }: any) => ({
		margin: 0,
		padding: '20px 0',
		position: 'fixed',
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		minWidth: getWidth(open),
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'auto',
		transition: '0.5s',
		backgroundColor: 'none',
	}),

	profileIcon: {
		height: 32,
		width: 32,
		minHeight: 32,
		minWidth: 32,
	},
	toggle: ({ open }: any) => ({
		position: 'fixed',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		top: `calc(${STYLES.MAIN_HEADER_HEIGHT}vh + 20px)`,
		left: getWidth(open) - 10,
		height: 23,
		width: 23,
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		color: theme.palette.text.secondary,
		borderRadius: 5,
		transition: '0.5s',
		cursor: 'pointer',
		zIndex: 10,
	}),
	navigationButtonRoot: {
		padding: 0,
		margin: '8px 12px',
		'&  img, svg': {
			height: 30,
			width: 30,
			color: theme.palette.primary.main,
		},
	},
	bottomNavigationRoot: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 'auto',
		padding: '20px 0',
	},

	exitNavigation: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		padding: 0,
		margin: '8px 12px',
		'&  img, svg': {
			height: 30,
			width: 30,
			color: theme.palette.primary.main,
		},
	},
	rotatedIcon: {
		transform: 'rotate(180deg)',
	},
}))

interface DynamicSidebarProps {
	navigation: IFeature[]
	bottomNavigation?: IFeature[]
}

export const DynamicSidebar: FC<DynamicSidebarProps> = ({ navigation, bottomNavigation }) => {
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const appTheme = useTheme()
	const matches = useMediaQuery(appTheme.breakpoints.down('sm'))

	const hide = useUrlIncludesPaths(hideSidebar)
	const isOpen = useSelector(getSideBarOpened)

	const open = isOpen

	const classes = useStyles({ open })

	const handleToggleSideBar = useCallback(() => dispatch(setSideBar(!isOpen)), [dispatch, isOpen])
	const handleClickNavigationButton = useCallback(
		(newPath: string) => {
			dispatch(setSideBar(false))
			push(newPath)
		},
		[dispatch, push],
	)

	return hide || matches ? null : (
		<Paper elevation={8} className={classes.root}>
			<div className={classes.toggle} onClick={handleToggleSideBar}>
				{open ? <ArrowLeft /> : <ArrowRight />}
			</div>
			<div className={classes.container}>
				{navigation.map((nav, i) => (
					<NavigationButton
						classnames={{
							root: classes.navigationButtonRoot,
						}}
						key={'nav-item-' + i}
						hideLabel={!open}
						onClick={handleClickNavigationButton}
						navigation={nav}
					/>
				))}
				{bottomNavigation && (
					<div className={classes.bottomNavigationRoot}>
						{bottomNavigation.map((nav, i) => (
							<NavigationButton
								classnames={{
									root: classes.navigationButtonRoot,
								}}
								key={'nav-item-bottom' + i}
								hideLabel={!open}
								onClick={handleClickNavigationButton}
								navigation={nav}
							/>
						))}
					</div>
				)}
			</div>
		</Paper>
	)
}

const SideBar = () => {
	const isOpen = useSelector(getSideBarOpened)

	const open = isOpen

	const classes = useStyles({ open })
	const { externalId, firstName, lastName, photoUrl } = useSelector(getUserInfo)

	const userName = getFullName(firstName, lastName)

	const mainNavigation = useMainNavigation()

	const navigation = useMemo(
		() => [
			{
				label: userName,
				icon: <LazyLoadProfileImage className={classes.profileIcon} externalId={externalId} photoUrl={photoUrl} />,
				path: PATHS.APP.PROFILE,
			} as IFeature,
			...mainNavigation,
		],
		[classes.profileIcon, externalId, mainNavigation, photoUrl, userName],
	)

	return <DynamicSidebar navigation={navigation} />
}

export default SideBar
