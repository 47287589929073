import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import { DtoGroupMember } from '@navengage/sen-types'

// These enums are not database values, they are only used with getGroupMembers
export enum GroupMemberType {
	ADMIN = 'Admin',
	MEMBER = 'Member',
	OWNER = 'Owner',
}

export enum GroupMemberStatus {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
}

interface SearchParams {
	id: string
	status: `${GroupMemberStatus}`
	type?: `${GroupMemberType}`
	page: number
	schoolId?: number
}

const getGroupMembers = async ({ id, status, type, page, schoolId }: SearchParams) => {
	const url = `${schoolId}/groups/${id}/members`

	const params = {
		type,
		page,
		status,
		limit: settings.SEARCH_LIST_PER_PAGE,
	}

	return http.get<DtoGroupMember[]>(url, { params })
}

export default getGroupMembers
