import { GridRenderCellParams } from '@mui/x-data-grid'
import { ReactNode } from 'react'
import GridDateTemplate from './GridDateTemplate'

import { PATHS } from '@/constants'
import { DateTimeCell } from '../../../../../components/Selects/DateTimeSelect'
import { Route } from '../../../routes/config'
import { AppUserTypeTemplate, EditAppUserTypeTemplate } from './AppUserTypeTemplate'
import GridDateFullTemplate from './GridDateFullTemplate'
import GridLinkTemplate from './GridLinkTemplate'

export const CELL_TEMPLATES: Record<string, (params: GridRenderCellParams) => ReactNode> = {
	date: GridDateTemplate,
	dateTime: DateTimeCell,
	fullDate: GridDateFullTemplate,
	eventLink: (params) => GridLinkTemplate({ ...params, path: PATHS.APP.EVENTS_SINGLE(params.row?.id) }),
	postLink: (params) => GridLinkTemplate({ ...params, path: PATHS.APP.POST_INFO(params.row?.id) }),
	organizationLink: (params) => GridLinkTemplate({ ...params, path: PATHS.APP.ORGANIZATIONS_SINGLE(params.row?.id) }),
	analyticsProfile: (params) =>
		GridLinkTemplate({ ...params, path: `${PATHS.ADMIN}/${Route.Users}/${params.row?.userId || params.row?.id}` }),
	groupChatLink: (params) => GridLinkTemplate({ ...params, path: `${PATHS.ADMIN}/${Route.Groups}/${params.row?.id}` }),
	appUserTypeId: (params: GridRenderCellParams<any, any, { appUserTypeId: string }>) =>
		AppUserTypeTemplate({ appUserTypeId: params.row?.appUserTypeId }),
}

export const EDIT_CELL_TEMPLATES: Record<string, <T extends React.ComponentType<any>>(params: React.ComponentProps<T>) => React.ReactNode> =
	{
		// date: DatePicker,
		// dateTime: EditDateTime,
		appUserTypeId: (params) => <EditAppUserTypeTemplate {...params} />,
	}
