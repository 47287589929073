import { SESSION_TIME } from '@/constants/configuration'
import { objectToSearchString } from 'use-query-params'

import { GroupChatRoles } from '@navengage/sen-types'

import { baseApi } from './baseApi'

const groupsBaseApi = 'groups'

enum GroupMemberStatus {
	Approved = 'Approved',
	Blocked = 'Blocked',
	Removed = 'Removed',
}

interface SearchGroupMembers {
	skip: number
	take: number
	groupId: string
	status?: GroupMemberStatus
	type?: GroupChatRoles
}

export const analyticsApi = baseApi.enhanceEndpoints({ addTagTypes: ['Groups'] }).injectEndpoints({
	endpoints: (builder) => ({
		getGroupsGridMeta: builder.query({
			query: () => ({
				url: `/${groupsBaseApi}/grid/meta`,
				method: 'GET',
			}),
			keepUnusedDataFor: SESSION_TIME,
		}),
		getGroupsGridData: builder.query({
			query: (queryArg) => ({
				url: `/${groupsBaseApi}/grid/data`,
				method: 'GET',
				params: { take: queryArg.take, skip: queryArg.skip, filters: queryArg.filters, sortBy: queryArg.sortBy },
			}),
			providesTags: (result) => {
				if (!result) {
					return ['Groups']
				}
				return [...result.items.map(({ id }: any) => ({ type: 'Groups', id })), { type: 'Groups', id: 'LIST' }]
			},

			keepUnusedDataFor: SESSION_TIME,
		}),
		toggleRemoveGroups: builder.mutation<undefined, { groupIds: string[] }>({
			query: ({ groupIds }) => ({
				url: `/${groupsBaseApi}?${objectToSearchString({ ids: groupIds })}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['Groups'],
		}),
		getGroupById: builder.query({
			query: ({ groupId }) => ({
				url: `/${groupsBaseApi}/${groupId}`,
				method: 'GET',
			}),
		}),
		getGroupMembersByMemberType: builder.query<any, SearchGroupMembers>({
			query: ({ groupId, take, skip, type, status = 'active' }) => ({
				url: `/${groupsBaseApi}/${groupId}/memberships`,
				params: { take, skip, type, status },
				method: 'GET',
			}),
		}),
	}),
})

export const {
	useGetGroupsGridMetaQuery,
	useGetGroupsGridDataQuery,
	useToggleRemoveGroupsMutation,
	useGetGroupByIdQuery,
	useGetGroupMembersByMemberTypeQuery,
} = analyticsApi
