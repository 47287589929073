import useSWR from 'swr'
import * as http from '@/api/http'
import useSchoolId from '@/features/school/hooks/useSchoolId'
import { isEngage } from '@/constants/configuration'

export const useProfilePhoto = ({ externalId, photoUrl, inView }: { externalId: string; photoUrl: string | null; inView?: boolean }) => {
	const schoolId = useSchoolId()

	// If user is not at PSU and does not have a photoUrl, no need to fetch data, and
	// If we don't have externalId, inView, and cache, don't fetch data

	const shouldFetch = inView && externalId && (photoUrl || !isEngage())
	const path = shouldFetch ? `/profile-image/${externalId}` : null

	// const path = (!photoUrl || !externalId) && isEngage() ? null : externalId && inView ? `/profile-image/${externalId}` : null

	const { data, ...rest } = useSWR(
		path,
		async () => {
			const response = await http.user.getUserProfileImage({ externalId, schoolId })

			const image = response.data?.image

			return image ? `data:image/jpeg;base64,${image}` : null
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	return { image: data, ...rest }
}
