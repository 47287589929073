import getScheduledPosts from '@/api/http/feeds/getScheduledPosts'
import { IconButtonWithTooltip } from '@/components/Buttons'
import { ButtonColors } from '@/components/Buttons/types'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import { STYLES } from '@/constants'
import useAuth from '@/hooks/useAuth'
import { useInfiniteScrollList } from '@/hooks/useInfiniteScrollList'
import { globalStates, useGlobalStateSetter } from '@/lib/globalState'
import { usePubsubHub } from '@/lib/pubsub/hooks'
import { PubsubMessage, PubsubMessageTopic } from '@/lib/pubsub/types'
import { formatDateNew } from '@/utils/dateTime2'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback } from 'react'
import { useFeedPost } from '../hooks/useFeedPost'
import { AttachmentIcon, DeleteIcon, EditIcon, ScheduleIcon } from './Icons'
import EmptyList from './List/EmptyPlaceholder'

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		maxWidth: theme.spacing(100),
		minWidth: theme.spacing(80),
		height: theme.spacing(60),
		padding: '0 15px',
	},
	loaderContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
	},
	item: {
		padding: theme.spacing(2, 2),
		borderBottom: `solid 1px ${theme.colors.grey[200]}`,
		'&:last-child': {
			borderBottom: 'none',
		},
	},
	itemHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: theme.colors.grey[500],
		marginBottom: theme.spacing(1),
	},
	headerButtons: {
		'& > *': {
			cursor: 'pointer',
		},
	},
	itemBody: {
		padding: theme.spacing(0, 4),
	},
	postingOn: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
	},
	postText: {
		'&>p': {
			margin: '2px 0',
		},
		...theme.typography.body1,
		maxHeight: '5rem',
		overflow: 'hidden',
	},
	attachment: {
		display: 'flex',
		alignItems: 'center',
		color: theme.colors.grey[400],
		padding: theme.spacing(1, 0),
	},
	loadMoreSection: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: theme.spacing(12),
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.grey[100],
		},
	},
	listingRoot: {
		margin: 0,
	},
}))

const ListItemHeader = ({ id, activeSince, onEdit, onDelete }) => {
	const classes = useStyles()
	return (
		<div className={classes.itemHeader}>
			<div className={classes.postingOn}>
				<ScheduleIcon />
				<span>Posting on {formatDateNew(activeSince, 'full')}</span>
			</div>
			<div className={classes.headerButtons}>
				<IconButtonWithTooltip tooltipTitle={'Edit'} onClick={onEdit}>
					<EditIcon />
				</IconButtonWithTooltip>

				<IconButtonWithTooltip tooltipTitle={'Delete'} color={ButtonColors.LIGHT_RED} onClick={onDelete}>
					<DeleteIcon />
				</IconButtonWithTooltip>
			</div>
		</div>
	)
}

const ItemAttachment = () => {
	const classes = useStyles()
	return (
		<div className={classes.attachment}>
			<AttachmentIcon /> Has attachment
		</div>
	)
}

const ListItem = ({ item }) => {
	const classes = useStyles()

	const { deletePost } = useFeedPost(item.id, item)

	const handleHtmlClick = (e: any) => {
		if (e.target.tagName !== 'A') return
		e.stopPropagation()
	}

	const handleDelete = useCallback(async () => {
		deletePost()
	}, [deletePost])

	return (
		<div className={classes.item}>
			<ListItemHeader
				id={item.id}
				activeSince={item.activeSince}
				onEdit={(e: any) => {
					e.stopPropagation()
					globalStates.createFeedsModal.setValue((data) => {
						// @ts-ignore
						data.content = { ...data.content, postToEdit: item }
						data.isEdit = true
					})
				}}
				onDelete={handleDelete}
			/>
			<div className={classes.itemBody}>
				{item.message && <DescriptionHtml className={classes.postText} onClick={handleHtmlClick} description={item.message} />}
				{item.photoUrl && <ItemAttachment />}
			</div>
		</div>
	)
}

const Loader = () => <CircularProgress />

interface ScheduledPost {
	id: string
	message: string
	activeSince: Date
}

export default function SchedulePostList() {
	const classes = useStyles()
	const setCreateFeedsModal = useGlobalStateSetter(globalStates.createFeedsModal)

	const handleOpenCreateFeedModal = useCallback(() => {
		setCreateFeedsModal((data) => {
			data.isOpen = true
			data.isSchedule = true
		})
	}, [setCreateFeedsModal])

	const { schoolId, userId } = useAuth()
	const { isLoading, canLoadMore, items, loadMore, refreshItems } = useInfiniteScrollList<any, ScheduledPost>(
		{
			userId,
			scheduled: true,
			schoolId,
		},
		getScheduledPosts,
	)

	// @TODO: needs to be corrected at some point #cleanup
	usePubsubHub({
		[PubsubMessageTopic.FeedPostCreated]: (message: PubsubMessage) => {
			refreshItems()
		},
		[PubsubMessageTopic.FeedPostUpdated]: (message: PubsubMessage) => {
			refreshItems()
		},
		[PubsubMessageTopic.FeedPostDeleted]: (message: PubsubMessage) => {
			refreshItems()
		},
	})

	return (
		<div className={classes.rootContainer}>
			{isLoading && items.length === 0 ? (
				<div className={classes.loaderContainer}>
					<Loader />
				</div>
			) : !items.length ? (
				<EmptyList onScheduleClick={handleOpenCreateFeedModal} />
			) : (
				<InfiniteScrollComponent
					classnames={{ content: classes.listingRoot }}
					height={480}
					internalScroll
					canLoadMore={canLoadMore}
					onLoadMore={loadMore}
					items={items}
					getKey={(item, idx) => idx}
					renderItem={(item) => <ListItem item={item} />}
				/>
			)}
		</div>
	)
}
