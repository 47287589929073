import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { StaticSideBar } from '@/components/NavigationSideBar'

import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { PATHS, STYLES } from '@/constants'
import { useMainNavigation } from '@/hooks/useMainNavigation'
import { getUserInfo } from '@/store/auth'
import { getTotalConnections } from '@/store/network'
import { getFullName } from '@/utils/common'

const useStyles = makeStyles((theme) => ({
	iconContainer: {
		width: 40,
		minWidth: 40,
		marginRight: 7,
		'& img, svg': {
			height: 30,
			width: 30,
			color: theme.palette.primary.main,
		},
	},

	profileIcon: {
		height: 50,
		width: 50,
		minHeight: 50,
		minWidth: 50,
	},
	stickyContainer: {
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		overflowY: 'auto',
	},
	connectionsCounter: {
		color: theme.palette.text.secondary,
		fontWeight: 600,
	},
	userName: {
		fontWeight: 700,
	},
	userInfo: {
		marginLeft: 8,
	},
	clickable: {
		cursor: 'pointer',
	},
}))

const EmbeddedSideBar = () => {
	const classes = useStyles()
	const { push } = useHistory()

	const { externalId, firstName, lastName, photoUrl } = useSelector(getUserInfo)
	const numberOfConnections = useSelector(getTotalConnections)

	const userName = getFullName(firstName, lastName)

	const navigation = useMainNavigation()

	const handleClickViewProfile = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		push(PATHS.APP.PROFILE)
	}

	const handleClickNavigationButton = (newPath: string) => push(newPath)

	return (
		<StaticSideBar
			classnames={{ stickyContainer: classes.stickyContainer }}
			navButtonClassNames={{ icon: classes.iconContainer }}
			header={
				<>
					<LazyLoadProfileImage className={classes.profileIcon} externalId={externalId} photoUrl={photoUrl} />
					<div className={classes.userInfo}>
						<div className={classes.clickable} onClick={handleClickViewProfile}>
							<Typography className={classes.userName} variant="h3">
								{userName}
							</Typography>
						</div>
						<Typography className={classes.connectionsCounter}>
							{numberOfConnections} Connection
							{parseInt(numberOfConnections) === 1 ? '' : 's'}
						</Typography>
					</div>
				</>
			}
			navigation={navigation}
			onClickNavigation={handleClickNavigationButton}
		/>
	)
}

export default EmbeddedSideBar
