import useTakeSkip from '@/anthology/hooks/useTakeSkip'
import { ContentPreloader } from '@/components/ContentPreloader'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import EmptyProfileInfoItemPlaceholder from '@/components/UserProfile/UserExperienceItems/EmptyProfileInfoItemPlaceholder'
import ProfileInfoItem from '@/components/UserProfile/UserExperienceItems/ProfileInfoItem'
import { UserInfo } from '@/interfaces/user'
import { ProfileInfoCardTypes } from '@/utils/userExperienceItems'
import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ProfileItemDto, useProfileItemsControllerFindManyByUserIdQuery } from '../../../api/usersProfileItemsApi'
import { generateDynamicLabel } from '../../../utils/getTypeLabel'

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '500px',
	},
	placeholder: {
		marginTop: theme.spacing(5),
	},
}))

export type IUsrReportedProfileItemsListProps = {
	userId: UserInfo['id']
	type: ProfileItemDto['type']
}

const TYPE_MAPPER: Record<ProfileItemDto['type'], ProfileInfoCardTypes> = {
	interest: ProfileInfoCardTypes.INTERESTS,
	internship: ProfileInfoCardTypes.INTERNSHIPS,
	involvement: ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT,
	research: ProfileInfoCardTypes.RESEARCH,
	studyAbroad: ProfileInfoCardTypes.EDUCATION_ABROAD,
}

const UsrReportedProfileItemsList: React.FC<IUsrReportedProfileItemsListProps> = ({ type, userId }) => {
	const classes = useStyles()
	const [items, setItems] = useState<ProfileItemDto[]>([])
	const { skip, take, loadMore } = useTakeSkip()
	const { data, isLoading } = useProfileItemsControllerFindManyByUserIdQuery({
		skip,
		take,
		id: userId,
		type,
	})

	useEffect(() => {
		if (data?.items) {
			setItems((prev) => [...prev, ...data.items])
		}
	}, [data?.items])

	return (
		<div className={classes.root}>
			{isLoading ? (
				<ContentPreloader />
			) : (
				<div className={classes.placeholder}>
					<InfiniteScrollComponent
						resetScroll
						internalScroll
						canLoadMore={items.length < data.totalItems}
						height={500}
						placeholder={
							items.length === 0 ? (
								<EmptyProfileInfoItemPlaceholder
									showSubTitle={false}
									title={generateDynamicLabel({ prefix: 'No', subj: type, suffix: 'Submitted' })}
									type={TYPE_MAPPER[type]}
								/>
							) : null
						}
						onLoadMore={loadMore}
						items={items}
						renderItem={(item) => <ProfileInfoItem item={{ ...item.data, ...item }} type={TYPE_MAPPER[type]} />}
					/>
				</div>
			)}
		</div>
	)
}

export { UsrReportedProfileItemsList }
