import { inflectString } from '@/features/adminConsole/utils/getTypeLabel'
import { getUserRole, USER_TYPES } from '@/utils/authHandlers'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { GridCellParams, useGridApiContext } from '@mui/x-data-grid'

type AppUserTypeTemplateProps = {
	appUserTypeId: number
}
export const AppUserTypeTemplate: React.FC<AppUserTypeTemplateProps> = ({ appUserTypeId: typeId, ...rest }) => {
	return <div>{inflectString({ subj: getUserRole(typeId) ?? 'unknown' })}</div>
}

export type EditAppUserTypeTemplateProps = GridCellParams<{ appUserTypeId: number; id: number } & object>
export const isAppUserRow = (args): args is EditAppUserTypeTemplateProps['row'] =>
	args && typeof args.appUserTypeId === 'number' && typeof args.id === 'number'

export const EditAppUserTypeTemplate: React.FC<EditAppUserTypeTemplateProps> = (params) => {
	const { id, field, value } = params
	const apiRef = useGridApiContext()

	const handleChange = (event: SelectChangeEvent<number>) => {
		// Update the value directly via the Data Grid API
		apiRef.current.setEditCellValue({ id, field, value: event.target.value }, event)
	}

	return (
		<FormControl fullWidth sx={{ paddingLeft: 0, height: '100%', width: '100%' }}>
			<Select
				labelId="demo-simple-select-label"
				displayEmpty
				value={value ?? 0}
				onChange={handleChange}
				sx={{
					boxShadow: 'none',
					borderRadius: 0,
					fontSize: '14px',
					'& .MuiOutlinedInput-notchedOutline': {
						border: 0,
					},
					'& .MuiSelect-select': {
						paddingLeft: '8px',
						height: '100%',
					},
					'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: 0,
					},
				}}
			>
				{Object.entries(USER_TYPES)
					.filter(([_, value]) => value > 0)
					.map(([_name, value]) => (
						<MenuItem key={value} value={value}>
							{inflectString({ subj: _name })}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	)
}
