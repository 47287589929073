import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'

import { ColorButton, CommonModalCard, Modal } from '@/components'
import MembershipInfoRow from '@/components/IndividualPage/MembershipInfoRow'
import { OrgMembership } from '@/store/organizations/types'
import { Typography } from '@material-ui/core'
import InfiniteScrollComponent from '../ItemsSections/InfiniteScrollComponent'

const useStyles = makeStyles((theme) => ({
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '90%',
	},
	rootContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	saveButton: {
		margin: '4.5vh 0',
		padding: '1vh',
		fontWeight: 600,
		minWidth: 200,
	},
	description: {
		fontSize: 15,
		textAlign: 'left',
		margin: '5px 0 40px 0',
		color: theme.palette.text.secondary,
	},
	text: {
		fontSize: 15,
		textAlign: 'left',
		color: theme.palette.text.secondary,
	},
	subTitle: {
		fontSize: 15,
		textAlign: 'left',
	},
	modalCard: {
		width: '44vw',
		minWidth: 300,
		maxWidth: 580,
	},
	userRowContainer: {
		margin: '15px 0',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: 5,
	},
	userRow: {
		margin: 15,
	},
	userRowImage: {
		width: 40,
		height: 40,
	},
	userRowButtonsContainer: {
		margin: 15,
	},
	userRowButton: {
		width: 74,
		height: 32,
		fontSize: 15,
		fontWeight: 500,
	},
	textBlock: {
		padding: '10px 5%',
		width: '90%',
		borderBottom: `solid 1px ${theme.palette.divider}`,
	},
	optionsContainer: {
		width: '100%',

		overflowY: 'auto',
		margin: '0 0 40px 0',
	},
	userOptionRowContainer: {
		margin: '0 2%',
		padding: '3%',
		flexDirection: 'column',
	},
	userOptionRow: {
		margin: 0,
	},
	userOptionRowButtonsContainer: {
		width: '100%',
		height: '100%',
	},
	selectedUser: {
		backgroundColor: theme.palette.background.default,
	},
	buttonsContainer: {
		width: 'calc(100% - 58px)',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginLeft: 58,
	},
	primaryContactButton: {
		width: 202,
		height: 32,
		fontSize: 15,
		fontWeight: 500,
		margin: '5px 0',
	},
	userInfoActionRowContainer: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
		},
	},
}))

interface EditPrimaryContactModalProps {
	isLoading: boolean
	isFetching: boolean
	isOpen: boolean
	contact?: OrgMembership
	memberships: OrgMembership[]
	canLoadMore: boolean
	onChangeContact: (membershipId: number) => void
	handleCloseModal: (e: any) => void
	loadMore: () => void
}

const EditPrimaryContactModal = ({
	isOpen,
	contact,
	isLoading,
	isFetching,
	memberships,
	canLoadMore,
	onChangeContact,
	handleCloseModal = () => {},
	loadMore,
}: EditPrimaryContactModalProps) => {
	const classes = useStyles()

	const [isOpenSelectModal, setIsOpenSelectModal] = useState(false)
	const [selectedContact, setSelectedContact] = useState<OrgMembership | undefined>(contact)

	const onClose = (e: any) => {
		setIsOpenSelectModal(false)
		setSelectedContact(undefined)
		handleCloseModal(e)
	}

	const handleToggleChangeModal = () => {
		setIsOpenSelectModal((prev) => !prev)
	}

	const handleSaveContact = (e: any) => {
		onChangeContact(selectedContact!.id)
		onClose(e)
	}

	const handleSelectMembership = (membership: OrgMembership) => () => {
		setSelectedContact(membership)
	}

	const handleChangeContact = () => {
		handleToggleChangeModal()
	}

	useEffect(() => {
		if (isOpen) {
			setSelectedContact(contact)

			if (!contact) {
				setIsOpenSelectModal(true)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	const changed = selectedContact?.id !== contact?.id

	const renderMembership = useCallback(
		(member) => {
			const selected = member.id === selectedContact?.id

			return (
				<MembershipInfoRow
					user={member.user}
					classnames={{
						root: classNames(classes.userOptionRowContainer, selected ? classes.selectedUser : classes.userInfoActionRowContainer),
						userRow: classes.userOptionRow,
						profileImage: classes.userRowImage,
						buttonsContainer: classes.userOptionRowButtonsContainer,
					}}
					onClick={!selected ? handleSelectMembership(member) : undefined}
					userEmail={member.userEmail}
					buttons={
						selected ? (
							<div className={classes.buttonsContainer}>
								<ColorButton className={classes.primaryContactButton} onClick={handleChangeContact}>
									Make Primary Contact
								</ColorButton>
							</div>
						) : undefined
					}
				/>
			)
		},
		[classes, handleSelectMembership, handleChangeContact, selectedContact],
	)

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			{selectedContact && !isOpenSelectModal ? (
				<CommonModalCard
					title={`Primary Contact`}
					onClose={onClose}
					classnames={{
						card: classes.modalCard,
					}}
				>
					<div className={classes.formContainer}>
						<MembershipInfoRow
							user={selectedContact.user}
							classnames={{
								root: classes.userRowContainer,
								userRow: classes.userRow,
								profileImage: classes.userRowImage,
								buttonsContainer: classes.userRowButtonsContainer,
							}}
							membershipTitle={selectedContact.membershipTitle}
							userEmail={selectedContact.userEmail}
							buttons={
								memberships ? (
									<ColorButton className={classes.userRowButton} onClick={handleToggleChangeModal}>
										Change
									</ColorButton>
								) : undefined
							}
							openProfileOnClick
						/>
						<Typography className={classes.description}>
							The primary contact of an organization can be direct messaged by any user. They will automatically become an admin of the
							organization. They will be notified every time a user requests to join the organization or claims membership. They can create
							events on behalf of the organization.
						</Typography>
						{changed && (
							<ColorButton className={classes.saveButton} onClick={handleSaveContact}>
								Done
							</ColorButton>
						)}
					</div>
				</CommonModalCard>
			) : (
				<CommonModalCard
					title={`Primary Contact`}
					onClose={handleToggleChangeModal}
					classnames={{
						card: classes.modalCard,
					}}
				>
					<div className={classes.rootContainer}>
						<div className={classes.textBlock}>
							<Typography className={classes.subTitle}>Change primary contact</Typography>
							<Typography className={classes.text}>Primary Contacts must already be an Admin</Typography>
						</div>
					</div>

					<InfiniteScrollComponent
						classnames={{
							content: classes.optionsContainer,
						}}
						height={400}
						internalScroll
						canLoadMore={canLoadMore}
						onLoadMore={loadMore}
						items={memberships}
						renderItem={renderMembership}
					/>
				</CommonModalCard>
			)}
		</Modal>
	)
}

export default EditPrimaryContactModal
