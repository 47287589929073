import { IconButton, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ChangeGroupMember } from '@/api/http/groups/patchGroupMember'
import {
	ColorButton,
	IconWrapper,
	LazyLoadProfileImage,
	LockIcon,
	Modal,
	ModalWithSubmittedPopup,
	OptionButton,
	SuccessPopup,
} from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import PopupModalCard from '@/components/UserProfile/EditUserModal/PopupModalCard'
import { PATHS, STYLES } from '@/constants'
import { ConnectivityStatus } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import {
	changeBlockedMemberRequest,
	changeMemberRoleRequest,
	getMemberToView,
	getTargetGroup,
	removeMemberFromGroupRequest,
	setMemberToView,
} from '@/store/groups'
import { createConnectionRequest, getNetworkUserInfo } from '@/store/network'
import { getFullName } from '@/utils/common'
import { ADMIN_ACTIONS, checkGroupPermission } from '@navengage/sen-shared-assets'
import { GroupChatRoles } from '@navengage/sen-types'

const useStyles = makeStyles((theme) => ({
	itemPopupContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: '0 0 0 34px',
	},
	itemPopupRootContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '86%',
		padding: '7%',
	},
	itemPopupImage: {
		margin: 0,
		padding: 0,
	},
	itemPopupRoot: {
		display: 'flex',
		margin: 0,
		width: '100%',
	},
	optionButton: {
		width: '100%',
		borderTop: `solid 1px ${theme.palette.divider}`,
		color: theme.palette.text.primary,
		padding: '14px 28px',
		...theme.typography.subtitle2,
		textAlign: 'left',
		justifyContent: 'space-between',
		fontWeight: 600,
		'&.MuiButtonBase-root.Mui-disabled': {
			pointerEvents: 'auto',
		},
	},
	redText: {
		color: theme.colors.red[500],
	},
	profileImage: {
		width: 67,
		height: 67,
		minWidth: 67,
		minHeight: 67,
	},
	userName: {
		fontWeight: 700,
		marginTop: 20,
	},
	closeButton: {
		color: theme.palette.text.secondary,
		padding: 5,
		height: 34,
	},
	internalButtonInfo: {
		display: 'flex',
		alignItems: 'center',
	},
	lockIcon: {
		width: 15,
		height: 15,
		marginLeft: 7,
	},
	crownIcon: {
		marginLeft: 7,
	},
	connectButton: {
		padding: '4px 12px',
	},
}))

const ViewGroupMemberModal = () => {
	const classes = useStyles()
	const { push } = useHistory()
	const dispatch = useAppDispatch()
	const appTheme = useTheme()
	const data = useSelector(getTargetGroup)

	const member = useSelector(getMemberToView)
	const networkUser = useSelector(getNetworkUserInfo)

	const canAddRemoveMembers = checkGroupPermission(ADMIN_ACTIONS.ADD_REMOVE_MEMBERS, data, data?.userMemberType)
	const canAddRemoveAdmins = checkGroupPermission(ADMIN_ACTIONS.ADD_REMOVE_ADMINS, data, data?.userMemberType)

	const memberId = member?.userId ?? -1

	const [isOpenPopup, setIsOpenPopup] = useState({
		makeAdmin: false,
		removeAdmin: false,
		removeFromGroup: false,
		blockUser: false,
	})

	const handleOpenPopup = (name: keyof typeof isOpenPopup) => () => setIsOpenPopup((prev) => ({ ...prev, [name]: true }))
	const handleClosePopup = (name: keyof typeof isOpenPopup) => () => setIsOpenPopup((prev) => ({ ...prev, [name]: false }))
	const handleCloseAllPopupsAndModal = () => {
		onClose()
		setIsOpenPopup((prev) =>
			Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {
				...prev,
			}),
		)
	}

	const onClose = () => {
		dispatch(setMemberToView(null))
	}

	const isUserConnected = networkUser?.connectionStatus === ConnectivityStatus.ACCEPTED
	const isPendingStatus = networkUser?.connectionStatus === ConnectivityStatus.PENDING

	const shouldShowConnectButton = !isUserConnected && !isPendingStatus && !networkUser?.isArchived

	const handleCreateRequest = () => dispatch(createConnectionRequest(memberId))
	const handleOpenNetworkPage = () => {
		onClose()
		push(PATHS.APP.NETWORK)
	}

	const handleMakeAdmin = () => {
		dispatch(
			changeMemberRoleRequest({
				member: member!,
				newRole: GroupChatRoles.Admin,
			}),
		)
	}

	const handleRemoveAdmin = () => {
		handleOpenPopup('removeAdmin')()
		dispatch(
			changeMemberRoleRequest({
				member: member!,
				newRole: GroupChatRoles.Member,
			}),
		)
	}

	const handleRemoveUserFromGroup = () => {
		handleOpenPopup('removeFromGroup')()
		dispatch(removeMemberFromGroupRequest(member!))
	}

	const handleBlockUser = () => {
		dispatch(
			changeBlockedMemberRequest({
				memberId: `${member?.id}`,
				action: ChangeGroupMember.BLOCK,
			}),
		)
	}

	const handleSendDirectMessage = () => {
		onClose()
		push(PATHS.APP.MESSAGES_USER_DIALOGUE(memberId))
	}

	const isOpen = !!member

	return (
		<>
			<SuccessPopup
				isOpen={isOpenPopup.removeFromGroup}
				onClose={handleCloseAllPopupsAndModal}
				submittedMessage={['This user has been removed ', 'from the group.']}
			/>
			<SuccessPopup
				isOpen={isOpenPopup.removeAdmin}
				onClose={handleCloseAllPopupsAndModal}
				submittedMessage={['This user is no longer an ', 'admin of the group.']}
			/>
			<ModalWithSubmittedPopup
				isOpen={isOpenPopup.blockUser}
				onSubmit={handleBlockUser}
				onClose={handleClosePopup('blockUser')}
				onCloseSubmittedModal={handleCloseAllPopupsAndModal}
				title="Block user from this group"
				content={[
					'Once this user is blocked, they will no longer be able to see or join this group. You can edit blocked users in your group settings.',
				]}
				submitButtonLabel="Block user"
				submitButtonColor={appTheme.colors.red[500]}
			/>
			<ModalWithSubmittedPopup
				isOpen={isOpenPopup.makeAdmin}
				onSubmit={handleMakeAdmin}
				onClose={handleClosePopup('makeAdmin')}
				onCloseSubmittedModal={handleCloseAllPopupsAndModal}
				title="Make Admin"
				content={['To see what admins can do, check your group settings or ask the owner of the group.']}
				submitButtonLabel="Make Admin"
				submitButtonColor={appTheme.palette.primary.main}
				cancelButtonColor={appTheme.colors.red[500]}
			/>
			<Modal isOpen={isOpen} onClose={onClose}>
				<PopupModalCard className={classes.itemPopupImage} contentClassName={classes.itemPopupRoot}>
					<div className={classes.itemPopupRootContainer}>
						<div className={classes.itemPopupContainer}>
							<LazyLoadProfileImage
								className={classes.profileImage}
								borderRadius={10}
								externalId={member?.externalId}
								photoUrl={member?.photoUrl}
							/>
							<Typography className={classes.userName} variant="body2">
								{getFullName(member?.firstName, member?.lastName)}
							</Typography>
						</div>
						<IconButton className={classes.closeButton} onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</div>
					{shouldShowConnectButton ? (
						<OptionButton className={classes.optionButton} disabled>
							<span className={classes.internalButtonInfo}>Direct Message {<LockIcon className={classes.lockIcon} />}</span>
							{isPendingStatus ? (
								<ColorButton className={classes.connectButton} color={ButtonColors.PRIMARY} onClick={handleOpenNetworkPage}>
									Requested
								</ColorButton>
							) : (
								<ColorButton className={classes.connectButton} onClick={handleCreateRequest}>
									Connect
								</ColorButton>
							)}
						</OptionButton>
					) : (
						<OptionButton className={classes.optionButton} onClick={handleSendDirectMessage} disabled={shouldShowConnectButton}>
							Direct Message
						</OptionButton>
					)}
					{canAddRemoveAdmins && (
						<OptionButton
							className={classes.optionButton}
							onClick={member?.type !== GroupChatRoles.Admin ? handleOpenPopup('makeAdmin') : handleRemoveAdmin}
						>
							{member?.type !== GroupChatRoles.Admin ? (
								'Make Admin'
							) : (
								<span className={classes.internalButtonInfo}>
									Remove as Admin {<IconWrapper iconKey="crown" color={appTheme.palette.text.primary} className={classes.crownIcon} />}
								</span>
							)}
						</OptionButton>
					)}
					{(canAddRemoveAdmins || canAddRemoveMembers) && (
						<>
							<OptionButton className={classes.optionButton} onClick={handleRemoveUserFromGroup}>
								Remove from Group
							</OptionButton>
							<OptionButton className={classNames(classes.optionButton, classes.redText)} onClick={handleOpenPopup('blockUser')}>
								Block from Group
							</OptionButton>
						</>
					)}
				</PopupModalCard>
			</Modal>
		</>
	)
}

export default ViewGroupMemberModal
