import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { UserData } from '@/interfaces/common'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { UserPrimaryTitle } from '../UserProfile/UserPrimaryTitle'

const useStyles = makeStyles((theme) => ({
	connectionRequestRoot: {
		display: 'flex',
		flexDirection: 'row',
		width: 'auto',
		padding: 15,
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.grey[100],
		},
	},
	connectionRequestContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
	},
	profileImage: {
		width: 50,
		height: 50,
		minWidth: 50,
		minHeight: 50,
		marginRight: 15,
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		marginTop: 10,
	},
	userName: {
		color: theme.palette.text.primary,
	},
	text: {
		fontSize: 15,
		color: theme.palette.text.secondary,
		textTransform: 'capitalize',
	},
}))

interface ModalUserRowProps {
	user: UserData
	profileImageRadius?: number
	className?: string
	onClick?: () => void
}

const ModalUserRow = ({ user, profileImageRadius = 5, className = '', onClick = () => {} }: ModalUserRowProps) => {
	const classes = useStyles()

	const { externalId, fullName, type, campus, primaryTitle, primaryTitleDepartment, photoUrl } = user

	return (
		<div className={classNames(classes.connectionRequestRoot, className)} onClick={onClick}>
			<LazyLoadProfileImage
				borderRadius={profileImageRadius}
				className={classes.profileImage}
				externalId={externalId}
				photoUrl={photoUrl}
			/>
			<div className={classes.connectionRequestContent}>
				<Typography className={classes.userName}>{fullName}</Typography>
				<UserPrimaryTitle className={classes.text} primaryTitle={primaryTitle} primaryTitleDepartment={primaryTitleDepartment} />
				<Typography className={classes.text}>{`${type} ${campus ? ` | ${campus}` : ''}`}</Typography>
			</div>
		</div>
	)
}

export default ModalUserRow
